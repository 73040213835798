<template>
  <div id="editorWrapper">
    <div class="columns">
      <div class="column is-one-fifth-tablet is-1-desktop">
        <h2 class="title is-6">
          <a
              class="title is-6 has-text-link"
              @click="$emit('reset')"
          >
            <font-awesome-icon icon="undo"/>

            {{ $t('workplace.reset.confirm') }}
          </a>
        </h2>
      </div>

      <div
        v-if="groups"
        class="column is-three-fifths-tablet is-6-desktop"
      >
        <div class="column">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="text"
                v-model="query"
                :placeholder="$t('workplace.ingredients.search')"
              >
            </div>
          </div>
        </div>

        <template v-if="query.length !== 0">
          <ingredient-card
            v-for="ingredient in found"
            :key="ingredient.id"
            :ingredient="ingredient"
          ></ingredient-card>
        </template>

        <template v-else>
          <div
            class="column"
            v-for="group in myGroups"
            :key="group.id"
          >
            <router-link
              v-slot="{ href, navigate }"
              :to="{ name: 'workplace.ingredients', params: { slug: group.slug, id: group.id } }"
            >
              <div
                class="card"
                :href="href"
                @click="navigate"
              >
                <header class="card-header">
                  <p class="card-header-title">
                    {{ group.name }}
                  </p>

                  <p class="card-header-icon">
                    <span class="icon">
                      <font-awesome-icon icon="angle-right" />
                    </span>
                  </p>
                </header>

                <div class="card-content">
                  <div class="content">
                    {{ filtered(group.ingredients) }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </template>
      </div>

      <div
        v-else
        class="notification"
      >
        Ingredienten worden geladen
      </div>
    </div>
  </div>
</template>

<script>
import recipe from '@/mixins/workplace/recipe'
import supplies from '@/mixins/workplace/supplies'
import IngredientCard from "@/components/workplace/IngredientCard";

export default {
  name: 'Editor',
  components: {IngredientCard},
  mixins: [
    recipe,
    supplies
  ],

  data () {
    return {
      query: '',
    };
  },

  computed: {
    found () {
      if (this.ingredients) {
        const index = this.recipe.producer ? this.producerIngredients : this.ingredients;

        return index.filter(ingredient => ingredient.name.toLowerCase().includes(this.query.toLowerCase()))
      }

      return [];
    }
  },

  methods: {
    filtered (ingredients) {
      if (this.recipe.producer) {
        const producerIngredientIds = this.recipe.producer.ingredients.map(ing => ing.id)

        ingredients = ingredients.filter(ing => producerIngredientIds.includes(ing.id))
      }

      return ingredients.map(ingredient => ingredient.name).join(', ')
    }
  }
}
</script>

<style lang="css" scoped>
.card {
  margin-bottom: 15px;
}
</style>
