import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      groups: 'workplace/groups',
      additives: 'workplace/additives',
      ingredients: 'workplace/ingredients',
      producerIndex: 'workplace/producerIndex',
    }),

    myGroups () {
      return (this.recipe?.producer) ?
        this.groups.filter(group => this.producerIngredients.filter(ing => ing.group_id === group.id).length > 0) :
        this.groups
    },

    producerIngredients () {
      if (!this.recipe?.producer) {
        return null
      }

      const ids = this.recipe?.producer.ingredients.map(ing => ing.id)

      return this.ingredients.filter(ingredient => ids.includes(ingredient.id))
    },
  },

  methods: {
    isIgnored (ingredient) {
      return (this.isInBootstrapMode) ? this.isInBase(ingredient.id) || !this.isRelative(ingredient) : !this.isRelative(ingredient)
    },

    isRelative (ingredient) {
      return ingredient.unit_id === 1
    },

    isAdditive (ingredientId) {
      return this.additives.map(additive => additive.id).includes(ingredientId)
    },

    isAdded (ingredientId) {
      return this.recipe.ingredients.map(ing => ing.id).includes(ingredientId)
    },

    findIngredient (ingredientId) {
      try {
        return this.ingredients.find(x => x.id === ingredientId)
      } catch (e) {
        console.error('Ingredient not found')
      }
    },

    isRelativeIngredient (ingredient) {
      return ingredient.unit.symbol === '%'
    },

    densityIcon (density) {
      const icons = {
        '-1': 'arrow-down',
        '0': 'minus',
        '1': 'arrow-up'
      }

      return icons[density]
    },
  }
}
