<template>
  <div id="resetPasswordWrapper">
    <div class="columns">
      <div class="column is-half is-offset-one-quarter">
        <h1 class="title">{{ $t('account.resetPassword.title') }}</h1>

        <div
          v-if="success"
          class="notification is-success"
        >
          {{ $t('account.resetPassword.success') }}
        </div>

        <div
            v-if="failed"
            class="notification is-danger"
        >
          {{ $t('account.resetPassword.failed') }}
        </div>

        <password
          v-else
          :expiration="$attrs.expiration"
          :token="$attrs.token"
        ></password>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Password from '@/components/profile/Password'

export default {
  name: 'ResetPassword',

  components: {Password},

  data() {
    return {
      success: false,
      failed: false,
    }
  },

  computed: {
    ...mapGetters({
      httpStatus: 'http/status',
    }),
  },

  watch: {
    httpStatus() {
      if (this.httpStatus === 0) {
        this.success = true

        setTimeout(() => this.$router.push({ name: 'home' }), 2e3)
      }

      if (this.httpStatus === 1) {
        this.failed = true
      }
    }
  }
}
</script>

<style scoped>

</style>
