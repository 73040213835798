import { render, staticRenderFns } from "./WelcomeNotice.vue?vue&type=template&id=da51de32&scoped=true&"
import script from "./WelcomeNotice.vue?vue&type=script&lang=js&"
export * from "./WelcomeNotice.vue?vue&type=script&lang=js&"
import style0 from "./WelcomeNotice.vue?vue&type=style&index=0&id=da51de32&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da51de32",
  null
  
)

export default component.exports