import getters from './getters'
import mutations from './mutations'

const state = {
  status: null, // 0 finished, 1 error, 2 loading
  message: null,
  redirect: null
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
