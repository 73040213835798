<template>
  <div id="indexWrapper">
    <div class="columns">
      <div class="column">
        <div class="container">
          <h1 class="title is-6 has-text-centered">{{ $t('workplace.header') }}</h1>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-quarter-desktop">
        <index-ad
          id="desktopHomeAd"
          :image="1"
        ></index-ad>
      </div>

      <div class="column is-half-desktop is-three-fifths-tablet is-offset-one-fifth-tablet is-offset-0-desktop">
        <router-link
          v-slot="{ href, navigate,  }"
          :to="{ name: 'preloads', params: { mode: 'preloads' } }"
        >
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                <span
                  :href="href"
                  @click="navigate"
                >
                  {{ $t('workplace.preloads.header') }}
                </span>

                <font-awesome-icon
                  class="ml-3"
                  icon="info-circle"
                  @click="openVideo(0)"
                ></font-awesome-icon>
              </p>

              <p
                :href="href"
                @click="navigate"
                class="card-header-icon"
              >
                <span class="icon">
                  <font-awesome-icon icon="angle-right" />
                </span>
              </p>
            </header>

            <div
              :href="href"
              @click="navigate"
              class="card-content">
              <div class="content">
                {{ $t('workplace.preloads.body') }}
              </div>
            </div>
          </div>
        </router-link>

        <index-ad
          id="mobileHomeAd"
          :image="0"
        ></index-ad>

        <router-link
          v-if="showProfiler"
          :to="{ name: 'preloads', params: { mode: 'preloads' } }"
          v-slot="{ href, navigate }"
        >
          <div class="card">
            <header class="card-header">
              <p
                :href="href"
                @click="navigate"
                class="card-header-title"
              >
                {{ $t('workplace.profiler.header') }}
              </p>

              <p
                :href="href"
                @click="navigate"
                class="card-header-icon"
              >
                <span class="icon">
                  <font-awesome-icon icon="angle-right" />
                </span>
              </p>
            </header>

            <div
              :href="href"
              @click="navigate"
              class="card-content"
            >
              <div class="content">
                {{ $t('workplace.profiler.body') }}
              </div>
            </div>
          </div>
        </router-link>

        <router-link
          v-slot="{ href, navigate }"
          :to="{ name: 'formulas', params: { mode: 'bootstrapped' } }"
        >
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                <span
                  :href="href"
                  @click="navigate"
                >
                  {{ $t('workplace.bootstrapped.header') }}
                </span>

                <font-awesome-icon
                  class="ml-3"
                  icon="info-circle"
                  @click="openVideo(2)"
                ></font-awesome-icon>
              </p>

              <p
                :href="href"
                @click="navigate"
                class="card-header-icon"
              >
                <span class="icon">
                  <font-awesome-icon icon="angle-right" />
                </span>
              </p>
            </header>

            <div
              :href="href"
              @click="navigate"
              class="card-content"
            >
              <div class="content">
                {{ $t('workplace.bootstrapped.body') }}
              </div>
            </div>
          </div>
        </router-link>

        <router-link
          v-slot="{ href, navigate }"
          :to="{ name: 'workplace', params: { mode: 'diy' } }"
        >
          <div class="card" :href="href" @click="navigate">
            <header class="card-header">
              <p class="card-header-title">
                {{ $t('workplace.diy.header') }}
              </p>

              <p class="card-header-icon">
                <span class="icon">
                  <font-awesome-icon icon="angle-right" />
                </span>
              </p>
            </header>

            <div class="card-content">
              <div class="content">
                {{ $t('workplace.diy.body') }}
              </div>
            </div>
          </div>
        </router-link>
      </div>

      <modal
        :is-active="showVideo !== null"
        @close="showVideo = null"
      >
        <div class="video-container">
          <iframe
            :src="videos[showVideo]"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import IndexAd from '@/components/ads/IndexAd'
import Modal from '@/components/modules/Modal'

export default {
  name: 'Index',

  components: {
    Modal,
    IndexAd
  },

  data () {
    return {
      videos: [
        'https://www.youtube.com/embed/pSZDmEsFQXg',
        null,
        'https://www.youtube.com/embed/moJfCfOloYw'
      ],
      showVideo: null,
      showProfiler: false
    }
  },

  beforeRouteEnter: (to, from, next) => {
    if (localStorage.getItem('recipe') !== null) {
      next('/werkplaats/groepen')
    } else {
      next()
    }
  },

  methods: {
    openVideo (idx) {
      this.showVideo = idx
    }
  }
}
</script>

<style scoped>
@media (min-width: 1024px) {
  #mobileHomeAd {
    display: none;
  }
}

@media (max-width: 1023px) {
  #desktopHomeAd {
    display: none;
  }
}

.card {
  margin-bottom: 15px;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
