import client from '@/http/client'

class Profile {
  path = 'profile'

  async fetch() {
    return await client.get(this.path, { headers: { 'Silent': 'true' } })
  }

  async create(data) {
    return await client.post(this.path, data)
  }

  async reset(email) {
    return await client.post(`${ this.path }/forgot`, email)
  }

  async password(data) {
    const path = data.token ? '/reset-password' : `${ this.path }/password`

    return await client.put(path, data)
  }

  async update(data) {
    return await client.put(this.path, data)
  }

  async destroy(password) {
    return await client.post(`${ process.env.VUE_APP_API }/cancel`, password)
  }
}

export { Profile }
