<template>
  <div
    v-if="ad"
    class="columns"
  >
    <div class="column">
      <figure
        v-if="ad"
        class="image"
      >
        <img
          :src="ad.urls[image]"
          :alt="ad.name"
          @click="inc"
        >
      </figure>

      <hr>

      <p class="is-size-7 has-text-centered">
        <router-link :to="{ name: 'about', params: { page: 'adverteren' } }">
          {{ $t('link.to.advertising') }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IndexAd',

  props: {
    image: {
      type: Number,
      required: true
    }
  },

  mounted () {
    this.$store.dispatch('adPositions/load', 1)
  },

  computed: {
    ...mapGetters({
      ad: 'adPositions/ad'
    }),

    data () {
      if (this.ad) {
        return {
          id: this.ad.id,
          deeplink: `${this.ad?.deeplink}?ref=myboilie.nl`
        }
      }

      return null
    }
  },

  methods: {
    inc () {
      this.$store.dispatch('ads/count', this.data)
    }
  }
}
</script>

<style scoped>

</style>
