const SET_RECIPE = (state, data) => {
  state.recipe = data
}

const UNSET_RECIPE = (state) => {
  state.recipe = {
    name: null,
    ingredients: []
  }
}

const SET_RECIPES = (state, data) => {
  state.recipes = data
}

const REORDER = (state, data) => {
  const recipes = []

  data.index.forEach(item => {
    recipes.push(state.recipes.find(x => x.id === item.id))
  })

  state.recipes = recipes
}

const UPDATE = (state, data) => {
  const idx = state.recipes.findIndex(x => x.id === data.id)

  if (idx !== -1) {
    state.recipes[idx] = data
  }
}

const REMOVE = (state, id) => {
  const idx = state.recipes.findIndex(x => x.id === id)

  if (idx !== -1) {
    state.recipes.splice(idx, 1)
  }
}

export default {
  UPDATE,
  REMOVE,
  REORDER,
  SET_RECIPE,
  SET_RECIPES,
  UNSET_RECIPE
}
