<template>
  <div class="columns">
    <div class="column">
      <h2 class="title is-6" @click="$router.go(-1)">
        <span class="icon">
          <font-awesome-icon icon="chevron-left" />
        </span>

        {{ $t('workplace.back') }}
      </h2>

      <template v-if="recipe">
        <recipe-form
          @save="save"
          @close="cancel"
        ></recipe-form>

        <hr>

        <button
          class="button is-link is-fullwidth"
          @click="select(recipe)"
        >
          {{ $t('account.recipes.openInWorkplace') }}
        </button>
      </template>

      <div
        v-else
        class="notification"
      >
        {{ $t('account.recipes.loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import machines from '@/mixins/workplace/machines'
import RecipeForm from '@/components/workplace/RecipeForm'

export default {
  name: 'EditRecipe',

  components: {
    RecipeForm
  },

  mixins: [
    machines
  ],

  created () {
    if (this.$attrs.id && this.recipe?.id !== this.$attrs.id) {
      this.$store.dispatch('recipes/load', this.$attrs.id)
    }
  },

  beforeDestroy () {
    this.$store.commit('recipes/UNSET_RECIPE')
  },

  computed: {
    ...mapGetters({
      recipe: 'recipes/recipe'
    })
  },

  methods: {
    save (payload) {
      this.$store.dispatch('recipes/save', payload)
    },

    cancel () {
      this.$router.go(-1)
    }
  }
}
</script>
