<template>
  <div class="modal" :class="{ 'is-active': ingredient }">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <div v-if="ingredient" class="card">
        <header class="card-header">
          <p class="card-header-title">{{ ingredient.name }}</p>
        </header>

        <div class="card-content">
          <div class="content" v-html="ingredient.description"></div>
          <ul>
            <li>{{ $t('workplace.ingredients.partOf') }}: <span class="is-pulled-right">{{ group.name }}</span></li>
            <li>{{ $t('workplace.ingredients.physicalProperties') }}: <span class="is-pulled-right">{{ (ingredient.density === -1) ? $t('workplace.ingredients.sinker') : (ingredient.density === 1 ? $t('workplace.ingredients.floater') : $t('workplace.ingredients.neutral')) }}</span></li>
            <li>{{ $t('workplace.ingredients.limit') }}: <span class="is-pulled-right">{{ ingredient.limit }}{{ ingredient.unit.symbol }}</span></li>
            <li>{{ $t('workplace.ingredients.increment') }}:<span class="is-pulled-right">{{ ingredient.increment }}{{ ingredient.unit.symbol }}</span></li>
          </ul>
        </div>
      </div>

      <div v-else class="notification is-warning">{{ $t('workplace.ingredients.notFound') }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IngredientDetails',
  props: {
    ingredient: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      groups: 'workplace/groups'
    }),
    group () {
      return (this.ingredient) ? this.groups.find(x => x.id === this.ingredient.group_id) : null
    }
  }
}
</script>

<style scoped>
.modal {
  padding: 1rem;
}
</style>
