<template>
  <div id="adminProducerWrapper">
    <div class="box">
      <div class="field">
        <label class="label">{{ $t('admin.producer.name') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="name">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.producer.address') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="address">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.producer.zip') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="zip">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.producer.city') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="city">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.producer.country') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="country">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.producer.email') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="email">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.producer.phone') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="phone">
        </div>
      </div>
    </div>

    <div
      v-if="loaded"
      class="box"
    >
      <h2 class="title is-4">{{ $t('admin.workplace.ingredients') }}</h2>

      <div class="tabs">
        <ul>
          <li
            v-for="(group, idx) in groups"
            :key="group.id"
            :class="{ 'is-active': activeTab === idx }"
            @click="toggleTab(idx)"
          >
            <a :title="group.name">{{ group.name | limited }}</a>
          </li>
        </ul>
      </div>

      <div class="columns">
        <div class="column">
          <div
              v-for="ing in groups[activeTab].ingredients"
              :key="ing.id"
              class="field"
          >
            <label class="checkbox">
              <input
                type="checkbox"
                v-model="selected[ing.id]"
              >
              {{ ing.name }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <form-buttons
      @submit="submit"
      @close="close"
      @remove="remove">
  </form-buttons>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'AdminProducer',

  components: {
    FormButtons
  },

  filters: {
    limited (str) {
      return str.length > 5 ? `${str.substr(0, 3)}...` : str
    }
  },

  data () {
    return {
      id: null,
      name: null,
      address: null,
      zip: null,
      city: null,
      country: null,
      email: null,
      phone: null,
      selected: {},
      activeTab: 0
    }
  },

  computed: {
    ...mapGetters({
      groups: 'groups/groups',
      producer: 'producers/producer'
    }),
    loaded () {
      return this.groups.length > 0
    }
  },

  created () {
    if (this.$attrs.id) this.$store.dispatch('producers/load', { id: this.$attrs.id, admin: true })
    this.$store.dispatch('groups/loadAll')
  },

  watch: {
    producer () {
      if (this.producer) {
        this.id = this.producer.id
        this.name = this.producer.name
        this.address = this.producer.address
        this.zip = this.producer.zip
        this.city = this.producer.city
        this.country = this.producer.country
        this.email = this.producer.email
        this.phone = this.producer.phone
      }
    },
    loaded () {
      this.$nextTick(() => {
        this.selected = this.producer.ingredients.map(ing => ing.id).reduce((a,b)=> (a[b] = true, a), {})
      })
    }
  },

  methods: {
    toggleTab (idx) {
      this.activeTab = idx
    },
    toggleIngredient (ingId) {
      this.isSelected(ingId) ? this.selected = this.selected[ingId] = !this.selected[ingId] : this.selected[ingId] = true
    },
    isSelected (ingId) {
      return Object.keys(this.selected).includes(ingId)
    },
    submit () {
      this.$store.dispatch('producers/save', {
        id: (this.id) ? this.id : null,
        name: this.name,
        address: this.address,
        zip: this.zip,
        city: this.city,
        country: this.country,
        email: this.email,
        phone: this.phone,
        ingredients: this.selected
      })
    },
    close () {
      this.id = this.name = this.address = this.zip = this.city = this.country = this.email = this.phone = null
      this.goToIndex()
    },
    remove () {
      this.$store.dispatch('producers/remove', this.id)
      this.goToIndex()
    },
    goToIndex () {
      this.$router.push({ name: 'admin.producers' })
    }
  }
}
</script>
