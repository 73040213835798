<template>
  <div id="workplaceWrapper">
    <router-view
      id="editorWrapper"
      @reset="displayResetConfirmation = true"
    ></router-view>

    <recipe
      @purge="purge"
      @save="toggleSave"
    ></recipe>

    <edit-recipe-modal
      v-if="recipe"
      :isActive="displaySaveModal"
      @save="save"
      @cancel="toggleSave"
    ></edit-recipe-modal>

    <reset-confirmation
      :is-active="displayResetConfirmation"
      @confirmed="purge"
      @cancelled="displayResetConfirmation = false"
    ></reset-confirmation>
  </div>
</template>

<script>
import recipe from '@/mixins/workplace/recipe'
import loaders from '@/mixins/workplace/loaders'
import supplies from '@/mixins/workplace/supplies'
import machines from '@/mixins/workplace/machines'
import instruments from '@/mixins/workplace/instruments'

import Recipe from '@/components/workplace/Recipe'
import EditRecipeModal from '@/components/workplace/EditRecipeModal'
import ResetConfirmation from "@/components/workplace/ResetConfirmation"

export default {
  name: 'Workplace',

  components: {
    Recipe,
    EditRecipeModal,
    ResetConfirmation
  },

  mixins: [
    recipe,
    loaders,
    supplies,
    machines,
    instruments
  ],

  data () {
    return {
      displaySaveModal: false,
      displayResetConfirmation: false
    }
  },

  created () {
    if (this.$route.params.isActive) this.isActive = this.$attrs.isActive
  },

  mounted () {
    this.$store.dispatch('workplace/loadRecipe')

    if (this.$attrs.mode === 'diy' && !this.recipe) {
      this.$store.commit('workplace/INITIALIZE')
    }
  },

  watch: {
    isSaved () {
      setTimeout(() => {
        if (this.isSaved) this.displaySaveModal = false
        this.$store.commit('workplace/SET_SAVED', null)
      }, 2000)
    }
  },

  methods: {
    toggleSave () {
      this.displaySaveModal = !this.displaySaveModal
    }
  }
}
</script>

<style scoped>
#editorWrapper {
  min-height: calc(100vh - 48px);
}
</style>
