import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      recipe: 'workplace/recipe'
    }),

    isInBootstrapMode () {
      return this.recipe?.mode === 'bootstrapped'
    },

    nutritionalValues () {
      const values = {
        carbs: 0,
        fibers: 0,
        fat: 0,
        neb: 0,
        protein: 0,
        vitamine_a: 0,
        vitamine_b1: 0,
        vitamine_b2: 0,
        vitamine_b6: 0,
      };

      if (this.recipe) {
        this.recipe.ingredients.forEach(ing => {
          for (const key in values) {
            values[key] = values[key] + (ing[key] / 100) * (ing.pivot.amount / 1000);
          }
        });
      }

      const evProtein = (values.protein * 16.8) / this.$factor;
      const evFat = (values.fat * 37.7) / this.$factor;
      const evCarbs = (values.carbs * 16.8) / this.$factor;
      const evTotal = evCarbs + evFat + evProtein;
      // const evProteinPerNutrient = evProtein / evTotal * 100;
      // const evFatPerNutrient = evFat / evTotal * 100;
      // const evCarbsPerNutrient = evCarbs / evTotal * 100;
      const fibersTotal = values.fibers / evTotal / 1005 * this.$factor;
      // const normVitA = values.vitamine_a;
      // const normVitB1 = ((evProtein + evCarbs) / 4185.5) * 0.6;
      // const normVitB2 = evProtein * 0.02;
      // const normVitB6 = values.vitamine_b6;
      // const neb = (values.neb * values.protein) / this.total * 10;


      return {
        evProtein: evProtein / evTotal * 100,
        evFat: evFat / evTotal * 100,
        evCarbs: evCarbs / evTotal * 100,
        // evProteinPerNutrient: evProteinPerNutrient,
        // evFatPerNutrient: evFatPerNutrient,
        // evCarbsPerNutrient: evCarbsPerNutrient,
        fibersTotal: fibersTotal,
        // normVitA: normVitA,
        // normVitB1: normVitB1,
        // normVitB2: normVitB2,
        // normVitB6: normVitB6,
        // neb: neb
      };
    },
  },

  methods: {
    isInBase (ingredientId) {
      return (this.isInBootstrapMode) ? this.recipe.base.map(ing => ing.id).includes(ingredientId) : false
    },

    isEditing (recipe) {
      return recipe.recipe_id !== null && recipe.original_ingredients !== null
    },

    myAmount (ingredientId) {
      const ingredient = this.recipe.ingredients.find(x => x.id === ingredientId)

      return (ingredient) ? `${(ingredient.pivot.amount / this.$factor).toFixed(1)}` : 0
    },

    findInRecipe (ingredientId) {
      const idx = this.recipe.ingredients.findIndex(x => x.id === ingredientId)

      if (idx !== -1) {
        return idx
      }

      return null
    },

    save (payload) {
      this.$store.dispatch('workplace/save', {
        id: payload.id,
        name: payload.name,
        mode: payload.mode,
        limit: payload.limit,
        labels: payload.labels,
        images: payload.images,
        is_preload: payload.is_preload,
        is_profiler: payload.is_profiler,
        description: payload.description,
        recipe_id: this.recipe.recipe_id,
        ingredients: this.recipe.ingredients,
        original_ingredients: this.recipe.original_ingredients,
        is_base: (this.mode === 'bootstrapped') ? false : payload.is_base
      })
    }
  }
}
