<template>
  <div id="lineWrapper">
    <div class="box">
      <h4 class="subtitle is-4">{{ $t('admin.workplace.line.message') }}</h4>

      <div class="field">
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="is_enabled">

          {{ $t('admin.workplace.line.is_enabled') }}
        </label>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.workplace.line.header') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="header">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.workplace.line.body') }}</label>

        <div class="control">
          <textarea class="textarea" v-model="body"></textarea>
        </div>
      </div>
    </div>

    <div class="box">
      <h4 class="subtitle is-4">{{ $t('admin.workplace.line.position') }}</h4>

      <div class="field">
        <label class="label">{{ $t('admin.workplace.line.previous') }}</label>

        <div class="select">
          <select
            v-model="previous_id"
            disabled>
            <option value=""></option>

            <option
              v-for="line in lines"
              :key="line.id"
              :value="line.id">

              {{ line.header }}{{ seperator(line) }}{{ line.body }}
            </option>
          </select>
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.workplace.profiler.option.next') }}</label>

        <div class="select">
          <select
            v-model="next_id"
            disabled>
            <option value=""></option>

            <option
              v-for="line in lines"
              :key="line.id"
              :value="line.id">

              {{ line.header }}{{ seperator(line) }}{{ line.body }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="box">
      <h4 class="subtitle is-4">{{ $t('admin.workplace.line.options') }}</h4>

      <template v-if="hasOptions">
        <ol>
          <li
            v-for="option in line.options"
            :key="option.id">

            {{ option.body }}

            <span class="icon is-pulled-right" @click="edit(option)">
              <font-awesome-icon icon="pencil-alt" />
            </span>
          </li>
        </ol>

        <button
          class="button"
          @click="toggleOptionEditor">

          {{ $t('admin.workplace.profiler.add_option') }}
        </button>
      </template>

      <p v-else @click="toggleOptionEditor">{{ $t('admin.workplace.profiler.add_option') }}</p>
    </div>

    <form-buttons
      :noRemove="hasOptions"
      @submit="submit"
      @close="close"
      @remove="remove">
    </form-buttons>

    <modal
      :isActive="showOptionEditor"
      @close="toggleOptionEditor">

      <admin-profiler-option
        :option="optionInEditor"
        :line_id="line.id"
        @close="toggleOptionEditor">
      </admin-profiler-option>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '../modules/Modal'
import FormButtons from '../modules/FormButtons'
import AdminProfilerOption from '../workplace/AdminProfilerOption'

export default {
  name: 'AdminProfilerLine',
  components: {
    Modal,
    FormButtons,
    AdminProfilerOption
  },
  data () {
    return {
      header: '',
      body: '',
      previous_id: null,
      next_id: null,
      is_enabled: true,
      optionInEditor: null,
      showOptionEditor: false
    }
  },
  created () {
    if (this.$attrs.id) {
      this.$store.dispatch('lines/load', this.$attrs.id)
    }

    if (this.$attrs.previous_id) {
      this.previous_id = this.$attrs.previous_id
    }

    if (this.$attrs.next_id) {
      this.next_id = this.$attrs.next_id
    }
  },
  watch: {
    line () {
      if (this.line) {
        this.header = this.line.header
        this.body = this.line.body
        this.is_enabled = this.line.is_enabled
        this.previous_id = (this.line.previous) ? this.line.previous.id : null
        this.next_id = (this.line.next) ? this.line.next.id : null
      }
    }
  },
  computed: {
    ...mapGetters({
      line: 'lines/line',
      lines: 'lines/lines',
      option: 'options/option'
    }),
    hasOptions () {
      return this.line && this.line.options.length > 0
    }
  },
  methods: {
    submit () {
      this.$store.dispatch('lines/save', {
        id: (this.$attrs.id) ? this.$attrs.id : null,
        header: this.header,
        body: this.body,
        is_enabled: this.is_enabled,
        previous_id: this.previous_id,
        next_id: this.next_id
      })
    },
    close () {
      this.goToIndex()
    },
    remove () {
      this.$store.dispatch('lines/remove', this.$attrs.id)
      this.goToIndex()
    },
    goToIndex () {
      this.$router.push({ name: 'admin.workplace.profiler', params: { from: this.$attrs.block } })
    },
    toggleOptionEditor () {
      this.showOptionEditor = !this.showOptionEditor
    },
    edit (option) {
      this.$store.commit('options/SET_OPTION', option)
      this.toggleOptionEditor()
    },
    seperator (line) {
      return (line.header && line.body) ? ' | ' : ''
    }
  }
}
</script>

<style scoped>
  ol {
    list-style-position: inside;
  }
</style>
