<template>
  <div class="columns">
    <div class="column">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ recipe.name }}
          </p>
        </header>

        <div class="card-content">
          <div class="content">
            <div v-html="recipe.description"></div>

            <hr>

            <h2 class="title is-6">{{ $t('workplace.preloads.ingredients') }}</h2>

            <table class="table is-striped is-fullwidth">
              <tr
                v-for="ingredient in recipe.ingredients"
                :key="ingredient.id"
              >

                <td>{{ ingredient.name }}</td>
                <td class="is-narrow">{{ (ingredient.pivot.amount / $factor).toFixed(1) }}{{ ingredient.unit.symbol }}</td>
              </tr>
            </table>

            <button
              class="button is-link is-fullwidth"
              @click="$emit('select', recipe)"
            >
              {{ $t('workplace.preloads.openInWorkplace') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Formula',

  props: {
    recipe: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="css">
</style>
