<template>
  <div id="uploaderWrapper">
    <button
      class="image is-64x64 is-pulled-left button"
      @click="toggle">

      <span class="icon">
        <font-awesome-icon icon="plus" />
      </span>
    </button>

    <div
      class="modal"
      :class="{ 'is-active': isActive }">

      <div class="modal-background"></div>

      <div class="modal-content">
        <div class="box mx-3">
          <div class="file has-name is-boxed">
            <label class="file-label">
              <input
                class="file-input"
                type="file"
                name="image"
                @change="handle">

              <span class="file-cta">
                <span class="icon">
                  <font-awesome-icon icon="upload" />
                </span>

                <span class="file-label">
                  Kies je afbeelding
                </span>
              </span>
            </label>
          </div>
        </div>
      </div>

      <button
        class="modal-close is-large"
        aria-label="close"
        @click="toggle">
      </button>
    </div>
  </div>
</template>

<script>
import { Recipes } from '@/services/recipes'

export default {
  name: 'Uploader',

  props: {
    min: {
      type: Number,
      default: null
    },

    max: {
      type: Number,
      default: null
    },

    endpoint: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isActive: false,
      rules: {
        size: (file) => {
          if (file.size > 2e6) {
            this.$store.commit('messages/PUSH_MESSAGE', this.messages.size)
            return false
          }

          return true
        },
        types: (file) => {
          const allowed = ['image/png', 'image/jpeg', 'image/gif']

          if (!allowed.includes(file.type)) {
            this.$store.commit('messages/PUSH_MESSAGE', this.messages.types)
            return false
          }

          return true
        }
      },
      messages: {
        size: 'De afbeelding is te groot, het bestand mag maximaal 2MB zijn.',
        types: 'Het bestand is geen afbeelding. Upload alleen png, jpeg, of gif bestanden.'
      }
    }
  },

  methods: {
    toggle () {
      this.isActive = !this.isActive
    },

    handle (payload) {
      const file = payload.target.files[0]

      if (this.validates(file)) {
        this.convert(file)
        this.toggle()
      }
    },

    convert (payload) {
      const reader = new FileReader()

      reader.onload = (e) => this.upload(e.target.result)

      reader.readAsDataURL(payload)
    },

    upload (data) {
      (new Recipes()).upload(data, this.endpoint).then(
        res => this.$emit('uploader:ready', res.data.path)
      ).catch(
        e => console.error(e.error)
      )
    },

    validates (file) {
      return ![this.rules.size(file), this.rules.types(file)].includes(false)
    }
  }
}
</script>

<style scoped>
  button {
    border: 0;
  }

  .file-label {
    width: 100%;
  }
</style>
