const SET = (store, params) => {
  store.status = params.status
  store.message = params.response?.message
  store.redirect = params.response?.redirect

  if (params.status !== 2) {
    setTimeout(() => store.status = null, 8000)
  }
}

export default {
  SET
}
