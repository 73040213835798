import { Suppliers } from '@/services/suppliers'

const loadAll = (context, admin) => {
  (new Suppliers(admin)).fetch().then(
    res => context.commit('SET_SUPPLIERS', res.data)
  ).catch(
    e => console.error(e)
  )
}

const load = (context, params) => {
  (new Suppliers(params.admin)).fetch(params.id).then(
    res => context.commit('SET_SUPPLIER', res.data)
  ).catch(
    e => console.error(e)
  )
}

const save = (context, params) => {
  (new Suppliers(true)).save(params).then(
    res => params.id ? context.commit('UPDATE', params) : context.commit('PUSH', res.data)
  ).catch(
    e => console.error(e)
  )
}

const remove = (context, unitId) => {
  (new Suppliers(true)).destroy(unitId).then(
    () => context.commit('REMOVE', unitId)
  ).catch(
    e => console.error(e)
  )
}

export default {
  save,
  load,
  remove,
  loadAll
}
