<template>
  <form @submit.prevent="submit">
    <hr>

    <h2 class="subtitle">{{ $t('signin.change_password') }}</h2>

    <div class="field">
      <template v-if="token">
        <label>{{ $t('signin.email.label') }}</label>
        <div class="control">
          <input
              class="input"
              type="text"
              name="email"
              placeholder="signin.email.label.placeholder"
              v-model="email"
          >
        </div>
      </template>

      <template v-else>
        <label>{{ $t('signin.current_password.label') }}</label>
        <div class="control">
          <input
              class="input"
              type="password"
              name="password"
              placeholder="signin.current_password.label.placeholder"
              v-model="current_password"
          >
        </div>
      </template>
    </div>

    <div class="field">
      <label>{{ $t('signin.new_password.label') }}</label>
      <div class="control">
        <input
          class="input"
          type="password"
          name="password"
          placeholder="signin.new_password.label.placeholder"
          :class="{ 'is-danger': hasErrors('password') }"
          v-model="password"
        >

        <p
          v-if="hasErrors('password')"
          class="help has-text-danger"
        >
          {{ messages.password | parseErrors }}
        </p>

        <p
            v-else
            class="help"
        >
          {{ $t('signin.password.properties') }}
        </p>
      </div>
    </div>

    <div class="field">
      <label>{{ $t('signin.new_password_confirmation.label') }}</label>
      <div class="control">
        <input
          class="input"
          type="password"
          name="password_confirmation"
          placeholder="signin.new_password.label.placeholder"
          v-model="password_confirmation"
        >
      </div>

      <p
        v-if="password !== password_confirmation || hasErrors('password_confirmation')"
        class="help is-danger"
      >
        {{ $t('signin.password_confirmation.error') }}
      </p>
    </div>

    <form-buttons
      :disabled="!isValid"
      :no-cancel="true"
      :no-remove="true"
      @save="submit"
    ></form-buttons>
  </form>
</template>

<script>
import FormButtons from '@/components/modules/FormButtons'
import { mapGetters } from 'vuex'

export default {
  name: 'Password',

  props: {
    expiration: {
      type: String,
      default: null,
    },

    token: {
      type: String,
      default: null,
    }
  },

  components: {
    FormButtons
  },

  filters: {
    parseErrors (errors) {
      return errors.join(' ')
    }
  },

  data () {
    return {
      email: null,
      password: null,
      password_confirmation: null,
      current_password: null,
    }
  },

  computed: {
    ...mapGetters({
      messages: 'messages/messages'
    }),

    isValid () {
      if ([this.password, this.password_confirmation].includes(null)) {
        return false;
      }

      const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*`.,-])(?=.{8,})")

      return ![
        this.token? this.email.length !== 0 : this.current_password.length !== 0,
        this.password.length >= 8,
        re.test(this.password),
        this.password === this.password_confirmation
      ].includes(false)
    }
  },

  methods: {
    hasErrors(field) {
      return Object.keys(this.messages).includes(field)
    },

    submit () {
      this.$store.dispatch('account/password', {
        expiration: this.expiration,
        token: this.token,
        email: this.email,
        current_password: this.current_password,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
    },
  }
}
</script>

<style scoped>

</style>
