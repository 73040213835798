<template>
  <form @submit.prevent="submit">
    <div class="field">
      <label>{{ $t('signin.name.label') }}</label>
      <div class="control">
        <input
          class="input"
          type="text"
          name="name"
          v-model="name"
        >
      </div>
    </div>

    <div class="field">
      <label>{{ $t('signin.email.label') }}</label>
      <div class="control">
        <input
          class="input"
          type="email"
          name="email"
          v-model="email"
        >
      </div>
    </div>

    <form-buttons
      :disabled="!isValid"
      :no-cancel="true"
      @save="submit"
      @remove="remove"
    ></form-buttons>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'User',

  components: {
    FormButtons
  },

  data () {
    return {
      name: null,
      email: null,
    }
  },

  computed: {
    ...mapGetters({
      profile: 'account/profile'
    }),
    isValid () {
      return this.name.length !== 0 ||
          this.email.length !== 0
    }
  },

  mounted() {
    this.set()
  },

  watch: {
    profile () {
      if (this.profile) {
        this.set()
      }
    }
  },

  methods: {
    set () {
      this.name = this.profile.name
      this.email = this.profile.email
    },

    submit () {
      this.$store.dispatch('account/edit', {
        name: this.name,
        email: this.email,
      })
    },

    remove () {
      this.$router.push({ name: 'account.cancel' })
    }
  }
}
</script>

<style scoped>

</style>
