<template>
  <div class="card mb-2">
    <div
      class="card-content"
      @click="toggle"
    >
      <div class="media">
        <div class="media-content">
          <p class="title">{{ producer.name }}</p>
          <p class="subtitle">{{ producer.city }}</p>
        </div>
      </div>

      <div
        v-show="expanded"
        class="content"
      >
        <p>
          {{ producer.address }}<br />
          {{ producer.zip }}<br />
          {{ producer.city }}<br />
          {{ producer.country }}<br />
        </p>
      </div>
    </div>

    <div v-if="expanded" class="card-footer">
      <a class="card-footer-item" :href="'mailto:' + producer.email ">{{ producer.email }}</a>
      <a class="card-footer-item" :href="'tel:' + producer.phone ">{{ producer.phone }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Producer',

  props: {
    producer: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      expanded: false
    }
  },

  methods: {
    toggle () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style scoped>

</style>
