<template>
  <div id="adminWorkplaceformulasWrapper">
    <router-view></router-view>

    <table v-show="!$route.params.id" class="table is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>{{ $t('admin.workplace.name') }}</th>
          <th>
            <label class="checkbox">
              {{ $t('admin.workplace.preload') }}
              <input type="checkbox" v-model="filterPreloads">
            </label>
          </th>
          <th>
            <label class="checkbox">
              {{ $t('admin.workplace.basemix') }}
              <input type="checkbox" v-model="filterBasemixes">
            </label>
          </th>
<!--          <th>-->
<!--            <label class="checkbox">-->
<!--              {{ $t('admin.workplace.profiler') }}-->
<!--              <input type="checkbox" v-model="filterProfiler">-->
<!--            </label>-->
<!--          </th>-->
          <th>
            <label class="checkbox">
              {{ $t('admin.workplace.recipe') }}
              <input type="checkbox" v-model="filterRecipes">
            </label>
          </th>
        </tr>
      </thead>

      <draggable
        tag="tbody"
        v-model="recipesList"
        @start="drag=true"
        @end="drag=false"
      >
        <tr
            v-for="recipe in filteredRecipes"
            :key="recipe.id"
            :title="recipe.description"
            @click="edit(recipe.id)">

          <td>{{ recipe.name }}</td>
          <td>
            <span v-show="recipe.is_preload" class="icon">
              <font-awesome-icon icon="check" />
            </span>
          </td>

          <td>
            <span v-show="recipe.is_base" class="icon">
              <font-awesome-icon icon="check" />
            </span>
          </td>

<!--          <td>-->
<!--            <span v-show="recipe.is_profiler" class="icon">-->
<!--              <font-awesome-icon icon="check" />-->
<!--            </span>-->
<!--          </td>-->

          <td>
            <span v-show="!recipe.is_preload && !recipe.is_base && !recipe.is_profiler" class="icon">
              <font-awesome-icon icon="check" />
            </span>
          </td>
        </tr>
      </draggable>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'

export default {
  name: 'AdminWorkplaceFormulas',

  components: {
    Draggable
  },

  data () {
    return {
      filterRecipes: false,
      filterPreloads: false,
      filterProfiler: false,
      filterBasemixes: false
    }
  },

  computed: {
    ...mapGetters({
      recipes: 'recipes/recipes'
    }),
    recipesList: {
      get () {
        return this.filteredRecipes.map(recipe => {
          return {
            id: recipe.id,
            name: recipe.name
          }
        })
      },
      set (index) {
        this.$store.dispatch('recipes/reorder', { index: index })
      }
    },
    filteredRecipes () {
      if (!this.filterPreloads && !this.filterBasemixes && !this.filterRecipes && !this.filterProfiler) return this.recipes

      let recipes = []

      if (this.filterPreloads) recipes.push(...this.recipes.filter(recipe => recipe.is_preload))
      if (this.filterProfiler) recipes.push(...this.recipes.filter(recipe => recipe.is_profiler))
      if (this.filterBasemixes) recipes.push(...this.recipes.filter(recipe => recipe.is_base))
      if (this.filterRecipes) recipes.push(...this.recipes.filter(recipe => !recipe.is_preload && !recipe.is_base && !recipe.is_profiler))

      return recipes
    }
  },

  created () {
    this.$store.dispatch('recipes/loadAll')
  },

  methods: {
    edit (recipeId) {
      this.$router.push({ name: 'admin.workplace.formulas.edit', params: { id: recipeId } })
    }
  }
}
</script>

<style lang="css">
</style>
