<template>
  <div id="pageEditWrapper">
    <div
      class="notification"
      v-if="!hasLoaded">
      {{ $t('app.loading') }}
    </div>

    <template v-else>
      <p>
        <router-link :to="{ name: 'admin.pages' }">
          <span class="icon">
            <i class="fa fa-chevron-left"></i>
          </span> Terug
        </router-link>
      </p>

      <hr>

      <h2 class="header is-4">{{ (page) ? $t('admin.pages.edit') + ': ' + page.header : $t('admin.pages.new') }}</h2>

      <div class="field">
        <label
          class="label"
          for="parent"
        >
          {{ $t('admin.pages.parent') }}
        </label>

        <div class="select">
          <select
            name="parent"
            v-model="parentId"
          >
            <option value="">{{ $t('admin.pages.rootLevel') }}</option>

            <option
              v-for="p in pages"
              :key="p.id"
              :value="p.id"
              :selected="p.id === parentId"
            >
              {{ p.header }}
            </option>
          </select>
        </div>
      </div>

      <div class="field">
        <label class="label" for="is_published">
          <input type="checkbox" name="is_published" v-model="is_published"> {{ $t('admin.pages.publish') }}
        </label>
      </div>

      <div class="field">
        <label class="label" for="is_about">
          <input type="checkbox" name="is_about" v-model="is_about"> {{ $t('admin.pages.in_about') }}
        </label>
      </div>

      <div class="field">
        <label
          class="label"
          for="header">

          {{ $t('admin.pages.title') }}
        </label>

        <div class="control">
          <input class="input" type="text" name="header" v-model="header">
        </div>
      </div>

      <div class="field">
        <label
          class="label"
          for="body">

          {{ $t('admin.pages.text') }}
        </label>

        <div class="control">
          <rte
            :content="body"
            @contentChange="handleContentChange">
          </rte>
        </div>
      </div>

      <form-buttons
        :confirm="$t('admin.pages.delete.confirm')"
        @submit="submit"
        @close="close"
        @remove="remove">
      </form-buttons>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Rte from '@/components/modules/Rte'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'AdminPageEdit',
  components: {
    Rte,
    FormButtons
  },
  data () {
    return {
      header: null,
      body: null,
      parentId: null,
      is_published: null,
      is_about: null
    }
  },
  computed: {
    ...mapGetters({
      page: 'pages/page',
      pages: 'pages/pages'
    }),
    hasLoaded () {
      return this.page !== null || !this.$attrs.id
    }
  },
  created () {
    if (this.$attrs.id) {
      this.$store.dispatch('pages/loadById', this.$attrs.id)
    }
  },
  destroyed () {
    this.$store.commit('pages/RESET')
  },
  watch: {
    page () {
      if (this.page) {
        this.header = this.page.header
        this.body = this.page.body
        this.parentId = this.page.parent_id
        this.is_published = this.page.is_published
        this.is_about = this.page.is_about
      }
    }
  },
  methods: {
    handleContentChange (payload) {
      this.body = payload
    },
    submit () {
      this.$store.dispatch('pages/save', {
        id: (this.page) ? this.page.id : null,
        header: this.header,
        body: this.body,
        parent_id: this.parentId,
        is_published: this.is_published ? 1 : 0,
        is_about: this.is_about ? 1 : 0
      })
    },
    close () {
      this.$router.go(-1)
    },
    remove () {
      this.$store.dispatch('pages/remove', this.page.id)
      this.close()
    }
  }
}
</script>

<style lang="css">
</style>
