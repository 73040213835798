import { Producers } from '@/services/producers'

const loadAll = (context, admin) => {
  (new Producers(admin)).fetch().then(
    res => context.commit('SET_PRODUCERS', res.data)
  ).catch(
    e => console.error(e)
  )
}

const load = (context, params) => {
  (new Producers(params.admin)).fetch(params.id).then(
    res => context.commit('SET_PRODUCER', res.data)
  ).catch(
    e => console.error(e)
  )
}

const save = (context, params) => {
  (new Producers(true)).save(params).then(
    res => params.id ? context.commit('UPDATE', params) : context.commit('PUSH', res.data)
  ).catch(
    e => console.error(e)
  )
}

const remove = (context, unitId) => {
  (new Producers(true)).destroy(unitId).then(
    () => context.commit('REMOVE', unitId)
  ).catch(
    e => console.error(e)
  )
}

const toggleIngredient = (context, params) => {
  (new Producers(false)).toggle(params.producer, params.ingredient).then(
    () => context.commit('TOGGLE_INGREDIENT', params.ingredient)
  ).catch(
    e => console.error(e)
  )
}

export default {
  save,
  load,
  remove,
  loadAll,
  toggleIngredient,
}
