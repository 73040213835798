<template>
  <div
    class="modal"
    :class="{ 'is-active': isActive }"
  >
    <div class="modal-background"></div>

    <div class="modal-content">
      <div class="box">
        <recipe-form
          :in-workplace="true"
          @save="save"
          @close="close"
        ></recipe-form>
      </div>
    </div>
  </div>
</template>

<script>
import RecipeForm from '@/components/workplace/RecipeForm'

export default {
  name: 'EditRecipeModal',

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },

  components: {
    RecipeForm
  },

  methods: {
    save (payload) {
      this.$emit('save', payload)
    },
    close () {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="css" scoped>
.box {
  margin: 0 15px;
}
</style>
