<template>
  <div class="card">
    <header
        class="card-header"
        @click="toggle()"
    >
      <p class="card-header-title">
        {{ ingredient.name }} ({{ ingredient.unit.symbol }})

        <sup>
          <font-awesome-icon
              :icon="densityIcon(ingredient.density)"
              size="xs"
          />
        </sup>
      </p>

      <a class="card-header-icon">
          <span class="icon">
            <span class="icon">
              <font-awesome-icon :icon="isExpanded ? 'angle-up' : 'angle-down'" />
            </span>
          </span>
      </a>
    </header>

    <div class="card-content">
      <div
          class="content"
          :class="{ 'is-hidden': !isExpanded }"
          v-html="ingredient.description"
          @click="toggle()"
      />

      <vue-slider
        tooltip="always"
        tooltip-placement="bottom"
        :tooltip-formatter="'{value}' + ingredient.unit.symbol"
        :max="getMax(ingredient.limit)"
        :lazy="true"
        :value="myAmount(ingredient.id)"
        :interval="0.1"
        :dot-options="dotOptions(ingredient)"
        @change="set(ingredient, $event)"
      />
    </div>
  </div>
</template>

<script>
import recipe from "@/mixins/workplace/recipe";
import supplies from "@/mixins/workplace/supplies";
import machines from "@/mixins/workplace/machines";
import instruments from "@/mixins/workplace/instruments";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'IngredientCard',

  components: {
    VueSlider
  },

  props: {
    ingredient: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      isExpanded: false,
    }
  },

  mixins: [
    recipe,
    supplies,
    machines,
    instruments
  ],

  methods: {
    dotOptions (ingredient) {
      return [
        {
          min: 0,
          max: this.myLimit(ingredient)
        }
      ]
    },

    getMax(limit) {
      return parseFloat(limit)
    },

    myLimit () {
      if (!this.isRelative(this.ingredient) || this.ingredient.limit <= this.maxAvailable) {
        return parseFloat(this.ingredient.limit)
      }

      if (this.myAmount(this.ingredient.id) !== 0) {
        return parseFloat(this.myAmount(this.ingredient.id)) + this.maxAvailable
      }

      return this.maxAvailable
    },

    toggle () {
      this.isExpanded = !this.isExpanded
    },
  }
}
</script>

<style scoped>

</style>
