var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"indexWrapper"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title is-6 has-text-centered"},[_vm._v(_vm._s(_vm.$t('workplace.header')))])])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-quarter-desktop"},[_c('index-ad',{attrs:{"id":"desktopHomeAd","image":1}})],1),_c('div',{staticClass:"column is-half-desktop is-three-fifths-tablet is-offset-one-fifth-tablet is-offset-0-desktop"},[_c('router-link',{attrs:{"to":{ name: 'preloads', params: { mode: 'preloads' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_c('span',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('workplace.preloads.header'))+" ")]),_c('font-awesome-icon',{staticClass:"ml-3",attrs:{"icon":"info-circle"},on:{"click":function($event){return _vm.openVideo(0)}}})],1),_c('p',{staticClass:"card-header-icon",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"angle-right"}})],1)])]),_c('div',{staticClass:"card-content",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t('workplace.preloads.body'))+" ")])])])]}}])}),_c('index-ad',{attrs:{"id":"mobileHomeAd","image":0}}),(_vm.showProfiler)?_c('router-link',{attrs:{"to":{ name: 'preloads', params: { mode: 'preloads' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('workplace.profiler.header'))+" ")]),_c('p',{staticClass:"card-header-icon",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"angle-right"}})],1)])]),_c('div',{staticClass:"card-content",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t('workplace.profiler.body'))+" ")])])])]}}],null,false,2950009063)}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'formulas', params: { mode: 'bootstrapped' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_c('span',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t('workplace.bootstrapped.header'))+" ")]),_c('font-awesome-icon',{staticClass:"ml-3",attrs:{"icon":"info-circle"},on:{"click":function($event){return _vm.openVideo(2)}}})],1),_c('p',{staticClass:"card-header-icon",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"angle-right"}})],1)])]),_c('div',{staticClass:"card-content",attrs:{"href":href},on:{"click":navigate}},[_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t('workplace.bootstrapped.body'))+" ")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'workplace', params: { mode: 'diy' } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('div',{staticClass:"card",attrs:{"href":href},on:{"click":navigate}},[_c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('workplace.diy.header'))+" ")]),_c('p',{staticClass:"card-header-icon"},[_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"angle-right"}})],1)])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$t('workplace.diy.body'))+" ")])])])]}}])})],1),_c('modal',{attrs:{"is-active":_vm.showVideo !== null},on:{"close":function($event){_vm.showVideo = null}}},[_c('div',{staticClass:"video-container"},[_c('iframe',{attrs:{"src":_vm.videos[_vm.showVideo],"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }