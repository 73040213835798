import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  recipe: {
    name: null,
    ingredients: []
  },
  recipes: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
