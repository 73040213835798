const SET_PRODUCERS = (state, data) => {
  state.producers = data
}

const SET_PRODUCER = (state, data) => {
  state.producer = data
}

const PUSH = (state, data) => {
  state.producers.push(data)
}

const UPDATE = (state, data) => {
  const idx = state.producers.findIndex(x => x.id === data.id)

  if (idx !== -1) {
    state.producers[idx] = data
  }
}

const REMOVE = (state, id) => {
  state.producers = state.producers.filter(p => p.id !== id)
}

const TOGGLE_INGREDIENT = (state, ingredient) => {
  if (state.producer.ingredients.map(ing => ing.id).includes(ingredient.id)) {
    state.producer.ingredients = state.producer.ingredients.filter(ing => ing.id !== ingredient.id);
  } else {
    state.producer.ingredients.push(ingredient);
  }
}

export default {
  PUSH,
  UPDATE,
  REMOVE,
  SET_PRODUCER,
  SET_PRODUCERS,
  TOGGLE_INGREDIENT,
}
