const SET_PROFILE = (state, data) => {
  state.profile = data
}

const UNSET_PROFILE = (state) => {
  state.profile = null
}

const SET_REGISTRATION_STATUS = (state, status) => {
  state.registrationSuccessful = status
}

const UNSET_RECIPE = (state, recipeId) => {
  state.profile.recipes = state.profile.recipes.filter(recipe => recipe.id !== recipeId)
}

export default {
  SET_PROFILE,
  UNSET_RECIPE,
  UNSET_PROFILE,
  SET_REGISTRATION_STATUS
}
