import client from '@/http/client'

class Suppliers {
  path = 'suppliers'

  constructor(admin = false) {
    if (admin) this.path = `admin/${this.path}`
  }

  async fetch(id = null, path = null) {
    if (path) {
      return await client.get(path)
    }

    return await client.get(id ? `${this.path}/${id}` : this.path)
  }

  async save(data) {
    return data.id ?
      await client.put(`${this.path}/${data.id}`, data) :
      await client.post(this.path, data)
  }

  async destroy(id) {
    return await client.delete(`${this.path}/${id}`)
  }
}

export { Suppliers }
