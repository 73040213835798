<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Blog',
  created () {
    this.$store.dispatch('tags/loadAll', { filter: 'hasPosts' })
    this.$store.dispatch('posts/loadAll')
  }
}
</script>

<style lang="css" scoped>
.card {
  margin-bottom: 15px;
}
</style>
