<template>
  <div class="columns">
    <div class="column">
      <template v-if="hasLines">
        <bot-line
          v-for="line in lines"
          :key="line.id"
          :line="line"
          @load="loadLines">
        </bot-line>
      </template>

      <div v-else class="notification">De regels worden geladen...</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BotLine from '@/components/workplace/BotLine'

export default {
  name: 'AdminWorkplaceProfiler',
  components: {
    BotLine
  },
  computed: {
    ...mapGetters({
      lines: 'lines/lines'
    })
  },
  created () {
    this.loadLines((this.$attrs.from) ? this.$attrs.from : 1)
  },
  methods: {
    hasLines () {
      return this.lines && this.lines.length > 0
    },
    loadLines (id) {
      this.$store.dispatch('lines/next', { id: id })
    }
  }
}
</script>
