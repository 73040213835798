<template>
  <modal
    :is-active="isActive"
    :no-close-button="true"
  >
    <div class="notification is-danger">
      <p>{{ text }}</p>

      <hr>

      <div class="field is-grouped">
        <p class="control">
          <button
            class="button is-danger is-light"
            @click="$emit('confirmed')"
          >
            Ja, verwijderen
          </button>
        </p>

        <p class="control">
          <button
              class="button is-ghost has-text-white"
              @click="$emit('cancelled')"
          >
            Nee, annuleren
          </button>
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/modules/Modal'

export default {
  name: 'Confirm',

  props: {
    text: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },

  components: {
    Modal
  }
}
</script>

<style scoped>
  .notification {
    margin: 0 15px;
  }
</style>
