<template>
  <div id="adminWorkplaceIngredientsWrapper">
    <router-view v-if="'id' in $attrs"></router-view>

    <template v-else>
      <div class="columns">
        <div class="column">
          <router-link
              class="button is-pulled-right"
              :to="{ name: 'admin.workplace.ingredients.edit', params: { id: null } }">

            {{ $t('admin.workplace.newIngredient') }}
          </router-link>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <table v-if="ingredients.length > 0" class="table is-striped is-hoverable is-fullwidth">
            <thead>
              <th v-if="representsProducer">*</th>
              <th>{{ $t('admin.workplace.name') }}</th>
              <th>{{ $t('admin.workplace.group') }}</th>
              <th>{{ $t('admin.workplace.unit') }}</th>
            </thead>

            <tbody>
            <tr
                v-for="ingredient in ingredients"
                :key="ingredient.id"
            >

              <td v-if="representsProducer">
                <input
                  type="checkbox"
                  :checked="producerHasIngredient(ingredient)"
                  @change="toggleIngredient(ingredient)"
                >
              </td>

              <td
                :class="{ 'has-text-grey-light': !ingredient.is_active }"
                @click="goTo(ingredient.id)"
              >
                {{ ingredient.name }}
              </td>

              <td
                :class="{ 'has-text-grey-light': !ingredient.is_active }"
                @click="goTo(ingredient.id)"
              >
                {{ (ingredient.group) ? ingredient.group.name : '' }}
              </td>

              <td
                :class="{ 'has-text-grey-light': !ingredient.is_active }"
                @click="goTo(ingredient.id)"
              >
                {{ ingredient.unit.name }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminWorkplaceIngredients',

  computed: {
    ...mapGetters({
      ingredients: 'ingredients/ingredients',
      representsProducer: "account/representsProducer",
      profile: "account/profile",
      producer: "producers/producer",
    })
  },

  methods: {
    goTo (ingredientId) {
      this.$router.push({ name: 'admin.workplace.ingredients.edit', params: { id: ingredientId } })
    },

    producerHasIngredient(ingredient) {
      return this.producer.ingredients.map((ing) => ing.id).includes(ingredient.id);
    },

    toggleIngredient(ingredient) {
      this.$store.dispatch("producers/toggleIngredient", { producer: this.producer, ingredient } );
    }
  },

  watch: {
    representsProducer() {
      if (this.representsProducer) {
        this.$store.dispatch("producers/load", { admin: false, id: this.profile.represents }).then((res) => console.log(res));
      }
    }
  },
};
</script>

<style lang="css">
</style>
