const INITIALIZE = (state) => {
  state.recipe = {
    name: null,
    mode: 'diy',
    ingredients: []
  }
}

const SET_UNITS = (state, data) => {
  state.units = data
}

const SET_GROUPS = (state, data) => {
  state.groups = data
}

const SET_NAME = (state, name) => {
  state.recipe.name = name
}

const SET_DESCRIPTION = (state, description) => {
  state.recipe.description = description
}

const SET_RECIPE = (state, data) => {
  state.recipe = data
}

const SET_INGREDIENTS = (state, data) => {
  state.ingredients = data;
}

const SET_PRODUCER_INDEX = (state, data) => {
  state.producerIndex = data;
}

const EDIT_RECIPE = (state, data) => {
  state.recipe.ingredients = data
  localStorage.setItem('recipe', JSON.stringify(state.recipe))
}

const LOAD_RECIPE = (state, data) => {
  state.recipe = JSON.parse(data)
}

const SET_ADDITIVES = (state, data) => {
  state.additives = data
}

const SET_SAVED = (state, status) => {
  state.isSaved = status
}

const SET_SAVING = (state, status) => {
  state.isSaving = status
}

const SET_ORDER = (state, order) => {
  const newOrder = order.map((ing, key) => {
    const ingredient = state.recipe.ingredients.find(x => x.id === ing.id)
    ingredient.pivot.sort_order = key

    return ingredient
  })

  state.recipe.ingredients = newOrder
}

const PURGE = (state) => {
  state.recipe = {}
  state.recipe.name = null
  state.recipe.ingredients = []

  localStorage.removeItem('recipe')
}

export default {
  PURGE,
  SET_NAME,
  SET_UNITS,
  SET_SAVED,
  SET_ORDER,
  SET_GROUPS,
  SET_RECIPE,
  SET_SAVING,
  INITIALIZE,
  LOAD_RECIPE,
  EDIT_RECIPE,
  SET_ADDITIVES,
  SET_DESCRIPTION,
  SET_INGREDIENTS,
  SET_PRODUCER_INDEX,
}
