/*
 * Load core
 */
import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import VueTrix from 'vue-trix'
import VueMoment from 'vue-moment'
// import * as Sentry from "@sentry/vue"

// Sentry.init({
//   Vue: Vue,
//   dsn: "https://73bf8ff2c673497e947140e84f132e72@o245130.ingest.sentry.io/5731944"
// })

/*
 * Import router and vuex store
 */
import router from './router'
import store from './store'

/*
 * Import other libraries
 */
import client from '@/http/client'
import '../node_modules/bulma/css/bulma.min.css'
import '../node_modules/bulma-switch/dist/css/bulma-switch.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown, faAngleRight, faAngleUp, faCheck, faChevronLeft,
  faChevronRight, faEnvelope, faExclamationTriangle,
  faChevronUp, faChevronDown, faPencilAlt,
  faPercentage, faPlus, faMinus,
  faSearch, faTags, faUpload, faSync,
  faSort, faUserSecret, faCopyright,
  faSave, faTimes, faTrash, faUndo,
  faArrowDown, faArrowUp, faHome, faInfoCircle,
  faCircleNotch, faDownload, faGripLines
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/*
 * Other global dependencies
 */
import VueScreen from 'vue-screen'
import VueCookies from 'vue-cookies'
import VueMobileDetection from 'vue-mobile-detection'

/*
 * Set app state
 */
Vue.config.productionTip = process.env.NODE_ENV === 'production'
Vue.prototype.$factor = 1000

/*
 * Initialise event bus
 */
Vue.prototype.$bus = new Vue()

/*
 * Initialise app dependencies
 */
Vue.use(VueI18n)
Vue.use(VueTrix)
Vue.use(VueScreen, 'bulma')
Vue.use(VueMoment)
Vue.use(VueCookies)
Vue.use(VueMobileDetection)
/*
 * FontAwesome icons
 */
library.add(
  faUserSecret,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faMinus,
  faUpload,
  faDownload,
  faSort,
  faAngleUp,
  faAngleDown,
  faAngleRight,
  faTags,
  faTrash,
  faSearch,
  faPercentage,
  faPencilAlt,
  faCheck,
  faEnvelope,
  faExclamationTriangle,
  faSave,
  faTimes,
  faGripLines,
  faArrowDown,
  faArrowUp,
  faCircleNotch,
  faHome,
  faCopyright,
  faUndo,
  faSync,
  faInfoCircle
)

Vue.$cookies.config('95d')

router.afterEach(() => {
  Vue.prototype.$bus.$emit('routerLinkClicked')
})

/*
 * External components
 */
Vue.component('font-awesome-icon', FontAwesomeIcon)

const locale = 'nl'

client.get(`${process.env.VUE_APP_API}/api/languages/${locale}`).then(
  res => {
    const i18n = new VueI18n({
      locale: locale,
      messages: res.data
    })

    new Vue({
      i18n,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
)
