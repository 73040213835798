<template>
  <div id="adminWorkplaceGroupsWrapper">
    <router-view v-if="'id' in $attrs"></router-view>

    <template v-else>
      <div class="columns">
        <div class="column">
          <router-link
              class="button is-pulled-right"
              :to="{ name: 'admin.suppliers.edit', params: { id: null } }">

            {{ $t('admin.suppliers.newSupplier') }}
          </router-link>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <router-link
              v-for="supplier in suppliers"
              :key="supplier.id"
              :to="{ name: 'admin.suppliers.edit', params: { id: supplier.id } }"
              tag="div"
              class="card">
            <div class="card-content">
              {{ supplier.name }}
            </div>
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminSuppliers',

  computed: {
    ...mapGetters({
      suppliers: 'suppliers/suppliers'
    })
  },

  created () {
    this.$store.dispatch('suppliers/loadAll', true)
  }
}
</script>

<style scoped>

</style>
