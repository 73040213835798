import client from '@/http/client'

class Ads {
  path = 'ads'

  constructor(admin = false) {
    if (admin) this.path = `admin/${this.path}`
  }

  async fetch(id) {
    return await client.get(`${this.path}/${id}`)
  }

  async inc(id) {
    return await client.put(`${this.path}/count/${id}`)
  }

  async save(data) {
    return data.id ?
      await client.put(`${this.path}/${data.id}`, data) :
      await client.post(this.path, data)
  }

  async destroy(id) {
    return await client.delete(`${this.path}/${id}`)
  }
}

export { Ads }
