<template>
  <div class="columns">
    <div class="column is-three-fifth-tablet is-half-desktop is-offset-one-fifth-tablet is-offset-one-quarter-desktop">
      <sent v-if="sent"></sent>

      <contact-form
        v-else
        @sent="setSent"
      ></contact-form>
    </div>
  </div>
</template>

<script>
import ContactForm from '@/components/contact/Form'
import Sent from '@/components/contact/Sent'

export default {
  name: 'Contact',

  data () {
    return {
      sent: false
    }
  },

  components: {
    ContactForm,
    Sent
  },

  beforeDestroy() {
    this.sent = false
  },

  methods: {
    setSent () {
      this.sent = true
    }
  }
}
</script>

<style scoped>

</style>
