<template>
  <div id="profileWrapper">
    <user />
    <Password />
  </div>
</template>

<script>
import User from '@/components/profile/User'
import Password from '@/components/profile/Password'

export default {
  name: 'Profile',

  components: {
    User,
    Password,
  }
}
</script>
