const lines = state => state.lines
const units = state => state.units
const groups = state => state.groups
const recipe = state => state.recipe
const isSaved = state => state.isSaved
const isSaving = state => state.isSaving
const additives = state => state.additives
const ingredients = state => state.ingredients
const producerIndex = state => state.producerIndex

export default {
  lines,
  units,
  groups,
  recipe,
  isSaved,
  isSaving,
  additives,
  ingredients,
  producerIndex,
}
