import { Tags } from '@/services/tags'

const loadAll = (context, params) => {
  (new Tags()).fetch(params).then(
    res => context.commit('SET_TAGS', res.data)
  ).catch(
    e => console.error(e)
  )
}

export default {
  loadAll
}
