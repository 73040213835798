<template>
  <div id="adminWorkplaceGroupsWrapper">
    <router-view></router-view>

    <table v-show="!$route.params.id" class="table is-striped is-hoverable">
      <thead>
        <tr>
          <th>{{ $t('admin.workplace.name') }}</th>
          <th>{{ $t('admin.workplace.abbreviation') }}</th>
          <th>{{ $t('admin.workplace.symbol') }}</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="unit in units"
          :key="unit.id"
          @click="edit(unit.id)">

          <td>{{ unit.name }}</td>
          <td>{{ unit.abbreviation }}</td>
          <td>{{ unit.symbol }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminWorkplaceUnits',
  computed: {
    ...mapGetters({
      units: 'units/units'
    })
  },
  methods: {
    edit (unitId) {
      this.$router.push({ name: 'admin.workplace.units.edit', params: { id: unitId } })
    }
  }
}
</script>

<style lang="css">
</style>
