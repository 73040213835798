import client from '@/http/client'

class AdPositions {
  path = 'ads'

  async fetch(id) {
    return await client.get(`${this.path}/${id}`)
  }
}

export { AdPositions }
