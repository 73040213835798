<template>
  <div class="columns">
    <div
      v-if="profile"
      class="column"
    >
      <div class="field">
        <label>{{ $t('workplace.recipe.name.label') }}</label>
        <div class="control">
          <input class="input" type="text" name="name" v-model="name">
        </div>
      </div>

      <div class="field">
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="forceCreate"
          >
          {{ $t('workplace.saveAsNew') }}
        </label>
      </div>

      <div class="field">
        <tag-field
          :tags="profile.labels"
          :selected="labels"
          @add="addLabel"
          @push="addLabel"
          @remove="removeLabel"
        ></tag-field>
      </div>

      <template v-if="isAdmin && recipe.mode === 'diy'">
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" v-model="is_base">
              {{ $t('admin.workplace.basemix') }}
            </label>
          </div>
        </div>

        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input type="checkbox" v-model="is_preload">
              {{ $t('admin.workplace.preload') }}
            </label>
          </div>
        </div>

        <div class="field" v-show="is_base">
          <label>{{ $t('admin.workplace.limit') }}</label>
          <div class="control">
            <input class="input" type="number" name="limit" v-model="limit">
          </div>
        </div>
      </template>

      <div class="columns is-clipped">
        <div class="column">
          <label>{{ $t('admin.workplace.images') }}</label>
        </div>

        <div class="column">
          <figure
              class="image is-64x64 is-pulled-left is-clipped"
              v-for="(image, idx) in images"
              :key="idx">

            <img :src="image">

            <button
                class="delete"
                @click="setRemove(idx)"
            ></button>
          </figure>

          <uploader
            v-show="images.length < 3"
            :endpoint="`recipes/${this.id}/images/upload`"
            @uploader:ready="addImage"
          >
          </uploader>
        </div>
      </div>

      <div class="field">
        <label>{{ $t('workplace.recipe.description.label') }}</label>

        <div class="control">
          <rte
            v-if="isAdmin"
            :content="description"
            @contentChange="handleDescriptionChange"
          ></rte>

          <textarea
            name="description"
            class="textarea"
            v-else
            v-model="description"
          ></textarea>
        </div>
      </div>

      <form-buttons
        :no-remove="true"
        @submit="submit"
        @close="cancel"
      ></form-buttons>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import rteMixin from '@/mixins/rte'
import authMixin from '@/mixins/auth'
import imagesMixin from '@/mixins/images'
import Rte from '@/components/modules/Rte'
import recipe from '@/mixins/workplace/recipe'
import Uploader from '@/components/modules/Uploader'
import TagField from '@/components/modules/TagField'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'RecipeForm',

  props: {
    isActive: {
      type: Boolean,
      default: false
    },

    inWorkplace: {
      type: Boolean,
      default: false
    }
  },

  mixins: [
    recipe,
    rteMixin,
    authMixin,
    imagesMixin
  ],

  components: {
    Rte,
    TagField,
    Uploader,
    FormButtons
  },

  data () {
    return {
      id: null,
      limit: 0,
      name: null,
      labels: [],
      images: [],
      uploads: [],
      maxImages: 3,
      is_base: false,
      is_preload: false,
      is_profiler: false,
      description: '',
      forceCreate: false
    }
  },

  computed: {
    ...mapGetters({
      profile: 'account/profile',
      isSaved: 'workplace/isSaved',
      isSaving: 'workplace/isSaving'
    }),

    recipe () {
      return this.inWorkplace ? this.$store.getters['workplace/recipe'] : this.$store.getters['recipes/recipe']
    },

    isValid () {
      return this.name &&
          this.name.length > 0
    },

    buttonIcon () {
      if (!this.isSaving && this.isSaved === null) return 'fa-save'
      if (this.isSaved === null) return 'fa-circle-notch fa-spin'

      return (this.isSaved) ? 'fa-check' : 'fa-times'
    },

    buttonColor () {
      return (this.isSaved === null) ? 'is-link' : (this.isSaved) ? 'is-success' : 'is-danger'
    }
  },

  mounted () {
    this.setProperties()
  },

  watch: {
    recipe () {
      this.setProperties()
    }
  },

  methods: {
    submit () {
      this.$emit('save', {
        id: (this.forceCreate) ? null : this.id,
        name: this.name,
        mode: this.recipe.mode,
        labels: this.labels,
        images: this.images,
        is_base: this.is_base,
        is_preload: this.is_preload,
        is_profiler: this.is_profiler,
        description: this.description,
        ingredients: this.recipe.ingredients,
        limit: (this.isInBootstrapMode) ? this.limit : 0,
      })
    },

    cancel () {
      this.name = this.description = null
      this.$emit('close')
    },

    onSuccess (payload) {
      this.images.push(payload.path)
    },

    setProperties () {
      this.id = this.recipe.id
      this.name = this.recipe.name
      this.limit = this.recipe.limit
      this.labels = (this.recipe.labels) ? JSON.parse(JSON.stringify(this.recipe.labels)) : []
      this.images = (this.recipe.images) ? this.recipe.images : []
      this.is_base = this.recipe.is_base === 1
      this.is_preload = this.recipe.is_preload === 1
      this.is_profiler = this.recipe.is_profiler === 1
      this.description = this.recipe.description
    },

    addLabel (label) {
      (label.id) ? this.labels.push(label) : this.labels.push({ label: label })
    },

    removeLabel (idx) {
      this.labels.splice(idx, 1)
    }
  }
}
</script>

<style scoped>
figure {
  margin-right: 15px;
}

.delete {
  position: absolute;
  right: 2px;
  top: 2px;
}
</style>
