<template>
  <div class="columns">
    <div class="column is-one-fifth-tablet is-one-quarter-desktop">
      <back-link
        to="posts"
        :hide-on-desktop="false"
      ></back-link>
    </div>

    <div
      v-if="post"
      id="postWrapper"
      class="column is-three-fifths-tablet is-half-desktop"
    >
      <h1 class="title is-4">
        {{ post.title }}
      </h1>

      <div class="tags">
        <span
          v-for="tag in post.tags"
          :key="tag.id"
          class="tag is-light">

          {{ tag.tag }}
        </span>
      </div>

      <p class="is-size-7 has-text-weigth-bold">
        <time :datetime="post.published_at | moment('Y-M-D H:m')">{{ post.published_at | moment('D-M-Y H:mm') }}</time>
      </p>

      <div class="content" v-html="post.body"></div>

      <p>
        <router-link
          v-if="previous"
          :to="{ name: 'post', params: { id: previous.id } }">

          <span class="icon">
            <font-awesome-icon icon="chevron-left" />
          </span>

          {{ previous.title }}
        </router-link>

        <router-link
          class="is-pulled-right"
          v-if="next"
          :to="{ name: 'post', params: { id: next.id } }">

          {{ next.title }}

          <span class="icon">
            <font-awesome-icon icon="chevron-right" />
          </span>
        </router-link>
      </p>
    </div>

    <div class="notification" v-else>
      {{ $t('app.loading') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BackLink from '@/components/modules/BackLink'

export default {
  name: 'PostDetail',
  components: {
    BackLink
  },
  computed: {
    ...mapGetters({
      post: 'posts/post',
      posts: 'posts/filtered'
    }),
    id () {
      return this.$attrs.id
    },
    previous () {
      if (this.idx) {
        if (this.posts[this.idx - 1]) {
          return this.posts[this.idx - 1]
        }
      }

      return null
    },
    next () {
      if (this.idx !== null) {
        if (this.posts[this.idx + 1]) {
          return this.posts[this.idx + 1]
        }
      }

      return null
    },
    idx () {
      const idx = this.posts.findIndex(x => x.id === parseInt(this.$attrs.id))

      if (idx !== -1) {
        return idx
      }

      return null
    }
  },
  created () {
    this.load()

    if (this.posts.length === 0) {
      // this.$store.dispatch('posts/loadAll')
    }
  },
  beforeDestroy () {
    this.$store.commit('posts/UNSET_POST')
  },
  watch: {
    id () {
      this.load()
    }
  },
  methods: {
    load () {
      this.$store.dispatch('posts/load', this.id)
    }
  }
}
</script>

<style lang="css">
</style>
