<template>
  <div id="postsWrapper">
    <h1>{{ $t('admin.menu.blog')}}</h1>

    <router-link
      class="button is-pulled-right"
      :to="{ name: 'admin.posts.edit' }">

      {{ $t('admin.blog.new') }}
    </router-link>

    <ul>
      <li
        v-for="post in posts"
        :key="post.id">
        <router-link :to="{ name: 'admin.posts.edit', params: { id: post.id } }">{{ post.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminPosts',
  computed: {
    ...mapGetters({
      posts: 'posts/posts'
    })
  },
  created () {
    this.$store.dispatch('posts/loadAll', true)
  }
}
</script>

<style lang="css">
</style>
