import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  post: null,
  posts: [],
  filter: [],
  filtered: []
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
