<template>
  <div id="pagesWrapper">
    <div class="columns">
      <div class="column">
        <h1>{{ $t('admin.pages.header') }}</h1>
      </div>

      <div class="column">
        <router-link
            class="button is-pulled-right"
            :to="{ name: 'admin.pages.edit' }">

          {{ $t('admin.pages.new') }}
        </router-link>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <draggable
          tag="ul"
          v-model="pageList"
          @start="drag=true"
          @end="drag=false"
        >
          <li
            v-for="page in pages"
            :key="page.id"
          >
            <div class="box m-2">
              <router-link :to="{ name: 'admin.pages.edit', params: { id: page.id } }">{{ page.header }}</router-link>
            </div>
          </li>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'

export default {
  name: 'AdminPages',

  components: {
    Draggable
  },

  computed: {
    ...mapGetters({
      pages: 'pages/pages'
    }),
    pageList: {
      get () {
        return this.pages.map(page => {
          return {
            id: page.id,
            header: page.header
          }
        })
      },
      set (index) {
        this.$store.dispatch('pages/order', { index: index })
      }
    }
  },

  created () {
    this.$store.dispatch('pages/loadAll')
  }
}
</script>
