<template>
  <div id="recipeWrapper" class="container">
    <div class="columns">
      <div class="column">
        <ingredient-details
          v-if="details"
          :ingredient="details"
          @close="toggleDetails"
        ></ingredient-details>

        <article
          id="recipe"
          class="message is-info is-radiusless"
        >
          <div
            @click="toggle"
            class="message-header is-radiusless"
          >
            <div :class="{ 'is-uppercase': recipe.name }">
              <div class="tags">
                <span class="tag" :class="withinBounds ? 'is-success' : 'is-danger'">{{ recipeStatus }}%</span>
                <span
                  v-show="floatility >= 5 * $factor"
                  class="tag is-danger">
                  <font-awesome-icon
                    icon="exclamation-triangle"></font-awesome-icon>
                </span>
                <span class="tag">{{ (recipe.name) ? recipe.name : $t('workplace.recipe.header') }}</span>
              </div>
            </div>

            <font-awesome-icon
              v-show="$screen.width < 1024"
              :icon="isActive ? 'chevron-down' : 'chevron-up'"
              class="is-pulled-right"
            ></font-awesome-icon>
          </div>

          <div
            class="message-body"
            :class="{ 'is-hidden': $screen.width < 1024 ? !isActive : false }"
          >
            <h2 class="title is-6">
              {{ $t('workplace.recipe.ingredients') }}

              <font-awesome-icon
                icon="sort"
                class="is-pulled-right"
                :class="{ 'has-text-grey-light': !sorting }"
                @click="toggleSorting"
              />
            </h2>

            <p
              v-show="floatility >= 5 * $factor"
              class="help is-danger mb-4"
            >
              <font-awesome-icon icon="arrow-up" />
              {{ $t('workplace.buoyancyLimitReached') }}
            </p>

            <table
              v-if="sorting"
              class="table is-fullwidth is-striped is-hoverable"
            >
              <draggable
                tag="tbody"
                class="list"
                draggable=".ingredient"
                v-model="list"
                @start="drag=true"
                @end="drag=false"
              >
                <tr
                  v-for="ingredient in list"
                  class="ingredient"
                  :key="ingredient.id"
                >
                  <td @click="toggleDetails(ingredient)">
                    {{ ingredient.name }}
                    <sup>
                      <font-awesome-icon
                        :icon="densityIcon(ingredient.density)"
                        size="xs"
                      />
                    </sup>
                  </td>

                  <td class="has-text-right is-narrow">
                    <span :class="{ 'has-text-danger': isLocked(ingredient) }">{{ (ingredient.amount / $factor).toFixed(2) }}{{ ingredient.symbol }}</span>
                  </td>

                  <td class="is-narrow">
                    <font-awesome-icon icon="grip-lines"/>
                  </td>
                </tr>
              </draggable>
            </table>

            <table
              v-else
              class="table is-fullwidth is-striped is-hoverable"
            >
              <tbody>
                <tr
                  v-for="ingredient in list"
                  class="ingredient"
                  :key="ingredient.id"
                >
                  <td class="is-narrow">
                    <span
                      v-if="isEditable(ingredient)"
                      @click="del(findIngredient(ingredient.id))"
                      class="icon has-text-danger"
                    >
                      <font-awesome-icon icon="times" />
                    </span>
                  </td>

                  <td @click="toggleDetails(ingredient)">
                    {{ ingredient.name }}
                    <sup>
                      <font-awesome-icon
                        :icon="densityIcon(ingredient.density)"
                        size="xs"
                      />
                    </sup>
                  </td>

                  <td class="has-text-right is-narrow"><span :class="{ 'has-text-danger': isLocked(ingredient) }">{{ (ingredient.amount / $factor).toFixed(1) }}{{ ingredient.symbol }}</span></td>

                  <td class="is-narrow">
                    <template v-if="isEditable(ingredient)">
                      <span
                        @click="dec(findIngredient(ingredient.id))"
                        class="icon"
                      >
                        <font-awesome-icon icon="minus" />
                      </span>
                      <span
                        :class="{ 'has-text-grey-lighter': isLocked(ingredient) }"
                        @click="inc(findIngredient(ingredient.id))"
                        class="icon"
                      >
                        <font-awesome-icon icon="plus" />
                      </span>
                    </template>
                  </td>
                </tr>

                <tr slot="footer" v-if="recipe.mode === 'diy'">
                  <th>Totaal</th>
                  <th colspan="2" class="has-text-right">{{ total }}%</th>
                  <th></th>
                </tr>
              </tbody>
            </table>

            <div class="box is-outlined">
              <h2 class="title is-6">{{ $t('recipe.nutrients.title') }}</h2>

              <ul>
                <li
                  v-for="(nutrient, key, idx) in nutritionalValues"
                  :key="idx"
                >
                  <strong>{{ $t(`recipe.nutrients.${key}`) }}:</strong> <span class="is-pulled-right">{{ nutrient.toFixed(1) }}{{ getSymbol(key) }}</span>
                </li>
              </ul>
            </div>

            <div
              v-show="!sorting"
              class="field is-grouped"
            >
              <button
                class="button control"
                :disabled="!auth"
                @click="$emit('save')">

                <span class="icon">
                  <font-awesome-icon icon="save" />
                </span>

                <span>{{ $t('workplace.recipe.save') }}</span>
              </button>

              <button
                class="button control"
                @click="purge">

                <span class="icon has-text-danger">
                  <font-awesome-icon icon="times" />
                </span>

                <span>{{ $t('workplace.recipe.delete') }}</span>
              </button>
            </div>
            <p v-show="!auth" class="has-text-weight-semibold">Log eerst in om het recept op te kunnen slaan.</p>
          </div>
        </article>

        <modal :is-active="details !== null">
          <div v-if="details" class="box m-4">
            <button
              class="delete is-pulled-right"
              @click="toggleDetails(null)"
            />

            <b>{{ details.name }}</b>

            <p class="html" v-html="details.description"></p>
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import auth from '@/mixins/auth'
import recipe from '@/mixins/workplace/recipe'
import machines from '@/mixins/workplace/machines'
import instruments from '@/mixins/workplace/instruments'

import Modal from '@/components/modules/Modal'
import supplies from "@/mixins/workplace/supplies"
import Draggable from 'vuedraggable'
import IngredientDetails from '@/components/workplace/IngredientDetails'

export default {
  name: 'Recipe',

  components: {
    Modal,
    Draggable,
    IngredientDetails
  },

  mixins: [
    auth,
    recipe,
    supplies,
    machines,
    instruments
  ],

  data () {
    return {
      details: null,
      sorting: false,
      isActive: this.false
    }
  },

  computed: {
    ...mapGetters({
      auth: 'account/isAuth',
    }),

    list: {
      get () {
        return this.recipe.ingredients.map(ing => {
          return {
            id: ing.id,
            name: ing.name,
            symbol: ing.unit.symbol,
            amount: ing.pivot.amount,
            index: ing.pivot.sort_order,
            density: ing.density,
            description: ing.description
          }
        })
      },

      set (list) {
        this.$store.commit('workplace/SET_ORDER', list)
      }
    },

    withinBounds () {
      return (this.recipe.mode === 'diy') ? this.total === 100 : this.total < this.recipe.limit
    },

    recipeStatus () {
      return (this.recipe.mode === 'bootstrapped') ? (this.recipe.limit - this.total).toFixed(1) : this.total.toFixed(2)
    }
  },

  methods: {
    toggle () {
      if (!this.isDesktop) {
        this.isActive = !this.isActive
      }
    },

    toggleSorting () {
      this.sorting = !this.sorting
    },

    isEditable (ingredient) {
      if (this.recipe.mode === 'diy') return true

      if (!this.hasBase) return true

      return !this.isInBase(ingredient.id) && !this.isAdditive(ingredient.id)
    },

    toggleDetails (ingredient) {
      this.details = (this.details) ? null : ingredient
    },

    purge () {
      this.$emit('purge')
      this.toggle()
    },

    getSymbol(key) {
      return key === 'fibersTotal' ? 'gr/1005kJ' : '%'
    }
  }
}
</script>

<style lang="css" scoped>
@media (max-width: 1023px) {
  #recipe {
    position: fixed;
    bottom: 0;
    width: 100vw;
    margin-left: -15px;
    box-shadow: 0 0 10px #222;
    z-index: 10;
  }

  .message-body {
    max-height: 50vh;
  }
}

@media (min-width: 1024px) {
  #recipeWrapper {
    position: fixed;
    left: 60vw;
    top: 10vh;
    overflow: hidden;
  }

  .message-body {
    max-height: 80vh;
  }
}

.message-header p span {
  margin-right: 15px;
}

.message-body {
  border: 0;
  overflow: auto;
}

.table tr:last-child td {
  border-bottom: 0;
}
</style>
