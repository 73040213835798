import axios from 'axios'

const save = (context, params) => {
  if (params.id) {
    axios.put(
      `admin/options/${params.id}`, params
    ).then(
      () => {
        context.commit('UPDATE_OPTION', params)
      }
    ).catch(
      e => {
        console.error(e)
      }
    )
  } else {
    axios.post(
      'admin/options', params
    ).then(
      res => {
        context.commit('lines/ADD_OPTION', res.data, { root: true })
      }
    ).catch(
      e => {
        console.error(e)
      }
    )
  }
}

const remove = (context, id) => {
  axios.delete(
    `admin/options/${id}`
  ).then(
    () => {
      context.commit('lines/REMOVE_OPTION', id, { root: true })
    }
  ).catch(
    e => {
      console.error(e)
    }
  )
}

export default {
  save,
  remove
}
