const SET_GROUPS = (state, data) => {
  state.groups = data
}

const SET_GROUP = (state, data) => {
  state.group = data
}

const PUSH = (state, data) => {
  state.groups.push(data)
}

const UPDATE = (state, data) => {
  const idx = state.groups.findIndex(x => x.id === data.id)

  if (idx !== -1) {
    state.groups[idx] = data
  }
}

const REMOVE = (state, id) => {
  const idx = state.groups.findIndex(x => x.id === id)

  if (idx !== -1) {
    state.groups.splice(idx, 1)
  }
}

export default {
  PUSH,
  UPDATE,
  REMOVE,
  SET_GROUP,
  SET_GROUPS
}
