<template>
  <div class="field is-grouped">
    <div class="control">
      <button
        class="button"
        :class="submitButtonClass"
        :disabled="loading || disabled"
        @click="onSubmit">

        <span class="mr-2">{{ $t('app.buttons.save') }}</span>

        <font-awesome-icon
          class="is-small"
          :class="{ 'fa-spin': submitButtonIcon === 'circle-notch' }"
          :icon="submitButtonIcon"
        />
      </button>
    </div>

    <div
      v-if="!noCancel"
      class="control"
    >
      <button
        class="button"
        @click="onClose"
      >
        {{ $t('app.buttons.cancel') }}
      </button>
    </div>

    <div
      v-if="!noRemove"
      class="control is-pulled-right"
    >
      <button
        class="button is-danger"
        @click="onRemove"
      >
        {{ $t('app.buttons.delete') }}
      </button>
    </div>

    <confirm
      v-if="confirm"
      :text="confirm"
      :is-active="showConfirmation"
      @confirmed="onConfirmed"
      @cancelled="onCancelled"
    ></confirm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Confirm from '@/components/modules/Confirm'

export default {
  name: 'FormButtons',

  components: {
    Confirm
  },

  props: {
    confirm: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    noCancel: {
      type: Boolean,
      default: false
    },

    noRemove: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showConfirmation: false
    }
  },

  computed: {
    ...mapGetters({
      httpStatus: 'http/status'
    }),

    loading () {
      return this.httpStatus === 2
    },

    submitButtonClass () {
      if (this.httpStatus === 0) return 'is-success'
      if (this.httpStatus === 1) return 'is-danger'

      return 'is-info'
    },

    submitButtonIcon () {
      if (this.loading) return 'circle-notch'

      if (this.httpStatus === 0) return 'check'
      if (this.httpStatus === 1) return 'times'

      return 'save'
    }
  },

  methods: {
    toggleConfirmation (state) {
      this.showConfirmation = state
    },

    emit (action) {
      this.$emit(action)
    },

    onRemove () {
      this.confirm ? this.toggleConfirmation(true) : this.$emit('remove')
    },

    onSubmit () {
      this.$emit('submit')
    },

    onClose () {
      this.$emit('close')
    },

    onConfirmed () {
      this.$emit('remove')
    },

    onCancelled () {
      this.toggleConfirmation(false)
    }
  }
}
</script>
