import client from '@/http/client'

class Recipes {
  path = 'recipes'

  async fetch(params = null) {
    return await client.get((params) ? `${this.path}/${params.id ? params.id : JSON.stringify(params.filter)}` : this.path)
  }

  async save(data) {
    return data.id ?
      await client.put(`${this.path}/${data.id}`, data) :
      await client.post(this.path, data)
  }

  async reorder(data) {
    return await client.put(`${this.path}/reorder`, data)
  }

  async destroy(id) {
    return await client.delete(`${this.path}/${id}`)
  }

  async purge(images) {
    return await client.destroy(`recipes/images/remove/${images.join((','))}`, { headers: { 'Silent': 'true' } })
  }

  async upload(payload, path) {
    return await client.post(path, { file: payload }, { headers: { 'Silent': 'true' } })
  }

  async download(id) {
    return await client.get(`recipes/download/${id}`, { responseType: 'blob' })
  }
}

export { Recipes }
