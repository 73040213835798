<template>
  <div id="preloadsWrapper">
    <div class="columns">
      <div class="column is-one-fifth-tablet">
        <router-link
          :to="backLink"
          v-slot="{ href, navigate }"
        >
          <h2
            :href="href"
            class="title is-6"
            @click="navigate"
          >
            <span class="icon">
              <font-awesome-icon icon="chevron-left" />
            </span>

            {{ $t('workplace.back') }}
          </h2>
        </router-link>
      </div>

      <div
        v-if="$route.params.recipeId"
        class="column is-three-fifths-tablet is-half-desktop"
      >
        <formula
          :recipe="recipe"
          @select="choose(recipe)"
        ></formula>
      </div>

      <div
        v-else
        class="column is-three-fifths-tablet is-half-desktop"
      >
        <div class="content">
          {{ $t(`workplace.intro.${group}`) }}
        </div>

        <div class="field">
          <div class="control">
            <input
              class="input"
              type="text"
              v-model="query"
              :placeholder="$t('workplace.ingredients.search')"
            >
          </div>
        </div>

        <div
          class="card"
          v-for="recipe in filteredPreloads"
          :key="recipe.id">

          <div class="card-content">
            <router-link
              :to="getLink(recipe.id)"
              v-slot="{ href, navigate }"
            >
              <div
                class="columns is-mobile"
                :href="href"
                @click="navigate"
              >
                <div class="media-content">
                  <div
                    v-if="hasImages(recipe)"
                    class="column is-one-fifth"
                  >
                    <figure class="image is-48x48">
                      <img :src="recipe.images[0]" alt="Placeholder image">
                    </figure>
                  </div>
                </div>

                <div class="column is-four-fifths">
                  <p class="title is-5">
                    {{ recipe.name }}

                    <span class="icon is-pulled-right has-text-link is-size-5">
                      <font-awesome-icon icon="angle-right" />
                    </span>
                  </p>

                  <p
                    v-if="recipe.description"
                    class="subtitle is-7"
                    v-html="trimmed({id: recipe.id, description: recipe.description})"
                  ></p>
                </div>
              </div>
            </router-link>

            <button
              class="button is-fullwidth is-link"
              @click="choose(recipe)"
            >
              {{ $t(`workplace.${group}.select`) }}
            </button>
          </div>
        </div>

        <promotion-link v-if="group === 'bootstrapped'">
          {{ $t('workplace.bootstrapped.subscribeLink') }} <a href="mailto:info@myboilie.nl?subject=Basismix vermelding myboilie.nl">info@myboilie.nl</a>
        </promotion-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import recipe from '@/mixins/workplace/recipe'
import machines from '@/mixins/workplace/machines'
import Formula from '@/components/workplace/Formula'
import PromotionLink from '@/components/modules/PromotionLink'

export default {
  name: 'Formulas',

  components: {
    Formula,
    PromotionLink
  },

  mixins: [
    recipe,
    machines
  ],

  data () {
    return {
      query: null,
      areExpanded: []
    }
  },

  computed: {
    ...mapGetters({
      recipes: 'recipes/recipes'
    }),

    group () {
      return this.$route.name === 'formulas' ? 'bootstrapped' : this.$route.name
    },

    recipe () {
      if (this.$route.params.recipeId) {
        return this.recipes.find(x => x.id === this.$route.params.recipeId)
      }

      return null
    },

    backLink () {
      return { name: this.$route.params.recipeId ? this.$route.name : 'home' }
    },

    filteredPreloads () {
      if (!this.query || this.query.length === 0) {
        return this.recipes
      }

      return this.recipes.filter(recipe => {
        return recipe.name.toLowerCase().includes(this.query.toLowerCase())
      })
    }
  },

  created () {
    this.$store.dispatch('recipes/loadAll', {
      filter: [(this.group === 'bootstrapped') ? { is_base: 1 } : { is_preload: 1 }]
    })
  },

  methods: {
    choose (recipe) {
      recipe.mode = this.group === 'bootstrapped' ? 'bootstrapped' : 'diy'

      this.select(recipe, true)
    },

    trimmed (params) {
      return (params.description) ? `${params.description.substring(0, 100)}....` : ''
    },

    hasImages (recipe) {
      return recipe.images !== null
    },

    getLink (recipeId) {
      return {
        name: this.$route.name,
        params: {
          recipeId: recipeId
        }
      }
    },

    getImageUrl (path) {
      return `${process.env.VUE_APP_API}/storage/${path}`
    },

    getIngredients (ingredients) {
      return ingredients.map(ingredient => ingredient.name).join(', ')
    }
  }
}
</script>

<style lang="css" scoped>
.card {
  margin-bottom: 30px;
}
</style>
