<template>
  <h2
    class="title is-6"
    :class="{ 'is-hidden-desktop': hideOnDesktop }"
  >
    <router-link
      v-if="to"
      :to="toAttr"
    >
      <font-awesome-icon icon="chevron-left" />

      {{ $t('workplace.back') }}
    </router-link>

    <a
      v-else
      @click="back"
    >
      <font-awesome-icon icon="chevron-left" />

      {{ $t('workplace.back') }}
    </a>
  </h2>
</template>

<script>
export default {
  name: 'BackLink',

  props: {
    to: {
      type: String,
      default: null
    },

    params: {
      type: Object,
      default: null
    },

    hideOnDesktop: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    toAttr () {
      const toAttr = { name: this.to }

      if (this.params) toAttr['params'] = this.params

      return toAttr
    }
  },

  methods: {
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
