import axios from 'axios'
import store from '@/store'

const client = axios.create({
  baseURL: `${process.env.VUE_APP_API}/api`,
  withCredentials: true,
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
})

client.interceptors.request.use((request) => {
  if (!request.headers['Silent'] && ['post', 'put', 'delete'].includes(request.method)) {
    store.commit('http/SET', { status: 2 })
    store.commit('messages/CLEAR_MESSAGES')
  }

  return request
})

client.interceptors.response.use((response) => {
  if (!response.config.headers.Silent && ['post', 'put', 'delete'].includes(response.config.method)) {
    if (response.status >= 200 && response.status <= 300) {
      store.commit('http/SET', { status: 0, response: response.data })
    }
  }

  return response
}, (error) => {
  store.commit('http/SET', { status: 1, response: error.response.data })

  if (error.response.data.errors) {
    store.commit('messages/SET_MESSAGES', error.response.data.errors)
  }

  return Promise.reject(error.message)
})

export default client
