import { Ads } from '@/services/ads'

const loadAll = (context) => {
  (new Ads(true)).fetch().then(
    res => context.commit('SET_GROUPS', res.data)
  ).catch(
    e => console.error(e)
  )
}

const load = (context, id = null) => {
  // TODO: broke this, add if/else for all or one
  (new Ads(true)).fetch(id).then(
    res => context.commit('SET_ADS', res.data)
  ).catch(
    e => console.error(e)
  )
}

const save = (context, params) => {
  (new Ads(true)).save(params).then(
    res => params.id ? context.commit('UPDATE', params) : context.commit('PUSH', res.data)
  ).catch(
    e => console.error(e)
  )
}

const count = (context, data) => {
  (new Ads()).inc(data.id).then(
    () => context.commit('CLICK', data.deeplink)
  ).catch(e => console.error(e))
}

const remove = (context, unitId) => {
  (new Ads(true)).destroy(unitId).then(
    () => context.commit('REMOVE', unitId)
  ).catch(
    e => console.error(e)
  )
}

export default {
  save,
  load,
  count,
  remove,
  loadAll
}
