import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  lines: [],
  units: [],
  groups: [],
  recipe: null,
  isSaved: null,
  isSaving: false,
  additives: [],
  ingredients: [],
  producerIndex: [],
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
