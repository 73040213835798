import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      auth: 'account/isAuth',
      account: 'account/profile'
    }),
    isAdmin () {
      if (this.auth && this.account) {
        return this.account.is_admin === 1
      }

      return false
    },
    isSuspended () {
      if (this.auth) {
        return this.isSuspended === 1
      }

      return false
    },
    isActivated () {
      if (this.auth) {
        return this.email_verified_at !== null
      }

      return false
    }
  }
}
