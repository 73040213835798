<template>
  <div
    id="signinWrapper"
    class="p-3"
  >
    <form
      v-if="displayForgotPassword"
      @submit.prevent="request"
    >
      <div class="field">
        <label>{{ $t('forgotPassword.email.label') }}</label>

        <div class="control">
          <input
            class="input"
            type="email"
            name="email"
            v-model="email"
          >
        </div>

        <p
            v-show="status !== null"
            class="help"
            :class="status === 1 ? 'is-danger' : 'is-success'"
        >
          {{ status === 1 ? $t('forgotPassword.invalidEmail') : message }}
        </p>
      </div>

      <div class="field is-grouped">
        <p class="control">
          <button
            type="submit"
            class="button"
            :disabled="email === ''"
          >
            {{ $t('forgotPassword.submit.label') }}
          </button>
        </p>

        <p class="control">
          <button
            type="reset"
            class="button is-text"
            @click="toggle"
          >
            {{ $t('forgotPassword.password.cancel') }}
          </button>
        </p>
      </div>
    </form>

    <form
      v-else
      @submit.prevent="submit"
    >
      <div class="field">
        <label>{{ $t('signin.email.label') }}</label>

        <div class="control">
          <input
            class="input"
            type="email"
            name="email"
            v-model="email"
          >
        </div>
      </div>

      <div class="field">
        <label>{{ $t('signin.password.label') }}</label>

        <div class="control">
          <input
            class="input"
            type="password"
            name="password"
            v-model="password"
          >
        </div>

        <p
          v-show="status === 1"
          class="help is-danger"
        >
          {{ $t('account.invalidCredentials') }}
        </p>
      </div>

      <div class="field is-grouped">
        <p class="control">
          <button
            type="submit"
            class="button"
            :disabled="!isValid"
          >
            {{ $t('signin.submit.label') }}
          </button>
        </p>

        <p class="control">
          <button
            type="reset"
            class="button is-text"
            @click="toggle"
          >
            {{ $t('signin.password.forgot') }}
          </button>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SignIn',

  data () {
    return {
      email: null,
      password: null,
      displayForgotPassword: false
    }
  },

  computed: {
    ...mapGetters({
      status: 'http/status',
      message: 'http/message'
    }),

    isValid () {
      return this.validPassword && this.validEmail
    },

    validPassword () {
      return this.password &&
        this.password.length > 0
    },

    validEmail () {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

      return this.email &&
        this.email.length > 0 &&
        regex.test(this.email)
    }
  },

  methods: {
    toggle () {
      this.displayForgotPassword = !this.displayForgotPassword
    },

    submit () {
      this.$store.dispatch('account/signIn', {
        email: this.email,
        password: this.password
      })
    },

    request () {
      this.$store.dispatch('account/reset', {
        email: this.email
      })
    }
  }
}
</script>

<style lang="css" scoped>
#registerWrapper {
  width: max-content;
}

label {
  color: #222222;
}
</style>
