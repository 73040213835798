import { Pages } from '@/services/pages'

const loadAll = (context) => {
  (new Pages(true)).fetch().then(
    res => context.commit('SET_PAGES', res.data)
  ).catch(e => console.error(e))
}

const loadById = (context, id) => {
  (new Pages(true)).fetch({ id: id }).then(
    res => context.commit('SET_PAGE', res.data)
  ).catch(e => console.error(e))
}

const loadBySlug = (context, slug) => {
  (new Pages()).fetch({ slug: slug }).then(
    res => context.commit('SET_PAGE', res.data)
  ).catch(e => console.error(e))
}

const save = (context, params) => {
  (new Pages(true)).save(params).then(
    res => {
      if (params.id) {
        context.commit('UPDATE', params)
      } else {
        context.commit('PUSH', res.data)
        context.commit('SET_PAGE', res.data)
      }
    }
  ).catch(
    e => console.error(e)
  )
}

const order = (context, index) => {
  (new Pages(true)).reorder(index).then(
    () => context.commit('ORDER', index)
  ).catch(
    e => console.error(e)
  )
}

const remove = (context, pageId) => {
  (new Pages(true)).destroy(pageId).then(
    () => context.commit('REMOVE')
  ).catch(
    e => console.error(e)
  )
}

export default {
  save,
  order,
  remove,
  loadAll,
  loadById,
  loadBySlug
}
