<template>
  <div id="translationsWrapper">
    <div class="columns">
      <div class="column">
        <h1>
          {{ $t('admin.menu.translations') }}

          <button
            class="button is-pulled-right"
            @click="resync"
          >
            <font-awesome-icon
              icon="sync"></font-awesome-icon>
          </button>
        </h1>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div
            class="field is-horizontal"
            v-for="(value, key, idx) in translation"
            v-show="!key.includes('admin')"
            :key="idx"
        >
          <div class="field-label is-normal">
            <label
              class="label"
              :class="{ 'has-text-danger': !translation[key] || translation[key].length === 0 }"
            >
              {{ key }}
            </label>
          </div>

          <div class="field-body">
            <div class="field">
              <p class="control">
            <textarea
              rows="3"
              class="textarea"
              v-model="translation[key]"
            ></textarea>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label"></div>

          <div class="field-body">
            <form-buttons
              :no-cancel="true"
              :no-remove="true"
              @submit="submit"
            ></form-buttons>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flat from 'flat'
import { mapGetters } from 'vuex'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'AdminTranslations',

  components: {
    FormButtons
  },

  filters: {
    stripped (key) {
      return key.substr(key.indexOf('.') + 1)
    }
  },

  data () {
    return {
      languageId: null,
      languageCode: 'nl',
      translation: null
    }
  },

  computed: {
    ...mapGetters({
      translations: 'translations/translations'
    }),

    // translation () {
    //   if (this.languageId) {
    //     return this.translations.find(x => x.language_id === this.languageId)
    //   }
    //
    //   if (this.translations.length > 0) {
    //     const translations = this.translations[0].translations
    //
    //     const flattened = flat.flatten(translations)
    //
    //     const keys = Object.keys(flattened).map(key => {
    //       const elements = key.split('.')
    //       elements.shift()
    //
    //       return elements.join('.')
    //     })
    //
    //     const values = Object.values(flattened)
    //
    //     return keys.reduce((acc, val, idx) => {
    //       acc[val] = values[idx]
    //       return acc
    //     }, {})
    //   }
    //
    //   return []
    // },

    translationId () {
      if (this.languageId) {
        return this.translations.find(x => x.language_id === this.languageId).id
      }

      return this.translations[0].id
    }
  },

  created () {
    this.$store.dispatch('translations/load')
  },

  watch: {
    translations () {
      if (this.translations.length > 0) {
        const translations = this.translations[0].translations

        const flattened = flat.flatten(translations)

        const keys = Object.keys(flattened).map(key => {
          const elements = key.split('.')
          elements.shift()

          return elements.join('.')
        })

        const values = Object.values(flattened)

        this.translation = keys.reduce((acc, val, idx) => {
          acc[val] = values[idx]
          return acc
        }, {})
      }
    }
  },

  methods: {
    resync () {
      this.$store.dispatch('translations/sync')
    },

    submit () {
      this.$store.dispatch('translations/save', {
        id: this.translationId,
        translations: { nl: flat.unflatten(this.translation) }
      })
    }
  }
}
</script>
