import { Pages } from '@/services/pages'

const load = (context) => {
  (new Pages()).fetch({ is_published: 1 }).then(
    res => context.commit('SET_PAGES', res.data)
  ).catch(
    e => console.error(e)
  )
}

export default {
  load
}
