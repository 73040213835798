import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      ops: {
        '+': (a, b) => { return a + b },
        '-': (a, b) => { return a - b }
      },
      log: true
    }
  },

  computed: {
    ...mapGetters({
      isSaved: 'workplace/isSaved',
    })
  },

  methods: {
    select (recipe, initialize = false) {
      this.$store.commit('workplace/PURGE')

      if (!initialize) {
        if (recipe.mode === 'bootstrapped') {
          recipe.base = this.extractBase(recipe.original_ingredients)
        }

        this.load(recipe)
      } else {
        let copy = this.extract(recipe)

        if (!this.isEditing(recipe)) {
          copy.id = null
          copy.images = []
          copy.recipe_id = recipe.id
          copy.is_preload = copy.is_base = 0
          copy.limit = recipe.limit
          copy.mode = recipe.mode
          copy.name = `${recipe.name} - ${this.$t('workplace.recipe.copy')}`
          copy.original_ingredients = this.extract(recipe.ingredients)

          if (recipe.mode === 'bootstrapped') {
            copy.base = this.extractBase(recipe.ingredients)
            copy.ingredients = copy.ingredients.map(ing => {
              ing.is_base = true
              return ing
            })
          }
        }

        this.load(copy)
      }
    },

    load (recipe) {
      this.$store.commit('workplace/SET_RECIPE', recipe)

      this.$router.push({ name: 'workplace' })
    },

    calculate (params) {
      /**
       * 0. Bypass for ingredients with ml/mg units1
       * 1. Determine new levels for added ingredients
       * 2. Determine new levels for additives
       * 3. Determine remaining levels of basemix
       *
       **/

      let ingredients = this.setIngredientLevels({
        ingredient: params.ingredient,
        ingredients: this.extract(this.recipe.ingredients),
        op: params.operator,
        amount: params.amount
      })

      if (this.isInBootstrapMode && this.isRelative(params.ingredient)) {
        if (!this.isEmpty(ingredients)) {
          ingredients = this.setAdditiveLevels(ingredients)
          ingredients = this.setBaseLevels(ingredients)
        } else {
          ingredients = this.resetBaseLevels()
        }
      }

      if (this.log) {
        console.log('%cIngredients:','font-weight: bold')
        console.log(ingredients)
        console.log(`=> ${ingredients.filter(ing => this.isRelative(ing)).map(ing => ing.pivot.amount).reduce((a, c) => a + c, 0)}`)
      }

      return ingredients
    },

    extract (object) {
      return JSON.parse(JSON.stringify(object))
    },

    dec (ingredient) {
      if (this.isNotNill(ingredient.id)) {
        this.$store.commit('workplace/EDIT_RECIPE', this.calculate({
          operator: '-',
          ingredient: this.extract(ingredient)
        }))
      }
    },

    inc (ingredient) {
      if (this.isNotMax(ingredient.id) && !(this.isLocked(ingredient)) && !(this.maximums && this.isRelativeIngredient(ingredient))) {
        this.$store.commit('workplace/EDIT_RECIPE', this.calculate({
          operator: '+',
          ingredient: this.extract(ingredient)
        }))
      }
    },

    set (ingredient, amount) {
      this.$store.commit('workplace/EDIT_RECIPE', this.calculate({
        amount: amount * this.$factor,
        operator: '=',
        ingredient: this.extract(ingredient)
      }))
    },

    del (ingredient) {
      this.$store.commit('workplace/EDIT_RECIPE', this.calculate({
        operator: 'd',
        ingredient: ingredient
      }))
    },

    purge () {
      this.$store.commit('workplace/PURGE')

      window.location.href = '/'
    },

    extractBase (ingredients) {
      return ingredients.map(ingredient => {
        return {
          id: ingredient.id,
          abs: ingredient.pivot.amount,
          rel: ingredient.pivot.amount / this.$factor / 100
        }
      })
    },

    getInterval (limit) {
      return parseFloat((limit / 100).toFixed(4))
    },

    setIngredientLevels (params) {
      // Find ingredient's position in the recipe
      const idx = this.recipe.ingredients.findIndex(x => x.id === params.ingredient.id)

      // Get maximum available amount and convert
      const amount = params.amount ? params.amount : 0.1 * this.$factor

      // Push ingredient if it's not in the recipe, delete or alter the amount if it is
      if (idx === -1) {
        params.ingredient.pivot = {
          amount: amount,
          sort_order: this.recipe.ingredients.length
        }

        params.ingredients.push(params.ingredient)
      } else {
        if (params.op === 'd' || (params.op === '=') && params.amount === 0) {
          params.ingredients.splice(idx, 1)
        } else {
          if (params.amount) {
            params.ingredients[idx].pivot.amount = params.amount
          } else {
            params.ingredients[idx].pivot.amount = this.ops[params.op](
              params.ingredients[idx].pivot.amount,
              amount
            )
          }

          if (params.ingredients[idx].pivot.amount <= 0) {
            params.ingredients.splice(idx, 1)
          }
        }
      }

      return params.ingredients
    },

    setAdditiveLevels (ingredients) {
      ingredients.filter(ing => this.isAdditive(ing.id)).forEach(ing => {
        const additive = this.additives.find(x => x.id === ing.id)
        const ingInBase = this.getIngredientInBase(ing.id)

        const add = ((this.getIngredientsTotal(ingredients) / this.$factor) * additive.level) * this.$factor

        ing.pivot.amount = ingInBase.abs + add
      })

      return ingredients
    },

    setBaseLevels (ingredients) {
      const remainder = this.getBaseRemainder(ingredients)
      const baseTotal = this.getBaseTotal(ingredients)

      ingredients.forEach(ing => {
        if (this.isInBase(ing.id) && !this.isAdditive(ing.id) && this.isRelative(ing)) {
          const ingInBase = this.getIngredientInBase(ing.id)

          ing.pivot.amount = (ingInBase.rel / baseTotal) * remainder
        }
      })

      return ingredients
    },

    resetBaseLevels () {
      return this.recipe.original_ingredients
    }
  }
}
