<template>
  <div id="adminWorkplaceIngredientWrapper">
    <div class="box">
      <h4 class="subtitle is-4">{{ $t('admin.workplace.basic_data') }}</h4>

      <div class="field">
        <label class="label" for="is_active">
          <input type="checkbox" name="is_active" v-model="is_active"> {{ $t('admin.workplace.active') }}
        </label>
      </div>

      <div class="field">
        <label class="label" for="is_additive">
          <input type="checkbox" name="is_additive" v-model="is_additive"> {{ $t('admin.workplace.additive', 'Is additief') }}
        </label>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('admin.workplace.name') }}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <input type="text" class="input" v-model="name">
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('admin.workplace.group') }}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="group_id">
                  <option
                    v-for="group in groups"
                    :key="group.id"
                    :value="group.id">

                    {{ group.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('admin.workplace.unit') }}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="unit_id">
                  <option
                  v-for="unit in units"
                  :key="unit.id"
                  :value="unit.id">

                    {{ unit.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('admin.workplace.description') }}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <rte
                :content="description"
                @contentChange="handleDescriptionChange">
              </rte>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="columns">
        <div class="column">
          <h4 class="subtitle is-4">{{ $t('admin.workplace.nutritional_value') }}</h4>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.carbs') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="carbs">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.fat') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="fat">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.protein') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="protein">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.fibers') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="fibers">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.other') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="other">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <h4 class="subtitle is-4">{{ $t('admin.workplace.nutrients') }}</h4>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.vitamineA') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="vitamineA">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.vitamineB1') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="vitamineB1">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.vitamineB2') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="vitamineB2">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.vitamineB6') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="vitamineB6">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.neb') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="neb">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column">
          <h4 class="subtitle is-4">{{ $t('admin.workplace.properties') }}</h4>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.maximum') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="limit">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.increment') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input type="number" class="input" v-model="increment">
                </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">{{ $t('admin.workplace.buoyancy') }}</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <div class="select">
                    <select v-model="density">
                      <option value="-1">{{ $t('admin.workplace.sinker') }}</option>
                      <option value="0">{{ $t('admin.workplace.neutral') }}</option>
                      <option value="1">{{ $t('admin.workplace.floater') }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form-buttons
      @submit="submit"
      @close="close"
      @remove="remove">
    </form-buttons>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Rte from '@/components/modules/Rte'
import rteMixin from '@/mixins/rte'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'AdminIngredient',

  mixins: [
    rteMixin
  ],

  components: {
    Rte,
    FormButtons
  },

  data () {
    return {
      is_active: 0,
      name: null,
      group_id: null,
      unit_id: null,
      description: null,
      carbs: null,
      fat: null,
      protein: null,
      fibers: null,
      other: null,
      limit: null,
      density: null,
      is_additive: 0,
      increment: 0.01,
      vitamineA: null,
      vitamineB1: null,
      vitamineB2: null,
      vitamineB6: null,
      neb: null
    }
  },

  computed: {
    ...mapGetters({
      units: 'workplace/units',
      groups: 'groups/groups',
      ingredient: 'ingredients/ingredient'
    })
  },

  created () {
    if (this.$attrs.id) this.$store.dispatch('ingredients/load', this.$attrs.id)
  },

  beforeDestroy () {
    this.$store.commit('ingredients/SET_INGREDIENT', null)
  },

  watch: {
    ingredient () {
      if (this.ingredient) {
        this.is_active = this.ingredient.is_active
        this.name = this.ingredient.name
        this.unit_id = this.ingredient.unit_id
        this.group_id = this.ingredient.group_id
        this.description = this.ingredient.description
        this.carbs = this.ingredient.carbs
        this.fat = this.ingredient.fat
        this.protein = this.ingredient.protein
        this.fibers = this.ingredient.fibers
        this.other = this.ingredient.other
        this.limit = this.ingredient.limit
        this.density = this.ingredient.density
        this.is_additive = this.ingredient.is_additive
        this.increment = this.ingredient.increment
        this.vitamineA = this.ingredient.vitamine_a
        this.vitamineB1 = this.ingredient.vitamine_b1
        this.vitamineB2 = this.ingredient.vitamine_b2
        this.vitamineB6 = this.ingredient.vitamine_b6
        this.neb = this.ingredient.neb
      }
    }
  },

  methods: {
    submit () {
      this.$store.dispatch('ingredients/save', {
        id: (this.ingredient) ? this.ingredient.id : null,
        is_active: this.is_active,
        name: this.name,
        unit_id: this.unit_id,
        group_id: this.group_id,
        description: this.description,
        carbs: this.carbs,
        fat: this.fat,
        protein: this.protein,
        fibers: this.fibers,
        other: this.other,
        limit: this.limit,
        density: parseInt(this.density),
        is_additive: this.is_additive,
        increment: this.increment,
        unit: this.units.find(x => x.id === this.unit_id),
        group: this.groups.find(x => x.id === this.group_id),
        vitamine_a: this.vitamineA,
        vitamine_b1: this.vitamineB1,
        vitamine_b2: this.vitamineB2,
        vitamine_b6: this.vitamineB6,
        neb: this.neb
      })
    },
    close () {
      this.$router.push({ name: 'admin.workplace' })
    },
    remove () {
      this.$store.dispatch('ingredients/remove', this.ingredient.id)
      this.close()
    }
  }
}
</script>
