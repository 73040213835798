<template>
  <vue-editor
    v-model="editorContent"
    useCustomImageHandler
    :custom-modules="customModules"
    :editor-toolbar="editorToolbar"
    :editor-options="editorSettings"
    @image-added="handleImageAdded"
  ></vue-editor>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import htmlEditButton from 'quill-html-edit-button'

export default {
  props: [
    'content'
  ],

  components: {
    VueEditor
  },

  data () {
    return {
      editorContent: '',

      editorToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean']
      ],

      editorSettings: {
        modules: {
          htmlEditButton: {
            syntax: false,
          }
        }
      },

      customModules: [
        {
          alias: 'htmlEditButton',
          module: htmlEditButton
        }
      ]
    }
  },

  watch: {
    editorContent () {
      this.$emit('contentChange', this.editorContent)
    },

    content () {
      this.setContent()
    }
  },

  created () {
    this.setContent()
  },

  methods: {
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      this.$store.dispatch('posts/upload', {
        file: file,
        editor: Editor,
        cursorLocation: cursorLocation,
        resetUploader: resetUploader
      })
    },

    setContent () {
      if (typeof this.content !== 'undefined') {
        this.editorContent = JSON.parse(JSON.stringify(this.content))
      }
    }
  }
}
</script>
