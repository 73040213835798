<template>
  <router-link
    class="button is-link feedback-link"
    :to="{ name: 'contact' }"
  >
    Feedback
  </router-link>
</template>

<script>
export default {
  name: 'FeedbackButton'
}
</script>

<style scoped>
.feedback-link {
  position: fixed;
  right: -35px;
  top: 50%;
  transform: rotate(270deg);
}
</style>
