<template>
  <div id="app">
    <nav-bar />

    <div id="wrapper">
      <welcome-notice />
      <!-- <breadcrumb /> -->
      <router-view />
    </div>

    <cookie-notice />

    <toast v-if="messages.length > 0" />

    <bottom-bar />

    <feedback-button />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Toast from '@/components/modules/Toast'
import NavBar from '@/components/nav/NavBar'
import CookieNotice from '@/components/modules/CookieNotice'
import WelcomeNotice from '@/components/modules/WelcomeNotice'
import BottomBar from '@/components/modules/BottomBar'
import FeedbackButton from '@/components/modules/FeedbackButton'
// import Breadcrumb from '@/components/nav/Breadcrumb'

export default {
  name: 'App',

  components: {
    FeedbackButton,
    Toast,
    NavBar,
    BottomBar,
    CookieNotice,
    WelcomeNotice
    // Breadcrumb
  },

  created () {
    if (this.$route.name !== 'resetPassword') {
      this.$store.dispatch('account/load')
    }
  },

  computed: {
    ...mapGetters({
      messages: 'messages/messages'
    })
  }
}
</script>

<style>
#wrapper {
  padding: 80px 15px 15px;
}

.vue-slider {
  z-index: 0;
}
</style>
