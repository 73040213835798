const SET_SUPPLIERS = (state, data) => {
  state.suppliers = data
}

const SET_SUPPLIER = (state, data) => {
  state.supplier = data
}

const PUSH = (state, data) => {
  state.suppliers.push(data)
}

const UPDATE = (state, data) => {
  const idx = state.suppliers.findIndex(x => x.id === data.id)

  if (idx !== -1) {
    state.suppliers[idx] = data
  }
}

const REMOVE = (state, id) => {
  state.suppliers = state.suppliers.filter(p => p.id !== id)
}

export default {
  PUSH,
  UPDATE,
  REMOVE,
  SET_SUPPLIER,
  SET_SUPPLIERS
}
