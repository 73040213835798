const profile = state => state.profile;
const isAuth = state => state.profile !== null;
const isAdmin = state => state.profile?.is_admin === 1;
const registrationSuccessful = state => state.registrationSuccessful;

const representsProducer = state => !state.profile?.isAdmin && !!state.profile?.represents;

export default {
  isAuth,
  isAdmin,
  profile,
  registrationSuccessful,
  representsProducer,
}
