const SET_PAGE = (state, data) => {
  state.page = data
}

const SET_PAGES = (state, data) => {
  state.pages = data
}

const SET_SUBNAV = (state, data) => {
  state.subnav.push(data)
}

const PUSH = (state, data) => {
  state.pages.push(data)
}

const RESET = (state) => {
  state.page = null
}

const ORDER = (state, data) => {
  const pages = []

  data.index.forEach(page => {
    pages.push(state.pages.find(x => x.id === page.id))
  })

  state.pages = pages
}

const UPDATE = (state, data) => {
  state.page.title = data.title
  state.page.content = data.content
}

const REMOVE = (state, pageId) => {
  const idx = state.pages.findIndex(x => x.id === pageId)

  if (idx !== -1) {
    state.pages.splice(idx, 1)
  }
}

export default {
  PUSH,
  RESET,
  ORDER,
  UPDATE,
  REMOVE,
  SET_PAGE,
  SET_PAGES,
  SET_SUBNAV
}
