<template>
  <div id="adminUnitWrapper">
    <div class="box">
      <div class="field">
        <label class="label">{{ $t('admin.workplace.name') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="name">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.workplace.abbreviation') }}</label>

        <div class="control">
          <textarea class="textarea" v-model="abbreviation"></textarea>
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.workplace.symbol') }}</label>

        <div class="control">
          <textarea class="textarea" v-model="symbol"></textarea>
        </div>
      </div>
    </div>

    <form-buttons
      @submit="submit"
      @close="close"
      @remove="remove">
  </form-buttons>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'AdminUnit',
  components: {
    FormButtons
  },
  data () {
    return {
      id: null,
      name: null,
      symbol: null,
      abbreviation: null
    }
  },
  computed: {
    ...mapGetters({
      unit: 'units/unit'
    })
  },
  created () {
    this.$store.dispatch('units/load', this.$attrs.id)
  },
  watch: {
    unit () {
      if (this.unit) {
        this.id = this.unit.id
        this.name = this.unit.name
        this.symbol = this.unit.symbol
        this.abbreviation = this.unit.abbreviation
      }
    }
  },
  methods: {
    submit () {
      this.$store.dispatch('units/save', {
        id: (this.id) ? this.id : null,
        name: this.name,
        symbol: this.symbol,
        abbreviation: this.abbreviation
      })
    },
    close () {
      this.id = this.name = this.symbol = this.abbreviation = null
      this.goToIndex()
    },
    remove () {
      this.$store.dispatch('units/remove', this.id)
      this.goToIndex()
    },
    goToIndex () {
      this.$router.push({ name: 'admin.workplace.units' })
    }
  }
}
</script>

<style lang="css">
</style>
