<template>
  <div
    id="registerWrapper"
    class="p-3"
  >
    <div
      v-if="success"
      class="notification is-success"
    >
      {{ $t('register.success') }}
    </div>

    <form
      v-else
      @submit.prevent="submit"
    >
      <div class="field">
        <label>{{ $t('register.name.label') }}</label>

        <div class="control">
          <input
            class="input"
            type="text"
            name="name"
            v-model="name"
          >
        </div>
      </div>

      <div class="field">
        <label>{{ $t('register.email.label') }}</label>

        <div class="control">
          <input
            class="input"
            type="email"
            name="email"
            v-model="email"
          >
        </div>

        <p
          v-show="status === 1"
          class="help is-danger"
        >
          Het e-mail adres is ongeldig.
        </p>
      </div>

      <div class="field">
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="optIn"
          >
          {{ $t('register.opt_in') }}
        </label>
      </div>

      <div class="field">
        <label class="checkbox">
          <input
            type="checkbox"
            v-model="optInThirdParty"
          >
          {{ $t('register.opt_in_third_party') }}
        </label>
      </div>

      <div class="field">
        <button
          class="button"
          :disabled="!isValid"
        >
          {{ $t('register.submit.label') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Register',

  data () {
    return {
      name: null,
      email: null,
      optIn: false,
      optInThirdParty: false
    }
  },

  computed: {
    ...mapGetters({
      status: 'http/status',
      success: 'account/registrationSuccessful'
    }),

    isValid () {
      return this.validName && this.validEmail
    },

    validName () {
      return this.name &&
        this.name.length > 0
    },

    validEmail () {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

      return this.email &&
        this.email.length > 0 &&
        regex.test(this.email)
    }
  },

  methods: {
    submit () {
      this.$store.dispatch('account/register', {
        name: this.name,
        email: this.email,
        optIn: this.optIn,
        optInThirdParty: this.optInThirdParty
      })
    }
  }
}
</script>

<style lang="css" scoped>
#registerWrapper {
  width: max-content;
}

label {
  color: #222222;
}
</style>
