<template>
  <div id="producersWrapper">
    <div class="columns">
      <div class="column is-three-fifths-tablet is-half-desktop is-offset-one-fifth-tablet is-offset-one-quarter-desktop">
        <h1 class="title">{{ $t('producers.title') }}</h1>

        <div class="content">
          {{ $t('producers.intro') }}
        </div>

        <div
          v-for="(producer, idx) in producers"
          :key="producer.id"
        >
          <producer :producer="producer" />

          <promotion-link v-if="idx === 0">
            {{ $t('producers.subscribeLink') }} <a href="mailto:info@myboilie.nl?subject=Vermelding als draaier op myboilie.nl">info@myboilie.nl</a>.
          </promotion-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Producer from './Producer'
import PromotionLink from '@/components/modules/PromotionLink'

export default {
  name: 'Producers',

  components: {
    Producer,
    PromotionLink
  },

  computed: {
    ...mapGetters({
      producers: 'producers/producers'
    })
  },

  created () {
    this.$store.dispatch('producers/loadAll')
  }
}
</script>
