<template>
  <div id="adminrecipeWrapper">
    <div
      class="box"
      v-if="recipe">

      <div class="field">
        <label class="label">
          <input type="checkbox" v-model="is_base"> {{ $t('admin.workplace.basemix') }}
        </label>
      </div>

      <div class="field" v-show="is_base">
        <label class="label">{{ $t('admin.workplace.limit') }}</label>

        <div class="control has-icons-right">
          <input class="input" type="text" v-model="limit">

          <span class="icon is-right">
            <font-awesome-icon icon="percentage" />
          </span>
        </div>
      </div>

      <div class="field">
        <label class="label">
          <input type="checkbox" v-model="is_preload"> {{ $t('admin.workplace.preload') }}
        </label>
      </div>

<!--      <div class="field">-->
<!--        <label class="label">-->
<!--          <input type="checkbox" v-model="is_profiler"> {{ $t('admin.workplace.profiler') }}-->
<!--        </label>-->
<!--      </div>-->

      <div class="field">
        <label class="label">{{ $t('admin.workplace.producer') }}</label>
        <div class="select">
          <select v-model="producerId">
            <option :value="null">Geen</option>
            <option
              v-for="producer in producers"
              :key="producer.id"
              :value="producer.id"
            >
              {{ producer.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <figure
            class="image is-64x64 is-pulled-left is-clipped"
            v-for="(image, idx) in recipe.images"
            :key="idx">

            <img :src="image">

            <button
              class="delete"
              @click="setRemove(idx)"></button>
          </figure>

          <uploader
            v-show="recipe.images === null || recipe.images.length < 3"
            :endpoint="`recipes/${this.id}/images/upload`"
            @uploader:ready="addImage">
          </uploader>
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.workplace.name') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="name">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.workplace.description') }}</label>

        <div class="control">
          <rte
            :content="description"
            @contentChange="handleDescriptionChange">
          </rte>
        </div>
      </div>

      <ul>
        <li
          v-for="ingredient in recipe.ingredients"
          :key="ingredient.id">

          {{ ingredient.name }} {{ ingredient.pivot.amount / $factor }}{{ ingredient.unit.symbol }}
        </li>
      </ul>
    </div>

    <div class="field">
      <button
        class="button"
        @click="select(recipe, false)">

        {{ $t('admin.workplace.open_in_workplace') }}
      </button>
    </div>

    <form-buttons
      confirm="Weet je zeker dat je dit recept wilt verwijderen?"
      @submit="submit"
      @close="close"
      @remove="remove"
    ></form-buttons>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import rteMixin from '@/mixins/rte'
import imagesMixin from '@/mixins/images'
import Rte from '@/components/modules/Rte'
import machines from '@/mixins/workplace/machines'
import Uploader from '@/components/modules/Uploader'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'AdminFormula',
  components: {
    Rte,
    Uploader,
    FormButtons
  },
  mixins: [
    machines,
    rteMixin,
    imagesMixin
  ],
  data () {
    return {
      id: null,
      name: null,
      limit: null,
      images: null,
      is_base: null,
      is_preload: null,
      is_profiler: null,
      producerId: null,
      description: null
    }
  },
  computed: {
    ...mapGetters({
      recipe: 'recipes/recipe',
      producers: 'producers/producers'
    })
  },
  created () {
    this.$store.dispatch('recipes/load', this.$attrs.id)
    this.$store.dispatch('producers/loadAll')
  },
  watch: {
    recipe () {
      if (this.recipe) {
        this.id = this.recipe.id
        this.name = this.recipe.name
        this.limit = this.recipe.limit
        this.images = this.recipe.images
        this.is_base = this.recipe.is_base
        this.is_preload = this.recipe.is_preload
        this.is_profiler = this.recipe.is_profiler
        this.description = this.recipe.description
        this.producerId = this.recipe.producer_id
      }
    }
  },
  methods: {
    submit () {
      this.$store.dispatch('recipes/save', {
        id: (this.id) ? this.id : null,
        name: this.name,
        limit: this.limit,
        images: this.images,
        is_base: this.is_base,
        is_preload: this.is_preload,
        is_profiler: this.is_profiler,
        description: this.description,
        ingredients: this.recipe.ingredients,
        removables: this.removables,
        producer_id: this.producerId
      })
    },
    close () {
      this.id = this.is_base = this.is_preload = this.name = this.description = null
      this.goToIndex()
    },
    remove () {
      this.$store.dispatch('recipes/remove', this.id)
      this.goToIndex()
    },
    goToIndex () {
      this.$router.push({ name: 'admin.workplace.formulas' })
    }
  }
}
</script>

<style scoped>
  figure {
    margin-right: 15px;
  }

  .delete {
    position: absolute;
    right: 2px;
    top: 2px;
  }
</style>
