<template>
  <div class="box">
    <div class="field">
      <label class="label">{{ $t('admin.workplace.profiler.option.body') }}</label>

      <div class="control">
        <input
          class="input"
          v-model="body">
      </div>
    </div>

    <div class="field">
      <label class="label">{{ $t('admin.workplace.profiler.option.follow_up') }}</label>

      <div class="select">
        <select
          v-model="follow_up_id">
          <option value=""></option>

          <option
            v-for="line in lines"
            :key="line.id"
            :value="line.id">

            {{ line.header }}{{ seperator(line) }}{{ line.body }}
          </option>
        </select>
      </div>
    </div>

    <hr>

    <div class="field">
      <label class="label">{{ $t('admin.workplace.profiler.option.recipe') }}</label>

      <div class="select">
        <select
          v-model="recipe_id">
          <option value=""></option>

          <option
            v-for="recipe in recipes"
            :key="recipe.id"
            :value="recipe.id">

            {{ recipe.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="field">
      <label class="label">{{ $t('admin.workplace.profiler.option.amount') }}</label>

      <div class="control">
        <input
          type="number"
          class="input"
          v-model="amount">
      </div>
    </div>

    <div class="field">
      <div class="control">
        <form-buttons
          :disabled="isInvalid"
          @submit="submit"
          @close="close"
          @remove="remove">
        </form-buttons>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormButtons from '../modules/FormButtons'

export default {
  name: 'AdminProfilerOption',
  props: {
    line_id: {
      type: Number,
      required: true
    }
  },
  components: {
    FormButtons
  },
  data () {
    return {
      actions: [
        {
          name: 'diy',
          caption: this.$t('admin.workplace.profiler.option.action.diy')
        },
        {
          name: 'save',
          caption: this.$t('admin.workplace.profiler.option.action.save')
        }
      ],
      body: null,
      action: null,
      amount: 0,
      recipe_id: null,
      follow_up_id: null
    }
  },
  computed: {
    ...mapGetters({
      lines: 'lines/lines',
      option: 'options/option',
      recipes: 'recipes/recipes'
    }),
    isInvalid () {
      return !this.body || this.body.length === 0
    }
  },
  created () {
    this.$store.dispatch('lines/loadAll')
    this.$store.dispatch('recipes/loadAll', {
      filter: [{ is_profiler: 1 }]
    })
  },
  watch: {
    option () {
      if (this.option) {
        this.body = this.option.body
        this.action = this.option.action
        this.amount = this.option.amount / this.$factor
        this.recipe_id = this.option.recipe_id
        this.follow_up_id = this.option.follow_up_id
      } else {
        this.body = this.action = this.recipe_id = this.follow_up_id = null
        this.amount = 0
      }
    }
  },
  methods: {
    submit () {
      this.$store.dispatch('options/save', {
        id: (this.option) ? this.option.id : null,
        line_id: this.line_id,
        body: this.body,
        follow_up_id: this.follow_up_id,
        recipe_id: this.recipe_id,
        amount: (this.amount === 0) ? null : this.amount * this.$factor
      })

      this.close()
    },
    close () {
      this.$emit('close')
    },
    remove () {
      this.$store.dispatch('options/remove', this.option.id)
      this.close()
    },
    seperator (line) {
      return (line.header && line.body) ? ' | ' : ''
    }
  }
}
</script>

<style scoped>

</style>
