export default {
  data () {
    return {
      removables: []
    }
  },
  methods: {
    addImage (payload) {
      this.images.push(payload)
    },
    setRemove (idx) {
      try {
        const filename = /[^/]*$/.exec(this.images[idx])[0]
        this.removables.push(filename)
        this.images.splice(idx, 1)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
