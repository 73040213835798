import { Lines } from '@/services/lines'

const load = (context, lineId) => {
  (new Lines()).fetch(lineId).then(
    res => context.commit('SET_LINE', res.data)
  ).catch(
    e => console.error(e)
  )
}

const loadAll = (context) => {
  (new Lines()).fetch().then(
    res => context.commit('SET_LINES', res.data)
  ).catch(
    e => console.error(e)
  )
}

const next = (context, params) => {
  (new Lines()).fetch(null, `/lines/from/${params.id}`).then(
    res => context.commit((params.push) ? 'PUSH_LINES' : 'SET_LINES', res.data)
  ).catch(
    e => console.error(e)
  )
}

const reply = (context, option) => {
  context.commit('PUSH_REPLY', {
    header: '',
    body: option.body,
    options: [],
    me: true
  })

  context.dispatch('next', { id: option.follow_up_id, push: true })
}

const save = (context, params) => {
  (new Lines()).save(params).then(
    res => params.id ? context.commit('SET_LINE', params) : context.commit('SET_LINE', res.data)
  ).catch(
    e => console.error(e)
  )
}

const remove = (context, id) => {
  (new Lines()).destroy(id).then(
    () => context.commit('REMOVE', id)
  ).catch(
    e => console.error(e)
  )
}

export default {
  load,
  next,
  save,
  reply,
  remove,
  loadAll
}
