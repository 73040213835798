import client from '@/http/client'

class Translations {
  path = 'admin/translations'

  async fetch(params = null) {
    return await client.get((params && params.filter) ? `${this.path}/${params.filter}` : this.path)
  }

  async save(data) {
    return await client.put(`${this.path}/${data.id}`, data)
  }

  async sync() {
    return await client.post(this.path)
  }

  async destroy(id) {
    return await client.delete(`${this.path}/${id}`)
  }
}

export { Translations }
