<template>
  <h1>{{ $t('admin.dashboard.header') }}</h1>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
