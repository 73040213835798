import Vue from 'vue'
import Router from 'vue-router'
import Blog from '@/views/blog'
import Index from '@/views/Index'
import Page from '@/views/pages/Page'
import Account from '@/views/account'
import Contact from '@/views/contact'
import Producers from '@/views/producers'
import Suppliers from '@/views/suppliers'
import SignIn from '@/components/auth/SignIn'
import Cancel from '@/components/profile/Cancel'
import Register from '@/components/auth/Register'
import Formulas from '@/views/workplace/Formulas'
// import Profiler from '@/views/workplace/Profiler'
import Profile from '@/components/profile/Profile'
import Recipes from '@/components/profile/Recipes'
import PostList from '@/components/posts/PostList'
import Editor from '@/components/workplace/Editor'
import Workplace from '@/views/workplace/Workplace'
import EditRecipe from '@/components/workplace/EditRecipe'
import ResetPassword from '@/components/profile/ResetPassword'

import Ingredients from '@/components/workplace/Ingredients'
import PostDetail from '@/components/posts/PostDetail'
import Admin from '@/views/admin'
import AdminPosts from '@/components/posts/AdminPosts'
import AdminPages from '@/components/pages/AdminPages'
import AdminUnit from '@/components/workplace/AdminUnit'
import AdminGroup from '@/components/workplace/AdminGroup'
import AdminPageEdit from '@/components/pages/AdminPageEdit'
import AdminPostEdit from '@/components/posts/AdminPostEdit'
import AdminFormula from '@/components/workplace/AdminFormula'
import AdminDashboard from '@/components/admin/AdminDashboard'
import AdminProducer from '@/components/workplace/AdminProducer'
import AdminSupplier from '@/components/workplace/AdminSupplier'
import AdminWorkplace from '@/components/workplace/AdminWorkplace'
import AdminProducers from '@/components/workplace/AdminProducers'
import AdminSuppliers from '@/components/workplace/AdminSuppliers'
import AdminIngredient from '@/components/workplace/AdminIngredient'
import AdminProfilerLine from '@/components/workplace/AdminProfilerLine'
import AdminTranslations from '@/components/translations/AdminTranslations'
import AdminWorkplaceUnits from '@/components/workplace/AdminWorkplaceUnits'
import AdminWorkplaceGroups from '@/components/workplace/AdminWorkplaceGroups'
import AdminWorkplaceFormulas from '@/components/workplace/AdminWorkplaceFormulas'
import AdminWorkplaceProfiler from '@/components/workplace/AdminWorkplaceProfiler'
import AdminWorkplaceIngredients from '@/components/workplace/AdminWorkplaceIngredients'

Vue.use(Router)

export default new Router({
  mode: 'history',

  base: process.env.BASE_URL,

  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },

  routes: [
    {
      path: '/',
      name: 'home',
      component: Index
    },

    {
      path: '/werkplaats',
      name: '',
      component: Workplace,
      props: true,
      children: [
        {
          path: 'groepen',
          name: 'workplace',
          component: Editor
        },

        {
          path: 'groepen/:slug',
          name: 'workplace.ingredients',
          component: Ingredients,
          props: true
        }
      ]
    },

    {
      path: '/preloads/:recipeId?',
      name: 'preloads',
      component: Formulas,
    },

    {
      path: '/basismixen/:recipeId?',
      name: 'formulas',
      component: Formulas
    },

    {
      path: '/studiecentrum',
      name: '',
      component: Blog,
      children: [
        {
          path: '',
          name: 'posts',
          component: PostList
        },

        {
          path: 'artikel/:id',
          name: 'post',
          component: PostDetail,
          props: true
        },

        {
          path: 'serie/:tag',
          name: 'posts.series',
          component: PostList,
          props: true
        },

        {
          path: 'serie/:tag/artikel/:id',
          name: 'posts.series.post',
          component: PostDetail,
          props: true
        }
      ]
    },

    {
      path: '/draaiers',
      name: 'producers',
      component: Producers,
      meta: {
        navbar: 'start',
        caption: 'Draaiers'
      },
    },

    {
      path: '/grondstoffen-leveranciers',
      name: 'suppliers',
      component: Suppliers,
      meta: {
        navbar: 'start',
        caption: 'Grondstoffen leveranciers'
      },
    },

    {
      path: '/over-myboilie/:page',
      name: 'about',
      component: Page,
      props: true
    },

    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },

    {
      path: '/overige/:page',
      name: 'other',
      component: Page,
      props: true
    },

    {
      path: '/inloggen',
      name: 'signin',
      component: SignIn,
    },

    {
      path: '/registreren',
      name: 'register',
      component: Register,
      meta: {
        navbar: 'end',
        caption: 'Registreren',
        action: 'register'
      }
    },

    {
      path: '/admin',
      name: '',
      component: Admin,
      children: [
        {
          path: '',
          name: 'admin',
          component: AdminDashboard
        },

        {
          path: 'posts',
          name: 'admin.posts',
          component: AdminPosts
        },

        {
          path: 'posts/edit/:id?',
          name: 'admin.posts.edit',
          component: AdminPostEdit,
          props: true
        },

        {
          path: 'pages',
          name: 'admin.pages',
          component: AdminPages
        },

        {
          path: 'pages/edit/:id?',
          name: 'admin.pages.edit',
          component: AdminPageEdit,
          props: true
        },

        {
          path: 'translations/:language?',
          name: 'admin.translations',
          component: AdminTranslations,
          props: true
        },

        {
          path: 'workplace',
          name: '',
          component: AdminWorkplace,
          props: true,
          children: [
            {
              path: 'ingredients',
              name: 'admin.workplace',
              component: AdminWorkplaceIngredients,
              props: true,
              children: [
                {
                  path: ':id',
                  name: 'admin.workplace.ingredients.edit',
                  component: AdminIngredient,
                  props: true
                }
              ]
            },

            {
              path: 'groups',
              name: 'admin.workplace.groups',
              component: AdminWorkplaceGroups,
              props: true,
              children: [
                {
                  path: ':id',
                  name: 'admin.workplace.groups.edit',
                  component: AdminGroup,
                  props: true
                }
              ]
            },

            {
              path: 'formulas',
              name: 'admin.workplace.formulas',
              component: AdminWorkplaceFormulas,
              props: true,
              children: [
                {
                  path: ':id',
                  name: 'admin.workplace.formulas.edit',
                  component: AdminFormula,
                  props: true
                }
              ]
            },

            {
              path: 'units',
              name: 'admin.workplace.units',
              component: AdminWorkplaceUnits,
              props: true,
              children: [
                {
                  path: ':id',
                  name: 'admin.workplace.units.edit',
                  component: AdminUnit,
                  props: true
                }
              ]
            },

            {
              path: 'profiler',
              name: 'admin.workplace.profiler',
              component: AdminWorkplaceProfiler,
              props: true
            },

            {
              path: 'profiler/:id',
              name: 'admin.workplace.profiler.line.edit',
              component: AdminProfilerLine,
              props: true
            }
          ]
        },

        {
          path: 'producers',
          name: 'admin.producers',
          component: AdminProducers,
          props: true,
          children: [
            {
              path: ':id',
              name: 'admin.producers.edit',
              component: AdminProducer,
              props: true
            }
          ]
        },

        {
          path: 'suppliers',
          name: 'admin.suppliers',
          component: AdminSuppliers,
          props: true,
          children: [
            {
              path: ':id',
              name: 'admin.suppliers.edit',
              component: AdminSupplier,
              props: true
            }
          ]
        }
      ]
    },

    {
      path: '/account',
      name: '',
      component: Account,
      children: [
        {
          path: 'profile',
          name: 'account.profile',
          component: Profile,
        },

        {
          path: 'recipes',
          name: 'account.recipes',
          component: Recipes,
        },

        {
          path: 'recipes/:id',
          name: 'account.recipes.edit',
          component: EditRecipe,
          props: true
        },

        {
          path: 'cancel',
          name: 'account.cancel',
          component: Cancel,
        }
      ]
    },

    {
      path: '/reset-password/:expiration/:token',
      name: 'resetPassword',
      component: ResetPassword,
      props: true,
    },
  ]
})
