import client from '@/http/client'

class Tags {
  path = 'tags'

  async fetch(params = null) {
    return await client.get((params.filter) ? `${this.path}/${params.filter}` : this.path)
  }

  async save(data) {
    return data.id ?
      await client.put(`${this.path}/${data.id}`, data) :
      await client.post(this.path, data)
  }

  async destroy(id) {
    return await client.delete(`${this.path}/${id}`)
  }
}

export { Tags }
