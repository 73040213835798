<template>
  <div class="notification is-danger m-3">
    <button
      class="delete"
      @click="clear"
    ></button>

    {{ messages[0] }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Toast',

  computed: {
    ...mapGetters({
      messages: 'messages/messages'
    })
  },

  beforeDestroy() {
    this.$store.commit('messages/CLEAR_MESSAGES')
  },

  methods: {
    clear () {
      this.$store.commit('messages/CLEAR_MESSAGE')
    }
  }
}
</script>

<style scoped>
.notification {
  position: absolute;
  bottom: 0;
  z-index: 1000;
}
</style>
