<template>
  <div id="lineWrapper">
    <div
      class="card"
      :class="{ 'is-disabled': !line.is_enabled }">

      <header class="card-header">
        <p class="card-header-title">
          {{ line.header }}
        </p>

        <router-link :to="{ name: 'admin.workplace.profiler.line.edit', params: { id: line.id, block: lines[0].id } }">
          <span class="icon">
            <font-awesome-icon icon="pencil-alt" />
          </span>
        </router-link>
      </header>

      <div class="card-content">
        <div class="content">
          {{ line.body }}
        </div>
      </div>

      <footer
        v-show="hasOptions"
        class="card-footer">

        <a
          v-for="option in line.options"
          :key="option.id"
          @click="$emit('load', option.follow_up_id)"
          class="card-footer-item">

          {{ option.body }}
        </a>
      </footer>
    </div>

    <router-link
      v-show="!hasOptions"
      :to="{ name: 'admin.workplace.profiler.line.edit', params: { id: null, previous_id: line.id, next_id:
      line.next, block: lines[0].id } }"
      tag="button"
      class="button">

      <span class="icon">
        <font-awesome-icon icon="plus" />
      </span>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BotLine',
  props: {
    line: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      lines: 'lines/lines'
    }),
    hasHeader () {
      return this.line.header && this.line.header.length > 0
    },
    hasOptions () {
      return this.line.options && this.line.options.length > 0
    }
  },
  methods: {
    add () {

    }
  }
}
</script>

<style type="text/css" scoped>
  .card {
    width: 300px;
    margin: 15px 0;
  }

  .is-disabled {
    opacity: 0.4;
  }
</style>
