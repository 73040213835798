import { Recipes } from '@/services/recipes'

const loadAll = (context, params) => {
  (new Recipes()).fetch(params).then(
    res => context.commit('SET_RECIPES', res.data)
  ).catch(
    e => console.error(e)
  )
}

const load = (context, recipeId) => {
  (new Recipes()).fetch({ id: recipeId }).then(
    res => context.commit('SET_RECIPE', res.data)
  ).catch(
    e => console.error(e)
  )
}

const save = (context, params) => {
  (new Recipes()).save(params).then(
    res => {
      if (params.id) {
        context.commit('UPDATE', params)
        // context.dispatch('purgeRemovables', params.removables)
      } else {
        context.commit('PUSH', res.data)
        // context.dispatch('purgeRemovables', params.removables)
      }

      context.dispatch('account/load', null, { root: true })
    }
  ).catch(
    e => console.error(e)
  )
}

const reorder = (context, index) => {
  (new Recipes()).reorder(index).then(
    () => context.commit('REORDER', index)
  ).catch(
      e => console.error(e)
  )
}

const remove = (context, recipeId) => {
  (new Recipes()).destroy(recipeId).then(
    () => context.commit('REMOVE', recipeId)
  ).catch(
    e => console.error(e)
  )
}

const purgeRemovables = (context, removables) => {
  if (removables.length > 0) {
    (new Recipes()).purge(removables)
  }
}

export default {
  save,
  load,
  remove,
  reorder,
  loadAll,
  purgeRemovables

}
