<template>
  <div id="suppliersWrapper">
    <div class="columns">
      <div class="column is-three-fifths-tablet is-half-desktop is-offset-one-fifth-tablet is-offset-one-quarter-desktop">
        <h1 class="title">{{ $t('suppliers.title') }}</h1>

        <div class="content">
          {{ $t('suppliers.intro') }}
        </div>

        <div
          v-for="(supplier, idx) in suppliers"
          :key="supplier.id"
        >
          <supplier :supplier="supplier" />

          <promotion-link v-if="idx === 0">
            {{ $t('suppliers.subscribeLink') }} <a href="mailto:info@myboilie.nl?subject=Vermelding als draaier op myboilie.nl">info@myboilie.nl</a>.
          </promotion-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Supplier from './Supplier'
import PromotionLink from '@/components/modules/PromotionLink'

export default {
  name: 'Suppliers',

  components: {
    Supplier,
    PromotionLink
  },

  computed: {
    ...mapGetters({
      suppliers: 'suppliers/suppliers'
    })
  },

  created () {
    this.$store.dispatch('suppliers/loadAll')
  }
}
</script>
