<template>
  <p>{{ $t('contact.message.sent') }}</p>
</template>

<script>
export default {
  name: 'Sent'
}
</script>

<style scoped>

</style>
