<template>
  <article v-show="!isSeen" class="message">
    <div class="message-header">
      <p>{{ $t('app.welcome.title') }}</p>
      <button class="delete" aria-label="delete" @click="close"></button>
    </div>
    <div class="message-body">
      <p>{{ $t('app.welcome.body') }}</p>
      <p class="has-text-centered"><button class="button is-dark" @click="close">Sluiten</button></p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'WelcomeNotice',
  data () {
    return {
      isSeen: false,
      cookie: 'first-load-notice'
    }
  },
  created () {
    this.isSeen = this.$cookies.get(this.cookie)
  },
  beforeDestroy () {
    this.setSeen()
  },
  methods: {
    close () {
      this.setSeen()
    },
    setSeen () {
      this.isSeen = true
      this.$cookies.set(this.cookie, true, null)
    }
  }
}
</script>

<style scoped>
  .message-body p:first-child {
    margin-bottom: 15px;
  }
</style>
