<template>
  <article v-show="!isGranted" id="cookieNotice" class="message is-warning is-radiusless">
    <div class="message-header is-radiusless">
      <p>{{ $t('app.cookies.title') }}</p>
    </div>
    <div class="message-body">
      <p>{{ $t('app.cookies.body') }}</p>
      <hr>
      <p class="has-text-centered">
        <button class="button is-black is-centered" @click="acceptCookies">Accepteren</button>
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'CookieNotice',
  data () {
    return {
      isGranted: false
    }
  },
  created () {
    this.isGranted = this.$cookies.get('cookie-grant')
  },
  methods: {
    acceptCookies () {
      this.$cookies.set('cookie-grant', true)
      this.isGranted = true
    }
  }
}
</script>

<style scoped>
#cookieNotice {
  position: fixed;
  bottom: -4vh;
  width: 100%;
}
</style>
