<template>
  <div id="tagFieldWrapper">
    <div class="columns">
      <div class="column">
        <div
            :class="{ 'is-active': suggestions.length > 0 }"
            class="dropdown is-fullwidth"
        >
          <div class="dropdown-trigger">
            <div class="field">
              <div class="control">
                <input
                    type="text"
                    name="label"
                    class="input"
                    placeholder="Tags"
                    v-model="label"
                    @keydown.enter="create" />
              </div>
            </div>
          </div>

          <div class="dropdown-menu" id="dropdown-menu3" role="menu">
            <div class="dropdown-content">
              <a
                  v-for="(tag, idx) in suggestions"
                  :key="idx"
                  href="#"
                  class="dropdown-item"
                  @click="add(tag)"
              >
                {{ tag.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="tags">
          <span
              v-for="(tag, idx) in selected"
              :key="idx"
              class="tag"
          >
            {{ tag.label }}

            <button
                class="delete is-small"
                @click="remove(idx)"
            ></button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagField',

  props: {
    tags: {
      type: Array,
      required: true
    },

    selected: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      label: null
    }
  },

  computed: {
    suggestions () {
      if (this.label?.length === 0) return []

      const matches = this.tags.filter(tag => tag.label.includes(this.label))

      return matches.filter(tag => !this.selected.map(t => t.id).includes(tag.id))
    }
  },

  methods: {
    add (tag) {
      this.$emit('push', tag)
      this.reset()
    },

    create () {
      this.$emit('add', this.label)
      this.reset()
    },

    reset () {
      this.label = null
    },

    remove (idx) {
      this.$emit('remove', idx)
    }
  }
}
</script>
