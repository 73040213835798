<template>
  <div
    id="postListWrapper"
    :class="hasTags"
  >
    <div class="columns">
      <div class="column is-one-quarter-desktop">
        <aside
          id="tagPicker"
          :class="openedStatus"
        >
          <template v-if="$screen.width < 1024">
            <span
              v-if="!showTags"
              class="icon has-text-white is-pulled-left"
              @click="toggleTags"
            >
              <font-awesome-icon icon="tags" />
            </span>

            <span
              v-else
              class="mb-3 delete is-pulled-right"
              @click="toggleTags"
            >
            </span>
          </template>

          <template v-if="showTags || $screen.width > 1023">
            <div
                class="box selected-tags"
                v-show="filter.length > 0"
            >
              <span
                class="delete is-pulled-right"
                @click="purgeTags"
              ></span>

              <div class="tags">
                <span
                  v-for="tag in tags.filter(tag => getFilterIdx(tag.id) !== null)"
                  :key="tag.id"
                  class="tag is-warning selected-tag"
                  @click="toggleTag(tag.id)"
                >
                  {{ tag.tag }}
                </span>
              </div>
            </div>

            <div class="control has-icons-right search-tags">
              <input
                class="input"
                type="text"
                placeholder="Zoeken"
                v-model="query"
              >
              <span class="icon is-right">
            <i class="fas fa-search"></i>
          </span>
            </div>

            <div class="tags">
              <span
                v-for="tag in filteredTags.filter(tag => getFilterIdx(tag.id) === null)"
                :key="tag.id"
                class="tag"
                @click="toggleTag(tag.id)"
              >
                {{ tag.tag }}
              </span>
            </div>
          </template>

          <template v-else>
            <div class="tags">
              <span
                class="tag"
                v-for="tagId in filter"
                :key="tagId"
                @click="toggleTag(tagId)"
              >
              {{ getTagName(tagId) }}
            </span>
            </div>
          </template>
        </aside>
      </div>

      <div
        id="postList"
        class="column is-three-fifths-tablet is-half-desktop is-offset-one-fifth-tablet is-offset-0-desktop"
        :style="$screen.width < 1024 ? 'margin-top: ' + margin + 'px;' : null"
      >
        <div
          class="card"
          v-for="post in posts"
          :key="post.id"
        >
          <div
            class="card-image"
            v-if="firstImage(post.body) !== null"
          >
            <figure class="image">
              <img :src="firstImage(post.body)" />
            </figure>
          </div>

          <header class="card-header">
            <p class="card-header-title">
              <router-link :to="{ name: 'post', params: { id: post.id } }">{{ post.title }}</router-link>
            </p>
          </header>

          <div class="card-content">
            <div
              v-show="post.tags.length > 0"
              class="tags"
            >
              <span
                v-for="tag in post.tags"
                :key="tag.id"
                class="tag"
                :class="{ 'is-info': filter.includes(tag.id) }"
              >
                {{ tag.tag }}
              </span>
            </div>

            <div class="content">
              <p class="is-size-7 has-text-weigth-bold">
                <time :datetime="post.published_at | moment('Y-M-D H:m')">{{ post.published_at | moment('D-M-Y H:mm') }}</time>
              </p>

              <p>{{ getSnippet(post.body) }}</p>

              <p>
                <router-link :to="{ name: 'post', params: { id: post.id } }">{{ $t('blog.read_more') }}</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PostList',

  data () {
    return {
      query: '',
      margin: 0,
      showTags: false
    }
  },

  computed: {
    ...mapGetters({
      tags: 'tags/tags',
      filter: 'posts/filter',
      posts: 'posts/filtered'
    }),

    openedStatus () {
      return (this.showTags) ? 'opened' : 'closed'
    },

    hasTags () {
      return (this.filter.length > 0) ? 'has-tags' : null
    },

    filteredTags () {
      if (this.query.length === 0) return this.tags

      return this.tags.filter(tag => tag.tag.toLowerCase().includes(this.query.toLowerCase()))
    }
  },

  updated () {
    this.margin = (!this.showTags) ? document.getElementById('tagPicker').clientHeight - 20 : 0
  },

  watch: {
    tags () {
      if (this.$attrs.tag) {
        this.filter.push(this.tags.find(x => x.tag === this.$attrs.tag).id)
      }
    }
  },

  methods: {
    getSnippet (content) {
      return `${content.substring(0, 100).replace(/<\/?[^>]+(>|$)/g, '')}...`
    },

    getFilterIdx (tagId) {
      const idx = this.filter.findIndex(x => x === tagId)

      if (idx !== -1) {
        return idx
      }

      return null
    },

    getTagName (tagId) {
      return this.tags.find(x => x.id === tagId).tag
    },

    toggleTag (tagId) {
      const idx = this.getFilterIdx(tagId)

      if (idx !== null) {
        this.$store.commit('posts/REMOVE_FROM_FILTER', idx)
      } else {
        this.$store.commit('posts/ADD_TO_FILTER', tagId)
      }
    },

    toggleTags () {
      this.showTags = !this.showTags
    },

    purgeTags () {
      this.$store.commit('posts/PURGE_FILTER')
    },

    firstImage (content) {
      const images = this.extractImages(content)

      return (images.length > 0) ? images[0].getAttribute('src') : null
    },

    extractImages (content) {
      const tmp = document.createElement('div')
      tmp.innerHTML = content

      return tmp.getElementsByTagName('img')
    }
  }
}
</script>

<style lang="css" scoped>
@media (max-width: 1023px) {
  #tagPicker {
    position: fixed;
    left: 0;
    top: 52px;
    padding: 10px 5px 10px 10px;
    border-radius: 0 0 5px 0;
    z-index: 29;
    background-color: hsl(204, 86%, 53%);
    /*opacity: 0.9;*/
    overflow-x: scroll;
  }

  .has-tags #tagPicker {
    width: 100vw;
    border-radius: 0;
  }

  #tagPicker.opened {
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    border-radius: 0;
  }
}

.has-tags #postList {
  margin-top: 25px;
}

.search-tags {
  margin-bottom: 15px;
}

.selected-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}

.selected-tags {
  margin-top: 30px;
}

.icon {
  margin-right: 5px;
}

.card {
  margin-bottom: 15px;
}
</style>
