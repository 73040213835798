import client from '@/http/client'

class Posts {
  path = 'posts'

  async fetchAll(isAdmin) {
    return await client.get(isAdmin ? `admin/${this.path}` : this.path)
  }

  async fetch(id) {
    return await client.get(`${this.path}/${id}`)
  }

  async save(data) {
    return await data.id ?
      client.put(`admin/${this.path}/${data.id}`, data) :
      client.post(`admin/${this.path}`, data)
  }

  async destroy(id) {
    return await client.delete(`admin/${this.path}/${id}`)
  }

  async uploadFile(data) {
    return await client.post(
      `admin/${this.path}/upload`,
      data
    )
  }
}

export { Posts }
