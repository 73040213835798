const SET_ADS = (state, data) => {
  state.ads = data
}

const SET_AD = (state, data) => {
  state.ad = data
}

const PUSH = (state, data) => {
  state.ads.push(data)
}

const CLICK = (state, deeplink) => {
  window.open(deeplink, '_blank')
}

const UPDATE = (state, data) => {
  const idx = state.ads.findIndex(x => x.id === data.id)

  if (idx !== -1) {
    state.ads[idx] = data
  }
}

const REMOVE = (state, id) => {
  const idx = state.ads.findIndex(x => x.id === id)

  if (idx !== -1) {
    state.ads.splice(idx, 1)
  }
}

export default {
  PUSH,
  CLICK,
  UPDATE,
  REMOVE,
  SET_AD,
  SET_ADS
}
