<template>
  <div id="adminWorkplaceWrapper">
    <div class="tabs">
      <ul>
        <router-link :to="{ name: 'admin.workplace' }" tag="li" :class="{ 'is-active': isActive('admin.workplace') }"><a>{{ $t('admin.workplace.ingredients') }}</a></router-link>
        <router-link :to="{ name: 'admin.workplace.groups' }" tag="li" :class="{ 'is-active': isActive('admin.workplace.groups') }"><a>{{ $t('admin.workplace.groups') }}</a></router-link>
        <router-link :to="{ name: 'admin.workplace.formulas' }" tag="li" :class="{ 'is-active': isActive('admin.workplace.formulas') }"><a>{{ $t('admin.workplace.formulas') }}</a></router-link>
        <router-link :to="{ name: 'admin.workplace.units' }" tag="li" :class="{ 'is-active': isActive('admin.workplace.units') }"><a>{{ $t('admin.workplace.units') }}</a></router-link>
<!--        <router-link :to="{ name: 'admin.workplace.profiler' }" tag="li" :class="{ 'is-active': isActive('admin.workplace.profiler') }"><a>{{ $t('admin.workplace.profiler') }}</a></router-link>-->
      </ul>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'AdminWorkplace',
  created () {
    this.$store.dispatch('units/loadAll')
    this.$store.dispatch('groups/loadAll')
    this.$store.dispatch('ingredients/loadAll')
  },
  methods: {
    isActive (route) {
      return this.$route.name === route
    }
  }
}
</script>

<style lang="css">
</style>
