const SET_TRANSLATIONS = (state, data) => {
  state.translations = data
}

const UPDATE = (state, data) => {
  state.translations.translations = data.translations
}

export default {
  UPDATE,
  SET_TRANSLATIONS
}
