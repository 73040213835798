import { Units } from '@/services/units'
import { Groups } from '@/services/groups'
import { Recipes } from '@/services/recipes'
import { Ingredients } from '@/services/ingredients'

const init = (context) => {
  (new Units()).fetch().then(
    res => context.commit('SET_UNITS', res.data)
  ).catch(
    e => console.error(e)
  );

  (new Groups()).fetch().then(
    res => context.commit('SET_GROUPS', res.data)
  ).catch(
    e => console.error(e)
  );

  (new Ingredients()).fetch(null, 'ingredients').then(
    res => context.commit('SET_INGREDIENTS', res.data)
  ).catch(
    e => console.error(e)
  );

  (new Ingredients()).fetch(null, 'ingredients/additives').then(
    res => context.commit('SET_ADDITIVES', res.data)
  ).catch(
    e => console.error(e)
  );

  (new Ingredients()).fetch(null, 'ingredients/producers').then(
    res => context.commit('SET_PRODUCER_INDEX', res.data)
  ).catch(
    e => console.error(e)
  );
}

const save = (context, payload) => {
  context.commit('SET_SAVING', true);

  (new Recipes()).save(payload).then(
    res => {
      if (payload.id) {
        context.commit('SET_RECIPE', payload)
        context.commit('SET_SAVED', true)
        context.commit('SET_SAVING', false)
      } else {
        context.commit('SET_RECIPE', res.data)
        context.commit('SET_SAVED', true)
        context.commit('SET_SAVING', false)
      }
    }
  ).catch(
    () => {
      context.commit('SET_SAVED', false)
      context.commit('SET_SAVING', false)
    }
  )
}

const loadRecipe = (context) => {
  const recipe = localStorage.getItem('recipe')

  if (recipe) {
    context.commit('LOAD_RECIPE', localStorage.getItem('recipe'))
  }
}

export default {
  init,
  save,
  loadRecipe,
}
