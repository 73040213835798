const SET_POST = (state, data) => {
  state.post = data
}

const UNSET_POST = (state) => {
  state.post = null
}

const SET_POSTS = (state, data) => {
  state.posts = data
  state.filtered = (state.filter.length > 0) ? state.posts.filter(post => state.filter.some(f => post.tags.map(tag => tag.id).indexOf(f) !== -1)) : state.posts
}

const SET_IMAGE = (state, data) => {
  data.params.editor.insertEmbed(data.params.cursorLocation, 'image', `${process.env.VUE_APP_API}/storage/${data.response}`)
  data.params.resetUploader()
}

const PUSH = (state, data) => {
  state.posts.push(data)
  state.post = data
}

const RESET = (state) => {
  state.post = null
}

const UPDATE = (state, data) => {
  state.post.title = data.title
  state.post.content = data.content
}

const REMOVE = (state, postId) => {
  const idx = state.posts.findIndex(x => x.id === postId)

  if (idx !== -1) {
    state.posts.splice(idx, 1)
  }
}

const ADD_TO_FILTER = (state, tagId) => {
  state.filter.push(tagId)
  state.filtered = state.posts.filter(post => state.filter.some(f => post.tags.map(tag => tag.id).indexOf(f) !== -1))
}

const REMOVE_FROM_FILTER = (state, idx) => {
  state.filter.splice(idx, 1)
  state.filtered = (state.filter.length > 0) ? state.posts.filter(post => state.filter.some(f => post.tags.map(tag => tag.id).indexOf(f) !== -1)) : state.posts
}

const PURGE_FILTER = (state) => {
  state.filter = []
  state.filtered = state.posts
}

export default {
  PUSH,
  RESET,
  UPDATE,
  REMOVE,
  SET_POST,
  SET_POSTS,
  SET_IMAGE,
  UNSET_POST,
  PURGE_FILTER,
  ADD_TO_FILTER,
  REMOVE_FROM_FILTER
}
