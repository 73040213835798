import { Posts} from '@/services/posts'

const loadAll = (context, isAdmin = false) => {
  (new Posts()).fetchAll(isAdmin).then(
    res => context.commit('SET_POSTS', res.data)
  ).catch(
    e => console.error(e)
  )
}

const load = (context, postId) => {
  (new Posts()).fetch(postId).then(
    res => context.commit('SET_POST', res.data)
  ).catch(
    e => console.error(e)
  )
}

const save = (context, data) => {
  (new Posts()).save(data).then(
    res => context.commit('PUSH', res.status === 201 ? res.data : data)
  ).catch(
    e => console.error(e)
  )
}

const remove = (context, postId) => {
  (new Posts()).destroy(postId).then(
    () => context.commit('REMOVE')
  ).catch(
    e => console.error(e)
  )
}

const upload = (context, params) => {
  let formData = new FormData()
  formData.append('image', params.file);

  (new Posts()).uploadFile(formData).then(
    res => {
      context.commit('SET_IMAGE', {
        params: params,
        response: res.data
      })
    }
  ).catch(
    e => console.error(e)
  )
}

export default {
  save,
  load,
  remove,
  upload,
  loadAll
}
