<template>
  <form @submit.prevent="submit">
    <div class="field">
      <label class="label">Je e-mail adres</label>

      <div class="control">
        <input
          v-model="email"
          :class="{ 'is-danger': hasErrors('email') }"
          class="input"
          type="email"
          placeholder="Voer je e-mail adres in"
        >
      </div>

      <p v-show="hasErrors('email')" class="help is-danger">Dit is geen geldig e-mail adres</p>
    </div>

    <div class="field is-hidden">
      <label class="label">Onderwerp</label>

      <input
        v-model="subject"
        :class="{ 'is-danger': hasErrors('subject') }"
        class="input"
        type="text"
        placeholder="Voer je onderwerp in"
      >

      <p v-show="hasErrors('subject')" class="help is-danger">Onderwerp mag niet leeg zijn</p>
    </div>

    <div class="field">
      <label class="label">Je bericht</label>

      <div class="control">
        <textarea
          v-model="message"
          :class="{ 'is-danger': hasErrors('message') }"
          class="textarea"
          placeholder="Laat een bericht achter..."
        ></textarea>

        <p v-show="hasErrors('message')" class="help is-danger">Bericht mag niet leeg zijn</p>
      </div>
    </div>

    <div class="field is-grouped">
      <div class="control">
        <button
            class="button is-link"
            :disabled="inValid || sending"
            @click="send"
        >
          {{ sending ? 'Verzenden...' : 'Verzenden' }}
        </button>
      </div>
      <div class="control">
        <button class="button is-link is-light">Annuleren</button>
      </div>
    </div>
  </form>
</template>

<script>
import { Messages } from '@/services/messages'

export default {
  name: 'Form',

  data () {
    return {
      email: '',
      message: '',
      sending: false,
      subject: null,
      errorBag: []
    }
  },

  computed: {
    inValid () {
      return this.email.length === 0 || this.message.length === 0
    }
  },

  methods: {
    send () {
      const payload = {
        email: this.email,
        message: this.message,
        subject: this.subject,
      };

      this.sending = true;
      this.errorBag = [];

      (new Messages()).sendEmail(payload).then(
        () => this.$emit('sent')
      ).catch(
        e => {
          this.sending = false
          this.errorBag = e.response.data.errors
        }
      )
    },

    hasErrors (field) {
      return Object.keys(this.errorBag).includes(field)
    }
  }
}
</script>
