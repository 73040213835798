const SET_MESSAGES = (state, messages) => {
  state.messages = messages
}

const PUSH_MESSAGE = (state, message) => {
  state.messages.push(message)
}

const CLEAR_MESSAGE = (state) => {
  state.messages.shift()
}

const CLEAR_MESSAGES = (state) => {
  state.messages = []
}

export default {
  SET_MESSAGES,
  PUSH_MESSAGE,
  CLEAR_MESSAGE,
  CLEAR_MESSAGES
}
