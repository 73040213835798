<template>
  <footer class="footer has-background-white">
    <div class="content has-text-centered">
      <p>
        {{ $t('copyright') }} {{ year }}

        <font-awesome-icon icon="copyright"/>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BottomBar',

  computed: {
    year () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
footer {
  margin: 15px 0 0;
}
</style>
