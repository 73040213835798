import client from '@/http/client'

class Pages {
  path = 'pages'

  constructor(admin) {
    if (admin) this.path = `admin/${this.path}`
  }

  async fetch(params = null) {
    if (params) {
      const keys = Object.keys(params)
      const values = Object.values(params)

      return (keys.includes('id')) ?
        await client.get(`${this.path}/${params.id}`) :
        await client.get(`${this.path}/${keys.join(':')}/${values.join(':')}`)

    }

    return await client.get(this.path)
  }

  async save(data) {
    return data.id ?
      await client.put(`${this.path}/${data.id}`, data) :
      await client.post(this.path, data)
  }

  async reorder(index) {
    return await client.put(`${this.path}/reorder`, index)
  }

  async destroy(id) {
    return await client.delete(`${this.path}/${id}`)
  }
}

export { Pages }
