import client from '@/http/client'

class Auth {
  baseUrl = process.env.VUE_APP_API

  async login(credentials) {
    await client.get(`${ this.baseUrl }/sanctum/csrf-cookie`)

    return await client.post(`${ this.baseUrl }/login`, credentials)
  }

  async logout() {
    return client.post(`${ this.baseUrl }/logout`)
  }
}

export {
  Auth
}
