<template>
  <div class="card is-primary has-background-warning is-light my-3">
    <div class="card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromotionLink'
}
</script>
