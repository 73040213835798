<template>
  <div id="accountWrapper">
    <div class="columns">
      <div class="column is-three-fifths-tablet is-half-desktop is-offset-one-fifth-tablet is-offset-one-quarter-desktop">
        <div class="tabs">
          <ul>
            <li :class="{ 'is-active': this.isActive('profile') }">
              <router-link :to="{ name: 'account.profile' }">Profiel</router-link>
            </li>

            <li :class="{ 'is-active': this.isActive('recipes') }">
              <router-link :to="{ name: 'account.recipes' }">Recepten</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-three-fifths-tablet is-half-desktop is-offset-one-fifth-tablet is-offset-one-quarter-desktop">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Account',

  methods: {
    isActive (page) {
      return this.$route.name.includes(page)
    }
  }
}
</script>
