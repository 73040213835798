<template>
  <div class="columns">
    <div
      v-if="!message"
      class="column"
    >
      <article class="message is-danger">
        <div class="message-header">
          <p>{{ $t('profile.cancel.title') }}</p>
        </div>
        <div class="message-body">
          <p><b>{{ $t('profile.cancel.body.alert') }}</b> {{ $t('profile.cancel.body.text') }}</p>
        </div>
      </article>

      <div class="field">
        <label class="label">{{ $t('profile.cancel.password') }}</label>
        <div class="control">
          <input class="input" type="password" name="password" v-model="password">
        </div>
      </div>

      <button
        :disabled="!password"
        class="button is-danger is-fullwidth"
        @click="submit"
      >
        {{ $t('profile.cancel.submit') }}
      </button>
    </div>

    <div
      v-else
      class="column"
    >
      <article class="message is-success">
        <div class="message-header">
          <p>{{ $t('profile.cancel.removed') }}</p>
        </div>
        <div class="message-body">
          <p>{{ message }}</p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Cancel',

  data () {
    return {
      password: null
    }
  },

  computed: {
    ...mapGetters({
      message: 'http/message',
      redirect: 'http/redirect'
    })
  },

  watch: {
    redirect () {
      if (this.redirect) {
        setTimeout(() => this.$router.push({ name: 'workplace' }), 3000)
      }
    }
  },

  methods: {
    submit () {
      this.$store.dispatch('account/remove', { password: this.password })
    }
  }
}
</script>

<style scoped>

</style>
