import Vue from 'vue'
import Vuex from 'vuex'
import ads from './ads'
import nav from './nav'
import http from './http'
import tags from './tags'
import posts from './posts'
import units from './units'
import pages from './pages'
import lines from './lines'
import groups from './groups'
import options from './options'
import account from './account'
import recipes from './recipes'
import messages from './messages'
import workplace from './workplace'
import producers from './producers'
import suppliers from './suppliers'
import ingredients from './ingredients'
import adPositions from './adPositions'
import translations from './translations'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    ads: ads,
    nav: nav,
    http: http,
    tags: tags,
    posts: posts,
    units: units,
    pages: pages,
    lines: lines,
    groups: groups,
    options: options,
    account: account,
    recipes: recipes,
    messages: messages,
    workplace: workplace,
    producers: producers,
    suppliers: suppliers,
    ingredients: ingredients,
    adPositions: adPositions,
    translations: translations
  }
})
