import client from '@/http/client'

class Lines {
  path = 'admin/lines'

  async fetch(id = null, uri = null) {
    const path = uri ? uri : this.path

    return await client.get(id ? `${path}/${id}` : path)
  }

  async save(data) {
    return data.id ?
      await client.put(`${this.path}/${data.id}`, data) :
      await client.post(this.path, data)
  }

  async destroy(id) {
    return await client.delete(`${this.path}/${id}`)
  }
}

export { Lines }
