export default {
  computed: {
    total () {
      return this.getIngredientsTotal(this.recipe.ingredients) / this.$factor
    },

    maximums () {
      return (this.isEmpty(this.recipe.ingredients)) ? false : (this.isInBootstrapMode) ? this.total >= this.recipe.limit : this.total >= 100
    },

    floatility () {
      return this.recipe.ingredients.filter(ing => !this.isInBase(ing.id) && ing.density === 1 && this.isRelative(ing)).map(ing => ing.pivot.amount).reduce((a, i) => a + i, 0)
    },

    isAtTotalMax () {
      return (this.recipe.mode === 'diy') ? this.total === 100 * this.$factor : this.getAdditivesTotal(this.recipe.ingredients) >= this.recipe.limit * this.$factor
    },

    hasBase () {
      return !!this.recipe.base
    },

    maxAvailable () {
      return (this.isInBootstrapMode) ? parseFloat(this.recipe.limit) - this.addedTotal : 100 - this.total
    },

    addedTotal () {
      return this.getIngredientsTotal(this.recipe.ingredients.filter(ing => !this.isInBase(ing.id))) / this.$factor
    }
  },

  methods: {
    isEmpty (ingredients) {
      return (this.isInBootstrapMode) ? ingredients.length === this.recipe.base.length : ingredients.length === 0
    },

    isAtIngredientLimit (ingredient) {
      return (ingredient.pivot) ? ingredient.pivot.amount >= ingredient.limit * this.$factor : false
    },

    isLocked (ingredient) {
      if (this.isInBootstrapMode && ingredient.density === 1 && this.floatility >= 5 * this.$factor) return true

      return (this.isAtIngredientLimit(ingredient) || this.maximums) && ingredient.symbol === '%'
    },

    isNotMax (ingredientId) {
      if (this.isAtTotalMax) return false

      const ingredient = this.recipe.ingredients.find(x => x.id === ingredientId)

      if (ingredient) {
        return !this.isAtIngredientLimit(ingredient)
      }

      return true
    },

    isNotNill (ingredientId) {
      return this.findInRecipe(ingredientId) !== null
    },

    getIngredientInBase (ingredientId) {
      return this.recipe.base.find(x => x.id === ingredientId)
    },

    getIngredientsTotal (ingredients) {
      return ingredients.filter(ing => this.isRelative(ing) && !this.isInBase(ing.id))
        .map(ing => ing.pivot.amount)
        .reduce((total, amount) => {
          return amount + total
        }, 0)
    },

    getAdditivesTotal (ingredients) {
      return ingredients.filter(ing => this.isAdditive(ing.id)).map(ing => ing.pivot.amount).reduce((total, amount) => {
        return amount + total
      }, 0)
    },

    getBaseTotal () {
      return this.recipe.base.filter(
        ing => {
          const idx = this.findInRecipe(ing.id)
          return !this.isAdditive(ing.id) && this.isRelative(this.recipe.ingredients[idx])
        }
      )
      .map(ing => ing.rel)
      .reduce((total, amount) => {
        return amount + total
      }, 0)
    },

    getBaseRemainder (ingredients) {
      return (100 * this.$factor) - this.getIngredientsTotal(ingredients) - this.getAdditivesTotal(ingredients)
    },

    getAmount (inc, op, unitId) {
      inc = parseFloat(inc)

      if (op === '-' || unitId !== 1) return inc

      const limit = (this.isInBootstrapMode) ? this.recipe.limit : 100

      return (inc + this.total > limit) ? limit - this.total : inc
    }
  }
}
