<template lang="html">
  <div class="columns">
    <div class="column is-one-fifth">
      <admin-menu></admin-menu>
    </div>

    <div class="column">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import AdminMenu from '@/components/nav/AdminMenu'

export default {
  name: 'Admin',
  components: {
    AdminMenu
  },
  created () {
    this.$store.dispatch('workplace/init')
  }
}
</script>

<style lang="css">
</style>
