import client from '@/http/client'

class Messages {
  path = 'messages/send'

  async sendEmail(data) {
    return await client.post(this.path, data)
  }
}

export { Messages }
