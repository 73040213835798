const SET_UNITS = (state, data) => {
  state.units = data
}

const SET_UNIT = (state, data) => {
  state.unit = data
}

const PUSH = (state, data) => {
  state.units.push(data)
}

const UPDATE = (state, data) => {
  const idx = state.units.findIndex(x => x.id === data.id)

  if (idx !== -1) {
    state.units[idx] = data
  }
}

const REMOVE = (state, id) => {
  const idx = state.units.findIndex(x => x.id === id)

  if (idx !== -1) {
    state.units.splice(idx, 1)
  }
}

export default {
  PUSH,
  UPDATE,
  REMOVE,
  SET_UNIT,
  SET_UNITS
}
