<template>
  <div id="recipesWrapper">
    <div class="columns">
      <div class="column">
        <div class="field">
          <div class="control">
            <input
              type="text"
              class="input"
              name="search"
              placeholder="Zoeken"
              v-model="query"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div
        v-if="profile"
        class="column"
      >
        <div
            v-for="recipe in filtered"
            :key="recipe.id"
            class="card mb-2"
        >
          <div class="card-content">
            <div
                class="media"
                @click="expand(isExpanded(recipe.id) ? null : recipe.id)"
            >
              <div
                  v-if="hasImages(recipe)"
                  class="media-left"
              >
                <figure class="image is-48x48">
                  <img :src="recipe.images[0]" alt="Image not found">
                </figure>
              </div>

              <div class="media-content">
                <p class="title is-4">
                  {{ recipe.name }}
                </p>
                <p class="subtitle is-size-7">{{ recipe.ingredients | listed }}</p>
              </div>
            </div>

            <div
                v-show="isExpanded(recipe.id)"
                class="content"
            >
              <div class="content" v-html="recipe.description"></div>

              <ul>
                <li
                    v-for="ingredient in recipe.ingredients"
                    :key="ingredient.id"
                >
                  {{ ingredient.name }}<span class="is-pulled-right">{{ ingredient.pivot.amount / $factor }}{{ ingredient.unit.symbol }}</span>
                </li>
              </ul>
            </div>
          </div>

          <div class="card-footer">
            <a class="card-footer-item">
              <font-awesome-icon
                icon="download"
                @click="download(recipe.id)"
              />
            </a>

            <router-link
              :to="{ name: 'account.recipes.edit', params: { id: recipe.id } }"
              class="card-footer-item"
            >
              <font-awesome-icon icon="pencil-alt" size="xs"></font-awesome-icon>
            </router-link>

            <a class="card-footer-item">
              <font-awesome-icon
                icon="trash"
                class="has-text-danger"
                @click="confirm = recipe.id"
              />
            </a>
          </div>
        </div>
      </div>

      <div
        v-else
        class="column"
      >
        <div class="notification">Recepten worden geladen</div>
      </div>
    </div>

    <confirm
      :is-active="confirm !== null"
      text="Weet je zeker dat je dit recept wilt verwijderen?"
      @confirmed="remove"
      @cancelled="confirm = null"
    ></confirm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Recipes } from '@/services/recipes'
import workplace from '@/mixins/workplace/recipe'
import Confirm from '@/components/modules/Confirm'

export default {
  name: 'Recipes',

  components: {
    Confirm
  },

  mixins: [
    workplace
  ],

  filters: {
    listed (ingredients) {
      return ingredients.map(ing => ing.name).join(', ')
    }
  },

  data () {
    return {
      query: null,
      confirm: null,
      expanded: null
    }
  },

  computed: {
    ...mapGetters({
      profile: 'account/profile'
    }),

    filtered () {
      if (!this.query || this.query.length === 0) return this.profile.recipes

      return this.profile.recipes.filter(recipe => {
        const query = this.query?.toLowerCase()
        return recipe.name.toLowerCase().includes(query) || recipe.labels.map(tag => tag.label).join(' ').includes(query)
      })
    }
  },

  methods: {
    expand (id) {
      this.expanded = id
    },

    hasImages(recipe) {
      return recipe.images?.length > 0;
    },

    isExpanded (id) {
      return this.expanded === id
    },

    download (id) {
      (new Recipes()).download(id).then(
          res => {
            const header = res.headers['content-disposition']
            const filename = header.match(/filename="(.*)"/)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', filename[1]);

            document.body.appendChild(link);

            link.click();
          }
      )
    },

    remove () {
      this.$store.dispatch('account/removeRecipe', this.confirm)
      this.confirm = null
    }
  }
}
</script>
