<template>
  <div id="adminWorkplaceGroupsWrapper">
    <router-view v-if="'id' in $attrs"></router-view>

    <template v-else>
      <div class="columns">
        <div class="column">
          <router-link
              class="button is-pulled-right"
              :to="{ name: 'admin.workplace.groups.edit', params: { id: null } }">

            {{ $t('admin.workplace.newGroup') }}
          </router-link>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <table v-show="!$route.params.id" class="table is-striped is-hoverable">
            <thead>
            <tr>
              <th>{{ $t('admin.workplace.name') }}</th>
              <th>{{ $t('admin.workplace.description') }}</th>
              <!-- <th>Ingredienten</th> -->
            </tr>
            </thead>

            <tbody>
            <tr
                v-for="group in groups"
                :key="group.id"
                @click="edit(group.id)">

              <td>{{ group.name }}</td>
              <td>{{ group.description }}</td>
              <!-- <td>{{ group.ingredients.length }}</td> -->
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminWorkplaceGroups',
  computed: {
    ...mapGetters({
      groups: 'groups/groups'
    })
  },
  created () {
    this.$store.dispatch('groups/loadAll')
  },
  methods: {
    edit (groupId) {
      this.$router.push({ name: 'admin.workplace.groups.edit', params: { id: groupId } })
    }
  }
}
</script>

<style lang="css">
</style>
