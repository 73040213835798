<template>
  <aside class="menu">
    <p class="menu-label">
      {{ $t('admin.menu.content') }}
    </p>

    <ul class="menu-list">
      <li v-if="isAdmin"><router-link :to="{ name: 'admin' }">{{ $t('admin.menu.dashboard') }}</router-link></li>
      <li v-if="isAdmin"><router-link :to="{ name: 'admin.posts' }">{{ $t('admin.menu.blog') }}</router-link></li>
      <li v-if="isAdmin || representsProducer"><router-link :to="{ name: 'admin.workplace' }">{{ $t('admin.menu.workplace') }}</router-link></li>
      <li v-if="isAdmin"><router-link :to="{ name: 'admin.producers' }">{{ $t('admin.menu.producers') }}</router-link></li>
      <li v-if="isAdmin"><router-link :to="{ name: 'admin.suppliers' }">{{ $t('admin.menu.suppliers') }}</router-link></li>
      <li v-if="isAdmin"><router-link :to="{ name: 'admin.pages' }">{{ $t('admin.menu.pages') }}</router-link></li>
      <li v-if="isAdmin"><router-link :to="{ name: 'admin.translations' }">{{ $t('admin.menu.translations') }}</router-link></li>
    </ul>

    <template v-if="isAdmin">
      <p class="menu-label">
        {{ $t('admin.menu.administration') }}
      </p>
      <ul class="menu-list">
        <li><a>{{ $t('admin.menu.users') }}</a></li>
      </ul>
    </template>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isAdmin: 'account/isAdmin',
      representsProducer: 'account/representsProducer',
    }),
  }
}
</script>

