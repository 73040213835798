<template>
  <div id="postEditWrapper">
    <div
      class="notification"
      v-if="!hasLoaded"
    >
      {{ $t('app.loading') }}
    </div>

    <template v-else>
      <p>
        <back-link to="/admin/posts">Terug</back-link>
      </p>

      <hr>

      <h2 class="title is-4">{{ (post) ? $t('admin.blog.edit') + ' : ' + post.title : $t('admin.blog.new') }}</h2>

      <div class="field">
        <label
          class="label"
          for="title"
        >
          {{ $t('admin.blog.title') }}
        </label>

        <div class="control">
          <input class="input" type="text" name="title" v-model="title">
        </div>
      </div>

      <div class="field">
        <label
          class="label"
          for="title"
        >
          {{ $t('admin.blog.publish_at') }}
        </label>

        <div class="control">
          <date-pick
            format="DD-MM-YY HH:mm"
            :pickTime="true"
            v-model="published_at"
          ></date-pick>
        </div>
      </div>

      <div class="box">
        <label class="label">{{ $t('admin.blog.tags.label') }}</label>
        <div class="field is-grouped is-grouped-multiline">
          <div
            v-for="(tag, idx) in tags"
            :key="idx"
            class="control"
          >
            <div class="tags has-addons">
              <a class="tag is-link">{{ tag.tag }}</a>

              <a
                class="tag is-delete"
                @click="removeTag(idx)"
              ></a>
            </div>
          </div>
        </div>

        <div class="field">
          <div class="control">
            <input
              class="input"
              v-model="tag"
              @keyup.enter="pushTag"
              :placeholder="$t('admin.blog.tags.add')"
            >
          </div>
        </div>
      </div>

      <div class="field">
        <label
          class="label"
        >

          {{ $t('admin.blog.text') }}
        </label>

        <div class="control">
          <rte
            :content="body"
            @contentChange="handleContentChange"
          ></rte>
        </div>
      </div>

      <form-buttons
        @submit="submit"
        @close="close"
        @remove="remove"
      ></form-buttons>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePick from 'vue-date-pick'
import Rte from '@/components/modules/Rte'
import BackLink from '@/components/modules/BackLink'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'AdminPostEdit',

  components: {
    Rte,
    DatePick,
    BackLink,
    FormButtons
  },

  data () {
    return {
      tag: null,
      tags: [],
      title: null,
      body: null,
      published_at: null
    }
  },

  computed: {
    ...mapGetters({
      post: 'posts/post'
    }),

    hasLoaded () {
      return this.post !== null || !this.$attrs.id
    }
  },

  created () {
    if (this.$attrs.id) {
      this.$store.dispatch('posts/load', this.$attrs.id)
    }
  },

  destroyed () {
    this.$store.commit('posts/RESET')
  },

  watch: {
    post () {
      if (this.post) {
        this.title = this.post.title
        this.body = this.post.body
        this.published_at = this.$moment(this.post.published_at).format('D-M-Y h:mm')
        this.tags = this.post.tags
      }
    }
  },

  methods: {
    pushTag () {
      if (this.tag) {
        this.tags.push({ id: null, tag: this.tag })
        this.tag = null
      }
    },

    removeTag (idx) {
      this.tags.splice(idx, 1)
    },

    handleContentChange (payload) {
      this.body = payload
    },

    submit () {
      this.$store.dispatch('posts/save', {
        id: (this.post) ? this.post.id : null,
        title: this.title,
        body: this.body,
        published_at: this.published_at,
        tags: this.tags
      })
    },

    close () {
      this.$router.go(-1)
    },

    remove () {
      this.$store.dispatch('posts/remove', this.post.id)
      this.close()
    }
  }
}
</script>

<style lang="css">
@import '../../../node_modules/vue-date-pick/dist/vueDatePick.css';
</style>
