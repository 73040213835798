<template>
  <modal :is-active="isActive">
    <div class="notification is-danger m-3">
      <p>{{ $t('workplace.reset.confirmation') }}</p>

      <hr>

      <button
        class="button is-danger is-inverted"
        @click="$emit('confirmed')"
      >
        {{ $t('workplace.reset.confirmed') }}
      </button>

      <button
        class="button is-text has-text-white-ter"
        @click="$emit('cancelled')"
      >
        {{ $t('workplace.reset.cancelled') }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/modules/Modal'
export default {
  name: 'ResetConfirmation',

  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  components: {
    Modal
  }
}
</script>

<style scoped>

</style>
