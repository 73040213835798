<template>
  <nav
    class="navbar is-fixed-top is-primary"
    :class="{ 'with-shadow': scroll }"
    role="navigation"
    aria-label="main navigation"
  >
    <!-- Home -->
    <div class="navbar-brand">
      <router-link
        :to="{ name: 'home' }"
        id="homeButton"
        class="navbar-item has-text-weight-bold"
      >
        <font-awesome-icon
          icon="home"
          class="mr-2"
        />

        {{ $t('app.name') }}
      </router-link>

      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="mainNav"
        :class="{ 'is-active': isActive }"
        @click="toggle"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div
      v-if="pages"
      class="navbar-menu"
      :class="{ 'is-active': isActive }"
    >
      <div class="navbar-start">
        <!-- Blog -->
        <router-link
          :to="{ name: 'posts' }"
          class="navbar-item"
        >
          {{ $t('nav.blog') }}
        </router-link>

        <!-- Producers -->
        <div
          ref="dropdowns"
          class="navbar-item has-dropdown"
          :class="{ 'is-active': dropdown === 0 }"
        >
          <a
            class="navbar-link"
            @click="toggleDropdown(0)"
          >
            {{ $t('nav.supplies') }}
          </a>

          <div class="navbar-dropdown">
            <router-link
              :to="{ name: 'producers' }"
              class="navbar-item"
            >
              {{ $t('nav.producers') }}
            </router-link>

            <router-link
              :to="{ name: 'suppliers' }"
              class="navbar-item"
            >
              {{ $t('nav.suppliers') }}
            </router-link>
          </div>
        </div>

        <!-- About -->
        <div
          ref="dropdowns"
          class="navbar-item has-dropdown"
          :class="{ 'is-active': dropdown === 1 }"
        >
          <a
            class="navbar-link"
            @click="toggleDropdown(1)"
          >
            {{ $t('nav.about') }}
          </a>

          <div class="navbar-dropdown">
            <router-link
              v-for="(page, idx) in aboutPages"
              :key="idx"
              :to="{ name: 'about', params: { page: page.slug } }"
              class="navbar-item"
            >
              {{ page.header }}
            </router-link>
          </div>
        </div>

        <!-- Contact -->
        <router-link
          :to="{ name: 'contact' }"
          class="navbar-item"
        >
          {{ $t('nav.contact') }}
        </router-link>

        <!-- Other -->
        <div
          ref="dropdowns"
          class="navbar-item has-dropdown"
          :class="{ 'is-active': dropdown === 2 }"
        >
          <a
            class="navbar-link"
            @click="toggleDropdown(2)"
          >
            {{ $t('nav.other') }}
          </a>

          <div class="navbar-dropdown">
            <router-link
              v-for="(page, idx) in otherPages"
              :key="idx"
              :to="{ name: 'other', params: { page: page.slug } }"
              class="navbar-item"
            >
              {{ page.header }}
            </router-link>
          </div>
        </div>

        <!-- Admin -->
        <router-link
          v-if="isAdmin || representsProducer"
          :to="{ name: 'admin' }"
          class="navbar-item"
        >
          {{ $t('nav.admin') }}
        </router-link>
      </div>

      <div class="navbar-end">
        <!-- Account if signed in -->
        <template v-if="isAuth">
          <a
            class="navbar-item"
            @click="signOut"
          >
            Uitloggen
          </a>

          <router-link
            class="navbar-item"
            :to="{ name: 'account.profile' }"
          >
            Account
          </router-link>
        </template>

        <!-- Account if signed out -->
        <template v-else>
          <div
            class="navbar-item has-dropdown"
            :class="{ 'is-active': activeModule === 'login' }"
          >
            <a
              class="navbar-link"
              @click="toggleModule('login')"
            >
              Aanmelden
            </a>

            <div class="navbar-dropdown is-right">
              <sign-in></sign-in>
            </div>
          </div>

          <div
              class="navbar-item has-dropdown"
              :class="{ 'is-active': activeModule === 'register' }"
          >
            <a
                class="navbar-link"
                @click="toggleModule('register')"
            >
              Registreren
            </a>

            <div class="navbar-dropdown is-right">
              <register></register>
            </div>
          </div>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import SignIn from '@/components/auth/SignIn'
import Register from '@/components/auth/Register'
import workplaceMixin from '@/mixins/workplace/recipe'

export default {
  name: 'NavBar',

  components: {
    SignIn,
    Register
  },

  mixins: [
    workplaceMixin
  ],

  data () {
    return {
      scroll: false,
      dropdown: null,
      isActive: false,
      activeModule: null
    }
  },

  computed: {
    ...mapGetters({
      pages: 'nav/pages',
      isAuth: 'account/isAuth',
      recipe: 'workplace/recipe',
      isAdmin: 'account/isAdmin',
      profile: 'account/profile',
      representsProducer: 'account/representsProducer',
      redirect: 'http/redirect'
    }),

    aboutPages () {
      return this.pages.filter(page => page.is_about === 1)
    },

    otherPages () {
      return this.pages.filter(page => page.is_about === 0 && !page.parent_id)
    }
  },

  created () {
    this.$store.dispatch('nav/load')

    this.$bus.$on('routerLinkClicked', () => {
      this.isActive = false
    })

    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    toggle () {
      this.isActive = !this.isActive
    },

    toggleModule (module) {
      this.activeModule = (module !== this.activeModule) ? module : null
    },

    toggleDropdown (id) {
      this.dropdown = id === this.dropdown ? null : id
    },

    handleScroll () {
      this.scroll = window.scrollY > 0
    },

    signOut () {
      this.$store.dispatch('account/signOut').then(
          () => {
            this.$store.commit('workplace/PURGE')
            this.$router.push({ name: 'home' })
          }
      )
    }
  }
}
</script>

<style lang="css" scoped>
.navbar-item.has-dropdown .navbar-dropdown {
  display: none;
}

.navbar-item.has-dropdown.is-active .navbar-dropdown {
  display: block;
}

.with-shadow {
  box-shadow: 0 0 10px #222;
}
</style>
