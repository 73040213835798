<template>
  <div class="ingredientsWrapper">
    <div class="columns">
      <div class="column is-one-fifth-tablet is-1-desktop">
        <h2 class="title is-6">
          <router-link :to="{ name: 'workplace' }">
            <font-awesome-icon icon="chevron-left" />

            {{ $t('workplace.back') }}
          </router-link>
        </h2>
      </div>

      <div class="column is-three-fifths-tablet is-6-desktop">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="query" :placeholder="$t('workplace.ingredients.search')">
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-three-fifths-tablet is-6-desktop is-offset-one-fifth-tablet is-offset-1-desktop">
        <ingredient-card
            v-for="ingredient in filteredIngredients"
            :key="ingredient.id"
            :ingredient="ingredient"
            class="card"
        />

        <div class="notification">
          {{ $t('workplace.ingredients.missing.text') }}

          <router-link
            :to="{ name: 'contact' }"
            class="button is-link"
          >
            {{ $t('workplace.ingredients.missing.button') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import recipe from '@/mixins/workplace/recipe'
import supplies from '@/mixins/workplace/supplies'
import machines from '@/mixins/workplace/machines'
import instruments from '@/mixins/workplace/instruments'

import IngredientCard from '@/components/workplace/IngredientCard'

export default {
  name: 'Ingredients',

  components: {IngredientCard},

  mixins: [
    recipe,
    supplies,
    machines,
    instruments
  ],

  data () {
    return {
      query: null,
      adding: false,
      formatter: v => v.toFixed(1),
      areExpanded: []
    }
  },

  computed: {
    myIngredients () {
      const availableIngredients = (this.producerIngredients ?? this.ingredients).filter(ingredient => ingredient.group_id === parseInt(this.$attrs.id))

      return this.isInBootstrapMode ? availableIngredients.filter(ing => !this.recipe.original_ingredients.map(ing => ing.id).includes(ing.id)) : availableIngredients
    },

    filteredIngredients () {
      if (!this.query || this.query.length === 0) {
        return  this.myIngredients
      }

      return this.myIngredients.filter(ingredient => {
        return ingredient.name.toLowerCase().includes(this.query.toLowerCase())
      })
    }
  },

  methods: {
    toggle (group) {
      this.group = group
    },

    toggleInfo (ingredientId) {
      const idx = this.areExpanded.findIndex(x => x === ingredientId)

      if (idx === -1) {
        this.areExpanded.push(ingredientId)
      } else {
        this.areExpanded.splice(idx, 1)
      }
    },

    isDisabled (unitId) {
      return this.maximums && unitId === 1
    },

    atLimit (ingredientId) {
      const ingredient = this.recipe.ingredients.find(x => x.id === ingredientId)

      return (ingredient) ? this.isAtLimit(ingredient) : false
    },

    getMax(limit) {
      return parseFloat(limit)
    },

    isExpanded (ingredientId) {
      return this.areExpanded.includes(ingredientId)
    },

    dotOptions (ingredient) {
      return [
        {
          min: 0,
          max: this.myLimit(ingredient)
        }
      ]
    },
  }
}
</script>

<style lang="css" scoped>
sup {
  margin-left: 0.5rem;
}

.card {
  margin-bottom: 30px;
}

.card-content {
  z-index: 0;
}
</style>
