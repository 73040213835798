<template>
  <div class="modal" :class="{ 'is-active': isActive }">
    <div class="modal-background"></div>

    <div class="modal-content">
      <slot></slot>
    </div>

    <button
      v-show="!noCloseButton"
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    isActive: {
      type: Boolean,
      required: true,
      default: false
    },

    noCloseButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>
