<template>
  <div id="adminSupplierWrapper">
    <div class="box">
      <div class="field">
        <label class="label">{{ $t('admin.supplier.name') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="name">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.supplier.address') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="address">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.supplier.zip') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="zip">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.supplier.city') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="city">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.supplier.country') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="country">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.supplier.email') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="email">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.supplier.phone') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="phone">
        </div>
      </div>
    </div>

    <form-buttons
      @submit="submit"
      @close="close"
      @remove="remove">
  </form-buttons>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'AdminSupplier',

  components: {
    FormButtons
  },

  data () {
    return {
      id: null,
      name: null,
      address: null,
      zip: null,
      city: null,
      country: null,
      email: null,
      phone: null,
    }
  },

  computed: {
    ...mapGetters({
      supplier: 'suppliers/supplier'
    })
  },

  created () {
    if (this.$attrs.id) this.$store.dispatch('suppliers/load', { id: this.$attrs.id, admin: true })
  },

  watch: {
    supplier () {
      if (this.supplier) {
        this.id = this.supplier.id
        this.name = this.supplier.name
        this.address = this.supplier.address
        this.zip = this.supplier.zip
        this.city = this.supplier.city
        this.country = this.supplier.country
        this.email = this.supplier.email
        this.phone = this.supplier.phone
      }
    },
  },

  methods: {
    submit () {
      this.$store.dispatch('suppliers/save', {
        id: (this.id) ? this.id : null,
        name: this.name,
        address: this.address,
        zip: this.zip,
        city: this.city,
        country: this.country,
        email: this.email,
        phone: this.phone,
        ingredients: this.selected
      })
    },

    close () {
      this.id = this.name = this.address = this.zip = this.city = this.country = this.email = this.phone = null
      this.goToIndex()
    },

    remove () {
      this.$store.dispatch('suppliers/remove', this.id)
      this.goToIndex()
    },

    goToIndex () {
      this.$router.push({ name: 'admin.suppliers' })
    }
  }
}
</script>
