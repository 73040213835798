const SET_OPTION = (state, data) => {
  state.option = data
}

const UPDATE_OPTION = (state, data) => {
  state.option.body = data.body
  state.option.follow_up_id = data.follow_up_id
  state.option.recipe_id = data.recipe_id
  state.option.amount = data.amount
}

const REMOVE_OPTION = (state, id) => {
  const idx = state.options.findIndex(x => x.id === id)

  if (idx !== -1) {
    state.options.splice(idx, 1)
  }
}

export default {
  SET_OPTION,
  UPDATE_OPTION,
  REMOVE_OPTION
}
