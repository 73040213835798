import { Auth } from '@/services/auth'
import { Profile } from '@/services/profile'
import {Recipes} from "@/services/recipes";

const register = (context, payload) => {
  (new Profile()).create(payload).then(
    () => context.commit('SET_REGISTRATION_STATUS', true)
  ).catch(
    e => console.error(e)
  )
}

const signIn = (context, credentials) => {
  (new Auth()).login(credentials).then(
    () => {
      (new Profile()).fetch().then(
        res => context.commit('SET_PROFILE', res.data)
      )
    }
  ).catch(
    e => console.error(e)
  )
}

const signOut = (context) => {
  (new Auth()).logout().then(
      res => {
        context.commit('UNSET_PROFILE')
        context.commit('http/SET', res.data)
      }
  )
}

const reset = (context, email) => {
  (new Profile()).reset(email).then(
    res => {
      context.commit('http/SET', res.data)
    }
  )
}

const load = (context) => {
  (new Profile().fetch()).then(
    res => context.commit('SET_PROFILE', res.data)
  ).catch(e => console.error(e))
}

const edit = (context, data) => {
  (new Profile()).update(data).then(
    () => context.commit('SET_PROFILE', data)
  ).catch(e => console.error(e))
}

const password = (context, data) => {
  (new Profile()).password(data).catch(e => console.error(e))
}

const remove = (context, password) => {
  (new Profile()).destroy(password).then(
    () => context.commit('SET_PROFILE', null)
  ).catch(e => console.error(e))
}

const removeRecipe = (context, recipeId) => {
  (new Recipes()).destroy(recipeId).then(
    () => context.commit('UNSET_RECIPE', recipeId)
  ).catch(e => console.error(e))
}

export default {
  load,
  edit,
  reset,
  remove,
  signIn,
  signOut,
  register,
  password,
  removeRecipe,
}
