import {Translations} from "@/services/translations";

const load = (context) => {
  (new Translations()).fetch().then(
    res => context.commit('SET_TRANSLATIONS', res.data)
  ).catch(
    e => console.error(e)
  )
}

const save = (context, params) => {
  (new Translations()).save(params).then(
    () => context.commit('UPDATE', params)
  ).catch(
    e => console.error(e)
  )
}

const sync = (context) => {
  (new Translations()).sync().then(
    res => context.commit('SET_TRANSLATIONS', res.data)
  ).catch(
    e => console.error(e)
  )
}

export default {
  save,
  load,
  sync
}
