const SET_LINES = (state, data) => {
  state.lines = data
}

const SET_LINE = (state, data) => {
  state.line = data
}

const PUSH_LINES = (state, data) => {
  state.lines = (state.lines === null) ? data : state.lines.concat(data)
}

const CLEAR_LINES = (state) => {
  state.lines = []
}

const PUSH_REPLY = (state, data) => {
  state.lines.push(data)
}

const REMOVE = (state, id) => {
  const idx = state.lines.findIndex(x => x.id === id)

  state.lines.splice(idx, 1)
}

const ADD_OPTION = (state, data) => {
  state.line.options.push(data)
}

const REMOVE_OPTION = (state, id) => {
  const idx = state.line.options.findIndex(x => x.id === id)

  if (idx !== -1) {
    state.line.options.splice(idx, 1)
  }
}

export default {
  REMOVE,
  SET_LINE,
  SET_LINES,
  PUSH_LINES,
  PUSH_REPLY,
  ADD_OPTION,
  CLEAR_LINES,
  REMOVE_OPTION
}
