<template>
  <div id="adminGroupWrapper">
    <div class="box">
      <div class="field">
        <label class="label">{{ $t('admin.workplace.group') }}</label>

        <div class="control">
          <input class="input" type="text" v-model="name">
        </div>
      </div>

      <div class="field">
        <label class="label">{{ $t('admin.workplace.description') }}</label>

        <div class="control">
          <textarea class="textarea" v-model="description"></textarea>
        </div>
      </div>
    </div>

    <form-buttons
      @submit="submit"
      @close="close"
      @remove="remove">
  </form-buttons>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormButtons from '@/components/modules/FormButtons'

export default {
  name: 'AdminGroup',
  components: {
    FormButtons
  },
  data () {
    return {
      id: null,
      name: null,
      description: null
    }
  },
  computed: {
    ...mapGetters({
      group: 'groups/group'
    })
  },
  mounted () {
    if (this.$attrs.id) this.$store.dispatch('groups/load', this.$attrs.id)
  },
  watch: {
    group () {
      if (this.group) {
        this.id = this.group.id
        this.name = this.group.name
        this.description = this.group.description
      }
    }
  },
  methods: {
    submit () {
      this.$store.dispatch('groups/save', {
        id: (this.id) ? this.id : null,
        name: this.name,
        description: this.description
      })
    },
    close () {
      this.id = this.name = this.description = null
      this.goToIndex()
    },
    remove () {
      this.$store.dispatch('groups/remove', this.id)
      this.goToIndex()
    },
    goToIndex () {
      this.$router.push({ name: 'admin.workplace.groups' })
    }
  }
}
</script>

<style lang="css">
</style>
