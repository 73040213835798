import { AdPositions } from '@/services/adPositions'

const load = (context, id) => {
  (new AdPositions()).fetch(id).then(
    res => context.commit('SET_AD', res.data)
  ).catch(
    e => console.error(e)
  )
}

export default {
  load,
}
