const post = state => state.post
const posts = state => state.posts
const filter = state => state.filter
const filtered = state => state.filtered

export default {
  post,
  posts,
  filter,
  filtered
}
