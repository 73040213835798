<template>
  <div class="columns">
    <template v-if="page">
      <div
        v-if="hasSubmenu"
        class="column is-one-fifth-table is-one-quarter-desktop"
      >
        <aside class="menu">
          <back-link
            v-if="page.parent"
            to="other"
            :params="{ page: page.parent.slug }"
            :hide-on-desktop="false"
          ></back-link>

          <ul class="menu-list">
            <li
              v-for="child in subpagesMenu"
              :key="child.id"
            >
              <router-link :to="{ name: 'other', params: { page: child.slug } }">{{ child.header }}</router-link>
            </li>
          </ul>
        </aside>
      </div>

      <div
        class="column is-three-fifths-tablet is-half-desktop"
        :class="{ 'is-offset-one-fifth is-offset-one-quarter': !hasSubmenu }"
      >
        <h1 class="title is-4">{{ page.header }}</h1>

        <div class="content" v-html="page.body"></div>

<!--        <p>-->
<!--          <router-link-->
<!--              v-if="previous"-->
<!--              :to="{ name: 'about', params: { slug: previous.slug } }">-->

<!--          <span class="icon">-->
<!--            <font-awesome-icon icon="chevron-left" />-->
<!--          </span>-->

<!--            {{ previous.header }}-->
<!--          </router-link>-->

<!--          <router-link-->
<!--              v-if="next"-->
<!--              class="is-pulled-right"-->
<!--              :to="{ name: 'about', params: { slug: next.slug } }">-->

<!--            {{ next.header }}-->

<!--            <span class="icon">-->
<!--            <font-awesome-icon icon="chevron-right" />-->
<!--          </span>-->
<!--          </router-link>-->
<!--        </p>-->
      </div>
    </template>

    <div
      v-else
      class="notification"
    >
      {{ $t('page.loading') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BackLink from '@/components/modules/BackLink'

export default {
  name: 'Page',

  components: {
    BackLink
  },

  computed: {
    ...mapGetters({
      page: 'pages/page'
    }),

    slug () {
      return this.$attrs.page
    },

    hasSubmenu () {
      return this.page.children.length > 0 || this.page.parent !== null
    },

    subpagesMenu () {
      return this.page?.children.length > 0 ? this.page.children : this.page?.siblings
    }
  },

  created () {
    this.load()
  },

  watch: {
    slug () {
      this.load()
    }
  },

  methods: {
    load () {
      this.$store.dispatch('pages/loadBySlug', this.slug)
    }
  }
}
</script>
